import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoggingService } from '../shared/services/logging.service';
import { of } from 'rxjs';

@Injectable()
export class UrlService {
  public previousUrl$: Observable<string>;
  public returnTab$: Observable<string>;

  private _previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(
    '/home'
  );
  private readonly _logger = new LoggingService('UrlService', 'previousUrl');

  constructor() {
    this.previousUrl$ = this._previousUrl.asObservable();
  }

  setPreviousUrl(previousUrl: string) {
    this._logger.info(`Setting previous url ${previousUrl}`);
    this._previousUrl.next(previousUrl);
  }

  hasReturnTab(): boolean {
    return !!this.returnTab$;
  }

  clearReturnTabl(): void {
    this.returnTab$ = null;
  }
  setReturnTab(tab: string) {
    this._logger.info(`Setting return tab ${tab}`);
    if (!tab) {
      return;
    }
    this.returnTab$ = of(tab);
  }
}

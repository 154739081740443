import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { Site } from 'src/app/shared/models/site';
import { SiteFlagDescriptionEnum, SiteFlagEnum } from 'src/app/shared/models/enums/siteFlagEnum';
import {
  InterconnectionModel,
  InterconnectionNotification,
} from 'src/app/shared/models/interconnection';
import { catchError, finalize, map } from 'rxjs/operators';
import { SiteNotification } from '../../../shared/models/sites/siteNotification';
import { addDays, startOfDay } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class AccountManagementService extends ApiService {
  public isSitesLoading$ = new BehaviorSubject(false);
  public isAllAccountNotificationsLoading$ = new BehaviorSubject<boolean>(false);
  public iconPath = 'app/shared/assets/icons/site-flags/';
  public loadingOutboxNotifications$ = new BehaviorSubject<boolean>(false);
  public defaultStartDate$ = new BehaviorSubject<Date>(addDays(startOfDay(new Date()), -1)); // 1 day ago
  public dataEmit = new Subject();

  public flagIconArray = [
    {
      flag: SiteFlagEnum.DisconnectNotice,
      url: this.iconPath + 'disconnect-notice.svg',
      description: SiteFlagDescriptionEnum.DisconnectNotice,
    },
    {
      flag: SiteFlagEnum.OverGeneration,
      url: this.iconPath + 'over-generation.svg',
      description: SiteFlagDescriptionEnum.OverGeneration,
    },
    {
      flag: SiteFlagEnum.NoGeneration,
      url: this.iconPath + 'no-generation.svg',
      description: SiteFlagDescriptionEnum.NoGeneration,
    },
    {
      flag: SiteFlagEnum.RecloserStatusChange,
      url: this.iconPath + 'recloser-comms.svg',
      description: SiteFlagDescriptionEnum.RecloserStatusChange,
    },
  ];

  constructor() {
    super();
  }

  getExternalSites(flagged = false): Observable<Site[]> {
    this.isSitesLoading$.next(true);

    let params = new HttpParams();
    if (flagged) {
      params = ApiService.setHttpParam('flagged', 'true', params);
    }

    return this.http.get<InterconnectionModel[]>(`${this.apiUrl}/external/sites/`, { params }).pipe(
      map((resp) => resp.map((x) => Site.fromInterconnection(x))),
      catchError((err) => this.handleError('Unable to load program manager sites', err)),
      finalize(() => this.isSitesLoading$.next(false))
    );
  }

  getNotifications(
    startDate: Date,
    endDate: Date
  ): Observable<SiteNotification[] | InterconnectionNotification[]> {
    this.isAllAccountNotificationsLoading$.next(true);
    let params = new HttpParams();
    params = ApiService.setHttpParam('startDate', startDate.toISOString(), params);
    params = ApiService.setHttpParam('endDate', endDate.toISOString(), params);

    return this.http
      .get<InterconnectionNotification[]>(`${this.apiUrl}/external/sites/notifications`, { params })
      .pipe(
        finalize(() => this.isAllAccountNotificationsLoading$.next(false)),
        map((resp: InterconnectionNotification[]) => resp.map((n) => new SiteNotification(n)))
      );
  }
}

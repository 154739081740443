import {
  ChartOptions,
  LineOptions,
  BarOptions,
  LineControllerDatasetOptions,
  TooltipOptions,
  BarControllerDatasetOptions,
} from 'chart.js';
import { DeepPartial } from 'chart.js/dist/types/utils';

export type FullChartOptions = Partial<ChartOptions & LineOptions & BarOptions & LineControllerDatasetOptions & BarControllerDatasetOptions & {tooltip: DeepPartial<TooltipOptions>}>;

// TODO: May be corrected in future implementations of chart.js, but all of these properties are valid for our usages
export const DEFAULT_CHART_OPTIONS: FullChartOptions = {
  animation: {
    duration: 0,
  },
  interaction: {
    intersect: false,
    axis: 'x' as const,
  },
  responsive: true,
  maintainAspectRatio: false,
  spanGaps: true,

  pointHoverRadius: 5,
  pointHoverBorderColor: 'white',

  plugins: {
    legend: {
      display: false,
    },
  },
  tooltip: {
    bodyFont: {
      size: 16,
    },
    boxPadding: 4,
    titleFont: {
      weight: 'bold',
      size: 18,
    },
  },
};

export const DEFAULT_TICK_COLOR = {
  color: 'gray',
};

export const DEFAULT_X_TICK_COLOR_AND_FONT = {
  ...DEFAULT_TICK_COLOR,
  font: {
    weight: 'bold',
  },
};

export const DEFAULT_GRID = {
  grid: {
    color: 'lightgray',
  },
};

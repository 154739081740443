<div class="block bg-gray-lighter py-16">
  <section class="bg-white w-full">
    <div class="flex flex-col p-24">
      <span class="text-xl  mb-20">{{ siteName }} {{"(" + _site?.jurisdiction + ")" }} </span>
      <div class="flex-1"></div>
      <div class="flex flex-col flex-wrap gap-12">
        <div class="flex flex-row items-baseline">
         <div class="font-bold mr-6">Contact Us:</div>
        </div>
        <div class="flex flex-row gap-12">
          <div class="flex flex-row items-baseline">
            <div class="font-bold mr-6">Email:</div>
            <a class="underline">DEOMteam&#64;duke-energy.com</a>
          </div>
          <div class="flex flex-row items-baseline">
            <div class="font-bold mr-6">Phone:</div>
            <div>(800) 726-6736</div>
          </div>
        </div>

      </div>
    </div>
  </section>
</div>

<div class="battery-site py-24 px-44 bg-white">
  <div class="site-info flex flex-nowrap">
    <div class="flex flex-col justify-center">
      <span class="text-xl mb-[15px]">Battery Status </span>
        <div class="flex flex-row flex-wrap gap-10" *ngIf="batteryChargingStatus === chargingStatusType.Charging">
          <img  src="/assets/icons/circle-check-solid.svg" alt="circle checked" />
            <div class="text-dm-green-darker mt-4 text-lg">Grid Connected</div>
        </div>
        <div class="flex flex-row flex-wrap gap-10" *ngIf="batteryChargingStatus === chargingStatusType.Discharging">
          <img  src="/assets/icons/error.svg" alt="error" />
            <div class="text-red-dark mt-4 text-lg">Site Islanding</div>
        </div>
        <div *ngIf="batteryChargingStatus === chargingStatusType.Discharging">
            <div class="text-[#005983] mt-6 text-lg">{{daysRemaining}}</div>
        </div>
    </div>
    <div class="mx-28 border-1 border-gray"></div>
    <div class="flex grow flex-wrap justify-evenly items-center">
      <div class="battery-container">
        <app-battery [progressPercent]="batteryProgress"></app-battery>
      </div>
      <div class="">
        <div class="px-20 py-12 flex flex-col rounded-md border-1 border-gray">
          <div class="flex gap-20">
            <ng-container *ngIf="batteryChargingStatus === chargingStatusType.Charging; else discharging">
              <div class="mx-auto mb-0 h-[72px]">
                <span class="text-green-dark">Charging</span>
                <img class="mx-auto mt-6" src="/assets/icons/charging-green.svg" alt="Up Arrow Green" />
              </div>
              <div class="charging-status-divider"></div>
              <div class="mx-auto mb-0 h-[72px]">
                <span>Discharging</span>
                <img class="mx-auto mt-6" src="/assets/icons/discharging-gray.svg" alt="Down Arrow Grey" />

              </div>
            </ng-container>
          </div>
        </div>
        <h1 class=" mt-8 text-center">Battery Charging Status</h1>
      </div>


      <div class="">
        <div class="py-[5px] rounded-md border-1 border-gray text-center text-gray-dark">
          <div
            *ngIf="batteryChargingStatus === chargingStatusType.Discharging; else batteryCharging"
            class="text-3xl text-teal-darker m-auto"
          >
            {{ currentUsage }}
          </div>
          <ng-template #batteryCharging>
            <div class="text-3xl text-teal-darker m-auto">0</div>
          </ng-template>
          <div class="measurement-unit m-auto">kW</div>
        </div>
        <div class="usage-text mt-8">Current Battery Usage</div>
      </div>
    </div>
  </div>
</div>

<ng-template #discharging>
  <div class="mx-auto mb-0 h-[72px]">
    <span class="text-gray">Charging</span>
    <img class="mx-auto mt-6 text-gray" src="/assets/icons/electric-up.svg" alt="Charging Up Arrow Grey" />
  </div>
  <div class="charging-status-divider"></div>
  <div class="mx-auto mb-0 h-[72px]">
    <span class="text-red-dark">Discharging</span>
    <img class="mx-auto mt-6" src="/assets/icons/electric-down.svg" alt="Discharging Down Arrow Red" />
  </div>
</ng-template>

<app-loading *ngIf="(sitesService.isSiteBatteryLoading$ | async) || (sitesService.isSiteModelLoading$ | async)"></app-loading>

<!-- //TODO: Add capability to mark as read and sort. Re-add search option (but after actual message that was sent is returned.)-->

<div class="site-notifications__container">
  <section>
    <app-display-table [includeTextFilter]="false"
                       [pagingEnabled]="true"
                       [getData]="getNotifications"
                       [sortingEnabled]="false"
                       [includeHeaderRow]="false"
                       [loading]="sitesService.isSiteModelLoading$ | async"
                       [rowClass]="'no-border'"
                       noRecordsText="No messages found.">
      <ng-template #uuidTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #siteNotificationTemplate let-element="element" let-column="column">
        <ng-template #statusChangeTemplate let-messageType="messageType">
          <div class="border-l-6 border-teal-darker dark:border-dm-accent mt-20 pt-10 px-20 flex text-md dark:text-dm-body-text">
            <div class="flex-auto">
              <div class="mb-14">
                This site had a(n) <strong>{{ element.flag }}</strong>.
                The status changed from
                <strong>{{ messageType.previousStatus }}</strong>
                to
                <strong>{{ messageType.currentStatus }}</strong>.
              </div>

              <div class="text-sm">
                Created: {{element.date | date: 'shortDate'}}
                at {{element.date | date:'shortTime' }}
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="element.flag === 'No Generation'"
             class="border-l-6 border-teal-darker dark:border-dm-accent mt-20 pt-10 px-20 flex text-md dark:text-dm-body-text">
          <div class="flex-auto">
            <div class="mb-14">
              This site was not generating on
              <strong>{{element.noGeneration?.start | date: 'shortDate'}}</strong>

              <div class="text-sm">
                Created: {{element.date | date: 'shortDate'}}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="element.flag === 'Over Generation'"
             class="border-l-6 border-teal-darker dark:border-dm-accent mt-20 pt-10 px-20 flex text-md dark:text-dm-body-text">
          <div class="flex-auto">
            <div class="mb-14">
              This site generated on average
              <strong>{{element.overGeneration?.averageMW | number:'1.3-3'}} MW<sub>AC</sub></strong>
              during the previous hour. This exceeded its name plate capacity of
              <strong>{{element.overGeneration?.capacity | number:'1.3-3' }} MW<sub>AC</sub></strong>
              by
              <strong>{{element.overGeneration?.excessMW | number:'1.3-3'}} MW<sub>AC</sub></strong>.
            </div>

            <div class="text-sm">
              Created: {{element.date | date: 'shortDate'}}
              at {{element.overGeneration?.date | date:'shortTime' }}
            </div>
          </div>
        </div>

        <div *ngIf="element.flag === 'Disconnect Notice'"
             class="border-l-6 border-teal-darker dark:border-dm-accent mt-20 pt-10 px-20 flex text-md dark:text-dm-body-text">
          <div class="flex-auto">
            <div class="mb-14">
              This site has been issued a
              <strong>Disconnect Notice</strong> on {{ element.disconnectNotice?.updated_date | date: 'shortDate'}}.
              <span *ngIf="element.disconnectNotice?.is_paid"> - PAID</span>
            </div>

            <div class="text-sm">
              Created: {{element.date | date: 'shortDate'}}
              at {{element.date | date:'shortTime' }}
            </div>
          </div>
        </div>
      </ng-template>
      <app-data-cell name="uuid" label="uuid" [cellTemplate]="siteNotificationTemplate"> </app-data-cell>
    </app-display-table>
  </section>
</div>

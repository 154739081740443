import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { KeyValue } from '@angular/common';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';
import { SitesService } from 'src/app/shared/services/api/sites.service';
import { AppConfig } from 'src/app/core/config/app.config';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { Site } from 'src/app/shared/models/site';
import { ActivatedRoute } from '@angular/router';
import { DateChangedEvent } from 'src/app/shared/components/month-selector/month-selector.component';
import { convertToStartAndEnd } from 'src/app/shared/utils/date-change.converter';
import {
  InterconnectionGenerationModel,
  InterconnectionModel,
} from 'src/app/shared/models/interconnection';
import { GenerationViolationsComponent } from 'src/app/shared/components/macro-components/generation-violations/generation-violations.component';
import { PaymentHistoryComponent } from 'src/app/shared/components/macro-components/payment-history/payment-history.component';
import { MatDialog } from '@angular/material/dialog';
import { AmendmentsComponent } from 'src/app/shared/components/macro-components/amendments/amendments.component';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { addDays, endOfMonth, startOfMonth } from 'date-fns';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { PlannedOutagesService } from '../../../../shared/services/api/outages.service';
import { SitePlannedOutagesComponent } from '../../../../shared/components/macro-components/site-planned-outages/site-planned-outages.component';
import { PlannedOutage, PlannedOutageModel } from '../../../../shared/models/plannedOutage';
import { FromDukePayment, ToDukePayment } from 'src/app/shared/models/financial/settlements';
import { SettlementData } from 'src/app/shared/models/financial/settlements';
import { PlannedOutagesComponent } from '../../../account-management/pages/account-management/planned-outages/planned-outages.component';
import { EnergyType } from 'src/app/shared/models/enums/energyType';
import { BatteryData } from 'src/app/shared/models/battery/batteryData';
import { SiteNotification } from 'src/app/shared/models/sites/siteNotification';
import { interval, takeUntil, timer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BATTERY_SITE_MAPPING } from 'src/app/modules/sites/pages/site-specific/battery-site-mapping';
import { AccessLevelRequest } from 'src/app/modules/register-account/models/accountCreationModel';
type TAB_SELECTIONS = 'Home' | 'Payments' | 'Messages' | 'IBR Metrics' | 'Additional Info';

@Component({
  selector: 'app-site-specific',
  templateUrl: './site-specific.component.html',
  styleUrls: ['./site-specific.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteSpecificComponent extends BaseComponent implements OnInit {
  @ViewChild(GenerationViolationsComponent)
  generationViolationsComponent: GenerationViolationsComponent;
  @ViewChild(PaymentHistoryComponent) paymentHistory: PaymentHistoryComponent;
  @ViewChild(SitePlannedOutagesComponent)
  plannedOutages: PlannedOutagesComponent;
  public showCurtailmentInfo = this.appConfig.getFeatureFlag('showCurtailmentInfo');
  public showPaymentInfo = this.appConfig.getFeatureFlag('showPaymentInfo');
  public showBattery = false;
  public site: Site; // FE Model
  public interconnection: InterconnectionModel; // BE Model
  public outages: PlannedOutage[];
  public batteryData: BatteryData[];
  public batteryDetails: BatteryData;
  public batterySelectedDate: Date;
  public generationData: {
    data: InterconnectionGenerationModel[];
    live: number;
    recloser_status: string;
    comm_status: string;
  };
  public siteNotifications: SiteNotification[];
  public selectedTab: TAB_SELECTIONS = 'Home';
  protected userLevelRequest = AccessLevelRequest;
  protected refreshPageModalOpen = false;
  protected tableDatas: { from: SettlementData; to: SettlementData };
  protected readonly solarSiteWrite = false;
  // Temporary info IBR Metric header - DELETE after component implementation
  protected readonly comingSoon: string = 'Coming Soon';
  protected readonly contractAgreementInfo: string =
    'PPA contract agreement data as displayed on this card comes directly from DET DataMart';
  private _siteId: string;
  private _logger = new LoggingService('SiteSpecificComponent', 'Site');

  constructor(
    private activatedRoute: ActivatedRoute,
    private appConfig: AppConfig,
    private dialog: MatDialog,
    protected plannedOutageService: PlannedOutagesService,
    protected siteService: SitesService,
    protected themeService: ThemeService
  ) {
    super();
  }
  ngOnInit() {
    this.subscriptions.push(
      this.activatedRoute.params.subscribe((response) => {
        if (response['unique_id']) {
          this._siteId = response['unique_id'] as string;
          this.showBattery = this._siteId in BATTERY_SITE_MAPPING ? true : false;
          this.setSite();
          this.setPlannedOutages();
          this.setNotifications();
        }
      })
    );
    this.subscriptions.push(
      // Refresh data every 5 minutes
      interval(1000 * 60 * 5)
        .pipe(
          // After 2 hours open a modal that user needs to acknowledge to refresh page
          takeUntil(timer(1000 * 60 * 120)),
          finalize(() => (this.refreshPageModalOpen = true))
        )
        .subscribe(() => {
          // For non-battery sites, set generation data
          this.setGenerationData();
          // For microgrid and solar sites, set battery data
          this.setBatteryData();
        })
    );
  }
  setSite() {
    this.siteService
      .getSiteModel(this._siteId)
      .subscribe((interconnection: InterconnectionModel) => {
        this.site = Site.fromInterconnection(interconnection);
        this.interconnection = interconnection;

        // For non-battery sites, set generation data
        this.setGenerationData();

        // For microgrid and solar sites, set battery data
        this.setBatteryData();

        // Set payment data
        this.setPaymentData(interconnection);
      });
  }

  setPlannedOutages() {
    const dt = new Date();
    const start = startOfMonth(dt);
    const end = endOfMonth(dt);
    this.plannedOutageService
      .getSitePlannedOutages(this._siteId, start, end)
      .subscribe((outages: PlannedOutageModel[]) => {
        if (!outages) {
          this.outages = [];
          this._logger.warn(`No outages for current site ${this._siteId}`);
        } else {
          this.outages = outages.map((o) => new PlannedOutage(o));
        }
      });
  }

  protected closeRefreshPageModal() {
    location.reload();
  }

  public setGenerationData(start = new Date()) {
    if (this.interconnection?.battery_type === EnergyType.Battery) {
      return;
    }

    start.setHours(0, 0, 0);
    const end = addDays(start, 1);
    this.siteService.getSiteGeneration(+this._siteId, start, end).subscribe((resp) => {
      this.generationData = resp;
    });
  }

  public setBatteryData(start = new Date()) {
    // Require battery type to exist and battery type should be Microgrid or Battery
    if (
      !this.interconnection?.battery_type ||
      this.interconnection?.battery_type === EnergyType.Solar
    ) {
      return;
    }

    start.setHours(0, 0, 0);
    const end = addDays(start, 1);
    this.batterySelectedDate = start;
    this.siteService.getBatteryData(+this._siteId, start, end).subscribe((resp) => {
      this.batteryData = resp;
      this.batteryDetails = this.batteryData[this.batteryData.length - 1];
    });
  }

  public setNotifications() {
    this.siteService.getSiteNotifications(+this._siteId).subscribe((resp) => {
      this.siteNotifications = resp;
    });
  }

  public setPaymentData(interconnection: InterconnectionModel) {
    if (interconnection.user_role !== AccessLevelRequest.Owner) {
      return;
    }

    this.siteService
      .getSiteSettlements(+this._siteId)
      .subscribe(([from, to]: [FromDukePayment[], ToDukePayment[]]) => {
        this.tableDatas = {
          to: {
            columnDefs: [
              'paymentDueDate',
              'invoiceDate',
              'invoiceNumber',
              'paymentAmount',
              'chargeCodeDescription',
              'customerId',
              'status',
            ],
            columnHeaders: [
              'Payment Due Date',
              'Invoice Date',
              'Invoice #',
              'Payment Amount',
              'Charge Code Description',
              'Customer ID',
              'Status',
            ],
            data: to,
          },
          from: {
            columnDefs: [
              'paymentRef',
              'invoiceNumber',
              'paymentMethod',
              'paymentDate',
              'paymentAmount',
            ],
            columnHeaders: [
              'Payment ID #',
              'Invoice Number',
              'Payment Method',
              'Payment Date',
              'Payment Amount',
            ],
            data: from,
          },
        };
      });
  }

  getUpdatedInfo(resp: boolean) {
    if (resp) {
      this.setSite();
    }
  }

  public selectTab(event) {
    if (event.tab.textLabel.startsWith('Messages')) {
      this.selectedTab = 'Messages';
    } else {
      this.selectedTab = event.tab.textLabel;
    }
    // GTM DOC - 5.8.4.3
    GtmService.pageLoadEvent(
      '/sites',
      `/sites/${this._siteId}/${GtmService.format(this.selectedTab)}`
    );
  }

  public newMonthSelection(selection: DateChangedEvent) {
    const { start } = convertToStartAndEnd(selection);
    this.siteService.getSiteModel(this._siteId).subscribe((data) => {
      this.site = Site.fromInterconnection(data);
      this.interconnection = data;
      this.generationViolationsComponent?.setMonthSelector(start);

      // GTM DOC - 5.8.4.4.1
      GtmService.clickEvent({
        category: 'sites',
        action: 'date-range-changed',
        label: `date-|-${this.site.unique_id}`,
      });
    });
  }

  showAmendments(event: MouseEvent) {
    event.stopPropagation();
    this.dialog.open(AmendmentsComponent, {
      data: this.site.contract?.amendments,
      panelClass: this.themeService.isDarkTheme ? 'amendments-modal-dark' : 'amendments-modal',
    });
  }

  protected tableOrder(
    a: KeyValue<string, SettlementData>,
    b: KeyValue<string, SettlementData>
  ): number {
    return a.key > b.key ? 0 : 1;
  }

  protected readonly EnergyType = EnergyType;
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InputColumn } from '../models';

/**
 * Chips which show the user filters currently being applied to the table
 */
@Component({
  selector: 'app-table-filter-chips',
  templateUrl: './table-filter-chips.component.html',
})
export class TableFilterChipsComponent {
  @Input() filteredColumns: InputColumn[];
  @Output() chipRemoved = new EventEmitter<InputColumn>();

  protected removeChip(column: InputColumn) {
    this.chipRemoved.emit(column);
  }
}

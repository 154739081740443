import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export class Link {
  display: string;
  href: string;
}

@Component({
  selector: 'app-de-checkbox',
  templateUrl: './de-checkbox.component.html',
  styleUrls: ['./de-checkbox.component.scss'],
})
export class DeCheckboxComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() disabled = false;
  @Input() value: boolean;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    return;
  }

  ngOnInit(): void {
    return;
  }
}

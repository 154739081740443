// All models relating to the table with filters components

/*
 * <b>number</b>: number that will have a range filter type, e.g. filter between 0 and 20
 * <b>addend</b>: number that counts towards a sum/total (filter is a range, affects display logic for the totals row)
 * <b>multi</b>: string with limited, but multiple-at-once options - select from a dropdown
 * <b>bool</b>: true or false or null
 */
export type ColumnDataType =
  | 'number'
  | 'addend'
  | 'string'
  | 'multi'
  | 'bool'
  | 'date'
  | 'html'
  | 'mailtoEmail';

export const BOOL_OPTIONS = ['true', 'false', 'none'];

/**
 *  Describes columns of data in our tables.
 */
export interface InputColumn {
  // Passed in for input
  name: string;
  key: string;
  type: ColumnDataType;
  display?: boolean;
  displayFilter?: boolean;
  year?: boolean;

  // Helpers
  sticky?: boolean;
  route?: string;
  showSubSelection?: boolean;
  total?: string;

  // If type is 'number' or 'addend', decimal formatter can be set.
  digitsInfo?: string;

  // If type is 'multi', filter options would be set to this prop dynamically, based on all values in data set.
  filterOptions?: string[];
  prefix?: string;
  suffix?: string;

  filter?: ColumnFilter;
}

/**
 * Represents user entries for filters
 */
export interface ColumnFilter {
  // min of number range
  min?: number;
  // max of number range
  max?: number;
  // date of date filter
  date?: Date;
  // String of string filter
  searchValue?: string;
  // If type is 'multi', what user is searching on
  multiSearchValue?: string[];
}

export interface UserTableView {
  name: string;
  columns: InputColumn[];
  // Misnomer, but data is already saved this way for users to db. Keeping for backward compatibility.
  filters?: InputColumn[];
}

<div class="flex gap-16">
  <app-de-chip
    *ngFor="let column of filteredColumns"
    (removeChip)="removeChip(column)"
  >
    <!-- Number Range Filter Chip -->
    <span *ngIf="column.type === 'number' || column.type === 'addend'">
      <strong>{{ column.name }}</strong>
      : Min: <strong>{{ column.filter.min | prefix: column.prefix | suffix: column.suffix }}</strong>
      - Max: <strong>{{ column.filter.max | prefix: column.prefix | suffix: column.suffix }}</strong>
    </span>

    <!-- String Filter Chip -->
    <span
      *ngIf="
        column.type === 'string' ||
        column.type === 'html' ||
        column.type === 'bool' ||
        column.type === 'mailtoEmail'
      "
    >
      <strong>{{ column.name }}
        : {{ column.filter.searchValue | prefix: column.prefix | suffix: column.suffix }}</strong>
    </span>

    <!-- Multi Filter Chip -->
    <span
      *ngIf="column.type === 'multi'"
    >
      <strong>{{ column.name }}: {{ column.filter.multiSearchValue | arrayJoin }}</strong>
    </span>

    <!-- Date Filter Chip -->
    <span
      *ngIf="column.type === 'date'">
      <strong>{{ column.name }}: {{ column.filter.date | date: 'MMMM yyyy'}}</strong>
    </span>
  </app-de-chip>
</div>

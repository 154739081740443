import { ChartColors, getChartColor } from './chart.util';
import {
  ChartDataset,
} from 'chart.js';

export interface ChartDatasetModel {
  backgroundColor: string;
  hoverBackgroundColor: string;
  borderColor: string;
  borderDash: number[];
  fill: boolean;
  label: string;
  pointBackgroundColor: string;
  pointHoverBackgroundColor: string;
  pointRadius: number;
  tension: number;
  yAxisID: string;

  data: {
    x: number;
    y: number;
  }[];
}

export interface Axis {
  x: number;
  y: number;
}

export interface Options {
  color: ChartColors;
  data: Axis[];
  label: string;

  borderDash?: number[];
  fill?: boolean;
  pointRadius?: number;
  tension?: number;
  yAxisID?: string;
  // Explicitly helps with bar charts, maybe others
  hoverBackgroundColor?: ChartColors;
}

export function getDataset(options: Options): ChartDataset {
  return {
    backgroundColor: getChartColor(options.color),
    hoverBackgroundColor: options.hoverBackgroundColor ? getChartColor(options.hoverBackgroundColor) : getChartColor(options.color),
    borderColor: getChartColor(options.color),
    borderDash: options.borderDash ?? [],
    data: options.data,
    fill: options.fill ?? false,
    label: options.label,
    pointBackgroundColor: getChartColor(options.color),
    pointHoverBackgroundColor: getChartColor(options.color),
    pointRadius: options.pointRadius ?? 0,
    tension: options.tension ?? 0.2,
    yAxisID: options.yAxisID ?? 'y',
  };
}

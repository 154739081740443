import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BaseModalComponent } from 'src/app/shared/components/modals/base-modal.component';
import { InputColumn } from "../../../models";

export interface SaveTableViewEvent {
  name: string;
  columns: InputColumn[];
}

/**
 * Modal providing details on current Table View with an option to Save.
 */
@Component({
  selector: 'app-save-table-view-modal',
  templateUrl: './save-table-view-modal.component.html',
  styleUrls: ['./save-table-view-modal.component.scss'],
})
export class SaveTableViewModalComponent extends BaseModalComponent {
  @Input() filteredColumns: InputColumn[];

  protected saveTableViewForm = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  protected saveTableView(): void {
    this.close({
      name: this.saveTableViewForm.value.name,
      columns: this.filteredColumns,
    } as SaveTableViewEvent);
  }
}

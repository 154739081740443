import { Component, Input } from '@angular/core';
import { BaseModalComponent } from 'src/app/shared/components/modals/base-modal.component';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent extends BaseModalComponent {
  @Input() description: string;
  @Input() cancelText: string;
  @Input() confirmText: string;
}

import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Site } from 'src/app/shared/models/site';
import { SitesService } from 'src/app/shared/services/api/sites.service';
import { SettlementData } from 'src/app/shared/models/financial/settlements';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentHistoryComponent {
  @Input() site: Site;
  @Input()
  get tableType(): string {
    return this._tableType;
  }
  set tableType(val: string) {
    this._tableType = val?.charAt(0).toUpperCase() + val.slice(1);
  }

  get tableTitle(): string {
    let tableTitle = '';
    if (this._tableType == 'From') {
      tableTitle = 'Generation Payments';
    } else if (this._tableType == 'To') {
      tableTitle = 'Contract Payments';
    }
    return tableTitle;
  }

  @Input()
  set data(val: SettlementData) {
    this.columnDefs = val.columnDefs;
    this.columnHeaders = val.columnHeaders;
    // @ts-ignore
    this.tableData.data = val.data;
  }

  @ViewChild(MatSort)
  set matSort(ms: MatSort) {
    this.tableData.sort = ms;
  }

  @ViewChild(MatPaginator)
  set matPaginator(mp: MatPaginator) {
    this.tableData.paginator = mp;
  }

  paginatorSizeOptions = [10, 25, 50, 100];
  id: string;

  protected tableData: MatTableDataSource<any> = new MatTableDataSource<any>();
  protected columnDefs: string[];
  protected columnHeaders: string[];
  private _tableType: string;

  constructor(public sitesService: SitesService) {
    sitesService.siteModel$.subscribe((e) => {
      this.id = `${e.unique_id}`;
      this.site = Site.fromInterconnection(e);
    });
  }
}

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { SaveTableViewEvent } from './table-modals/save-table-view-modal/save-table-view-modal.component';
import { TableViewsCloseEvent } from './table-modals/table-views-modal/table-views-modal.component';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { InputColumn, UserTableView } from '../models';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { MatPaginator } from '@angular/material/paginator';
import {
  TableWithFiltersService,
  TableType
} from 'src/app/shared/components/table-with-filters/table-with-filters.service';

type ConfirmationModalType = 'SAVE' | 'DELETE';

/**
 * Table with filters' footer. Since the footer has all modal operation opening commands
 * for the table, also providing the modal definitions here.
 */
@Component({
  selector: 'app-table-footer-and-modals',
  templateUrl: './table-footer-and-modals.component.html',
})
export class TableFooterAndModalsComponent implements AfterViewInit {
  @Input() tableType: TableType;
  @Input() tableExportTitle: string;
  @Input() allColumns: InputColumn[];
  @Input() filteredColumns: InputColumn[];
  @Input() paginatorSizeOptions: number[];
  @Input() isSavingFiltersEnabled: boolean;

  // TODO: Figure out a direct way to reference the paginator as a ViewChild in parent
  @Output() paginator = new EventEmitter<MatPaginator>();

  @Output() loadTable = new EventEmitter<UserTableView>();
  @Output() exportTable = new EventEmitter<void>();

  @ViewChild(MatPaginator) childPaginator: MatPaginator;

  protected confirmationModalType: ConfirmationModalType;
  protected confirmDescription = '';

  protected confirmModalOpen = false;
  protected saveTableViewModalOpen = false;
  protected tableViewsModalOpen = false;

  protected saveConfirmEvent: SaveTableViewEvent;
  protected deleteConfirmEvent: TableViewsCloseEvent;

  protected tableViews: UserTableView[] = [];

  private readonly _tableNamePlaceholder = '--table-name--';
  private readonly _saveConfirmationText = `There is already a table saved as < ${this._tableNamePlaceholder} >, saving would overwrite and update that table. Alter the desired table name to preserve < ${this._tableNamePlaceholder} > and save the current settings.`;
  private readonly _deleteConfirmationText = `Are you sure you want to delete the table < ${this._tableNamePlaceholder} >?`;

  constructor(
    private snackBar: SnackBarService,
    private tableWithFiltersService: TableWithFiltersService
  ) {}

  ngAfterViewInit(): void {
    this.paginator.emit(this.childPaginator);
  }

  /**
   * Send GTM event and let parent know it can export
   * @protected
   */
  protected export(): void {
    GtmService.clickEvent({
      category: 'dashboard',
      label: this.tableExportTitle,
      action: 'export',
    });

    this.exportTable.emit();
  }

  /**
   * If not confirmed, open previous modal type. Else, execute event.
   * @param isConfirmed
   * @protected
   */
  protected closeConfirmationModal(isConfirmed: boolean): void {
    this.confirmModalOpen = false;

    if (this.confirmationModalType === 'SAVE') {
      if (isConfirmed) {
        this.closeSaveTableViewModal(this.saveConfirmEvent, true);
      } else {
        this.saveTableViewModalOpen = true;
      }
    } else if (this.confirmationModalType === 'DELETE') {
      if (isConfirmed) {
        this.deleteTableView(this.deleteConfirmEvent.tableView.name);
      } else {
        this.tableViewsModalOpen = true;
      }
    }
  }

  protected openTableViewsModal(): void {
    this.tableWithFiltersService
      .loadTableViewsFromDatabase(this.tableType)
      .subscribe((tableViews) => {
        this.tableViews = tableViews;
        this.tableViewsModalOpen = true;
      });
  }

  /**
   * If cancelled, event is null. Else execute event action.
   * @param event
   * @protected
   */
  protected closeTableViewsModal(event: TableViewsCloseEvent): void {
    this.tableViewsModalOpen = false;

    if (!event) {
      return;
    }

    GtmService.clickEvent({
      category: 'dashboard',
      label: this.tableExportTitle,
      action: event.action + '-table',
    });

    if (event.action === 'delete') {
      this.openDeleteConfirmationModal(event);
    } else if (event.action === 'load') {
      this.loadTable.emit(event.tableView);
    }
  }

  /**
   * Close save modal and save table to database
   * @param event
   * @param overwrite
   * @protected
   */
  protected closeSaveTableViewModal(
    event: SaveTableViewEvent,
    overwrite = false
  ): void {
    this.saveTableViewModalOpen = false;
    if (!event) {
      return;
    }

    GtmService.clickEvent({
      category: 'dashboard',
      label: this.tableExportTitle,
      action: 'save-table',
    });

    this.tableWithFiltersService
      .saveTableViewToDatabase(
        event.name,
        this.tableType,
        this.allColumns,
        overwrite
      )
      .subscribe((resp) => {
        if (!resp.success && resp.description === 'exists') {
          this.openSaveOverwriteConfirmationModal(event);
        }
      });
  }

  private openSaveOverwriteConfirmationModal(event: SaveTableViewEvent): void {
    this.confirmDescription = this._saveConfirmationText.replace(
      new RegExp(this._tableNamePlaceholder, 'g'),
      event.name
    );
    this.confirmationModalType = 'SAVE';
    this.saveConfirmEvent = event;
    this.confirmModalOpen = true;
  }

  private openDeleteConfirmationModal(event: TableViewsCloseEvent): void {
    this.confirmDescription = this._deleteConfirmationText.replace(
      this._tableNamePlaceholder,
      event.tableView.name
    );
    this.confirmationModalType = 'DELETE';
    this.deleteConfirmEvent = event;
    this.confirmModalOpen = true;
  }

  private deleteTableView(tableName: string) {
    GtmService.clickEvent({
      category: 'dashboard',
      label: this.tableExportTitle,
      action: 'delete-table',
    });

    this.tableWithFiltersService
      .deleteTableViewFromDatabase(tableName, this.tableType)
      .subscribe();
  }
}

<div class="table-paginator">
  <div class="table-paginator-buttons">
    <app-icon-button
      *ngIf="isSavingFiltersEnabled"
      name="Table Views"
      iconName="table/import"
      buttonType="text"
      (buttonClicked)="openTableViewsModal()"
    ></app-icon-button>
    <app-icon-button
      *ngIf="isSavingFiltersEnabled"
      name="Save Table View"
      iconName="table/save"
      buttonType="text"
      (buttonClicked)="saveTableViewModalOpen = true"
    ></app-icon-button>
    <app-icon-button
      name="Export Table"
      iconName="table/export"
      buttonType="text"
      (buttonClicked)="export()"
    ></app-icon-button>
  </div>
  <span class="paginator-spacer"></span>
  <mat-paginator [pageSizeOptions]="paginatorSizeOptions"></mat-paginator>
</div>

<app-save-table-view-modal
  [modalOpen]="saveTableViewModalOpen"
  [filteredColumns]="filteredColumns"
  (closeModal)="closeSaveTableViewModal($event)"
></app-save-table-view-modal>

<app-table-views-modal
  [modalOpen]="tableViewsModalOpen"
  [tableViews]="tableViews"
  (closeModal)="closeTableViewsModal($event)"
></app-table-views-modal>

<app-confirmation
  [modalOpen]="confirmModalOpen"
  [description]="confirmDescription"
  cancelText="BACK"
  [confirmText]="confirmationModalType + ' TABLE'"
  (closeModal)="closeConfirmationModal($event)"
></app-confirmation>

<div [ngClass]="showSelector ? 'selected' : ''">
  <app-icon-button
    [name]="label"
    iconName="calendar_simple"
    suffixIconName="table/down"
    buttonType="text"
    (click)="toggleSelector()"
  ></app-icon-button>
</div>
<form [formGroup]="form" *ngIf="showSelector" class="selector" [ngClass]="themeService.themeBackgroundClass">
  <mat-form-field appearance="fill">
    <mat-label>Year</mat-label>
    <mat-select [formControl]="yearControl">
      <mat-option *ngFor="let year of years" [value]="year">
        {{ year }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="!hideMonthField" appearance="fill">
    <mat-label>Month</mat-label>
    <mat-select [formControl]="monthControl">
      <mat-option *ngFor="let month of months" [value]="month">
        {{ month }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <de-button type="primary" (buttonClick)="apply()">APPLY</de-button>
</form>

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { LoggingService } from '../../shared/services/logging.service';

@Injectable()
export class ResponseLoggingInterceptor implements HttpInterceptor {
  private readonly _logger = new LoggingService(
    'ResponseLoggingInterceptor',
    'httpResponses'
  );
  intercept(
    request: HttpRequest<never>,
    next: HttpHandler
  ): Observable<HttpEvent<HttpResponse<never>>> {
    return next.handle(request).pipe(
      tap((response) => this._logger.debug('Response', response.type)),
      catchError((error) => {
        this._logger.error('Response error', error);
        throw error;
      })
    );
  }
}

import { getDaysInMonth } from 'date-fns';
import { DateChangedEvent } from '../components/month-selector/month-selector.component';
import { AbstractControl } from '@angular/forms';

export function convertToStartAndEnd(selection: DateChangedEvent) {
  const dt: Date = new Date(parseInt(selection.year), selection.monthIndex);
  const maxDays: string = getDaysInMonth(dt).toString();
  const selectionIdx: string = (selection.monthIndex + 1).toString();
  const monthStr = ('0' + selectionIdx).slice(-2);
  const maxDaysStr = ('0' + maxDays).slice(-2);
  const start = `${selection.year}-${monthStr}-01`;
  const end = `${selection.year}-${monthStr}-${maxDaysStr}`;
  return { start, end };
}


/**
 *  This addresses an Angular Datepicker bug: When typing 2018-10-18, it gets reformatted and becomes 10/17/2018.
 *  Our method affects the datepicker field after this conversion takes place. We save the value of the input field on
 *  each input (change). Then on (focusout), if we have the problematic regex match, simply reformat and set the field's
 *  value appropriately. No need to worry about timezones at all.
 *  @param formControl the formControl tied to a datepicker
 *  @param dateString the string value of the actual date in question
 */
export function formatDatePickerDateFromString(
  formControl: AbstractControl,
  dateString: string
) {
  if (/^\d{4}-\d{2}-\d{2}$/.exec(dateString)) {
    length = dateString.length;
    const newDateStr = `${dateString.substring(length - 5, length - 3)}
        /${dateString.substring(length - 2)}
        /${dateString.substring(0, length - 6)}`;
    formControl.setValue(new Date(newDateStr));
  }
}

<div class="summary-container">
  <div class="text-xl mb-10">Curtailments</div>

  <div *ngIf="siteModel?.ytdCurtailment; else noData">
    <div class="summary-section">
      <div class="text-blue dark:text-dm-blue mb-6">YTD Curtailments</div>
      <hr />
      <div class="summary-section-text">
        <div class="summary-section-text-label">Curtailed Total</div>
        <div class="summary-section-text-value">{{ siteModel.ytdCurtailment.used | number : "1.0-0" }} MWh</div>
      </div>
      <div class="summary-section-text">
        <div class="summary-section-text-label">Curtailed Non-Reliability</div>
        <div class="summary-section-text-value">
          {{
            siteModel.ytdCurtailment?.usedEconomic
              ? (siteModel.ytdCurtailment?.usedEconomic | number : "1.0-0") + " MWh"
              : "N/A"
          }}
        </div>
      </div>
      <div class="summary-section-text">
        <div class="summary-section-text-label">Curtailed Reliability</div>
        <div class="summary-section-text-value">
          {{
            siteModel.ytdCurtailment?.usedReliability
              ? (siteModel.ytdCurtailment?.usedReliability | number : "1.0-0") + " MWh"
              : "N/A"
          }}
        </div>
      </div>
      <div class="summary-section-text">
        <div class="summary-section-text-label">Curtailed Under Review</div>
        <div class="summary-section-text-value">
          {{
            siteModel.ytdCurtailment?.usedUncertain
              ? (siteModel.ytdCurtailment?.usedUncertain | number : "1.0-0") + " MWh"
              : "N/A"
          }}
        </div>
      </div>
      <div class="summary-section-text">
        <div class="summary-section-text-label">Remaining</div>
        <div class="summary-section-text-value">
          {{
            siteModel.ytdCurtailment?.remaining
              ? (siteModel.ytdCurtailment?.remaining | number : "1.0-0") + " MWh"
              : "N/A"
          }}
        </div>
      </div>
      <div class="summary-section-text">
        <div class="summary-section-text-label">Duration</div>
        <div class="summary-section-text-value">
          {{
            siteModel.ytdCurtailment?.minutes
              ? (siteModel.ytdCurtailment?.minutes | minutesToHoursMinutes : "long")
              : "N/A"
          }}
        </div>
      </div>
    </div>
  </div>

  <ng-template #noData>
    <app-no-data [title]="'No Curtailment Data'" [summary]="'Curtailment Data will appear here'"> </app-no-data>
  </ng-template>
</div>

import { OutageType } from 'src/app/shared/models/enums/outageType';
import { UserInfo, UserInfoApiModel } from 'src/app/shared/models/userInfo';
import { getDuration } from '../utils/date-time.util';

export class PlannedOutage {
  approvalStatus?: string;
  comments: string;
  createdBy?: UserInfo;
  createdDate?: Date;
  derateAmount?: number;
  endDateTime: Date;
  jurisdiction?: string;
  outageType: OutageType;
  siteName: string;
  siteCapacity: number;
  startDateTime: Date;
  lastEditedBy?: UserInfo;
  lastEditedDate?: Date;
  outageRoute?: string;
  temporaryExpectedCapacity: number;
  uniqueId: number;
  uniqueIdDisplay?: string;
  uuid?: string;

  // for reporting
  action?: string;
  createdByStr?: string;
  endDateTimeStr?: string;
  lastEditedByStr?: string;
  outageEditRoute?: string;
  startDateTimeStr?: string;

  /**
   * Return the duration in seconds of the outage.
   */
  get duration(): string {
    return getDuration(this.startDateTime, this.endDateTime);
  }

  constructor(site: PlannedOutageModel) {
    this.approvalStatus = site.status;
    this.jurisdiction = site.jurisdiction;
    this.uniqueId = site.unique_id;
    this.uniqueIdDisplay = `${this.uniqueId}`.padStart(6, '0');
    this.siteName = site.site_name;
    this.startDateTime = new Date(site.event_start);
    this.endDateTime = site.event_end ? new Date(site.event_end) : null;
    this.outageType = site.event_type;
    this.siteCapacity = site.installed_capacity;
    this.temporaryExpectedCapacity = site.expected_capacity;
    this.comments = site.additional_comments;
    this.uuid = site.uuid;
    this.lastEditedBy = UserInfo.fromApiModel(site.last_edited_by);
    this.lastEditedDate = new Date(site.last_edited_date);
    this.createdBy = UserInfo.fromApiModel(site.created_by);
    this.createdDate = new Date(site.created_date);
  }
}

export class PlannedOutageModel {
  jurisdiction?: string;
  uuid?: string;
  unique_id: number;
  site_name: string;
  event_type: OutageType;
  installed_capacity: number;
  expected_capacity: number;
  event_start: string;
  event_end: string;
  additional_comments: string;
  derate_amount: number;
  last_edited_by?: UserInfoApiModel;
  last_edited_date?: string;

  created_by?: UserInfoApiModel;
  created_date?: string;
  status?: string;

  constructor(site: PlannedOutage) {
    this.unique_id = site.uniqueId;
    this.jurisdiction = site.jurisdiction;
    this.site_name = site.siteName;
    this.event_start = new Date(site.startDateTime).toISOString();
    this.event_end = site.endDateTime ? new Date(site.endDateTime).toISOString() : null;
    this.additional_comments = site.comments;
    this.event_type = site.outageType;
    this.installed_capacity = site.siteCapacity;
    this.expected_capacity = site.temporaryExpectedCapacity;
    this.derate_amount = site.derateAmount;
    this.uuid = site.uuid;
  }
}

export class OutagesBaseModel {
  approval_status: string;
  uuid: string;
  site_name: string;
  unique_id: number;
  outage_type: string;
  installed_capacity: number;
  derate_amount: number;
  expected_capacity: number;
  event_start: string;
  event_end: string;
  comments: string;

  constructor(data: OutagesBase) {
    this.approval_status = data.approvalStatus;
    this.uuid = data.uuid;
    this.site_name = data.siteName;
    this.unique_id = data.uniqueId;
    this.outage_type = data.outageType;
    this.installed_capacity = data.installedCapacity;
    this.derate_amount = data.derateAmount;
    this.expected_capacity = data.tempExpectedCapacity;
    this.event_start = data.eventStartDate;
    this.event_end = data.eventEndDate;
    this.comments = data.comments;
  }
}

export class OutagesBase {
  approvalStatus: string;
  uuid: string;
  siteName: string;
  uniqueId: number;
  outageType: string;
  installedCapacity: number;
  derateAmount: number;
  tempExpectedCapacity: number;
  eventStartDate: string;
  eventEndDate: string;
  dateCreated: string;
  comments: string;

  constructor(data: OutagesBaseModel) {
    this.approvalStatus = data.approval_status;
    this.uuid = data.uuid;
    this.siteName = data.site_name;
    this.uniqueId = data.unique_id;
    this.outageType = data.outage_type;
    this.installedCapacity = data.installed_capacity;
    this.derateAmount = data.derate_amount;
    this.tempExpectedCapacity = data.expected_capacity;
    this.eventStartDate = data.event_start;
    this.eventEndDate = data.event_end;
    this.comments = data.comments;
  }
}

import { environments } from 'src/config/env.config';
import { env } from 'src/config/env';

const logLevels = {
  all: 0,
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
  critical: 5,
  none: 10,
};
type LogLevel = keyof typeof logLevels;

const separator = ' :: ';

/**
 * Instantiate via NEW operator.
 *
 * Flows:
 *   A flow is paired with logging level to display logs in the console.
 *   If you only want to show logs associated to a particular flow, you can name the flow as part of
 *   the instantiation of the service (and part of env config). Think 'siteMap' flow or 'autoPay' flow.
 *
 *   If a flow is not present, any messages will be logged solely determined by logging level.
 *
 *     @example
 *     // siteMap is the name of the flow here, and is optional
 *     private readonly _logger = new LoggingService('SiteMapComponent', 'siteMap');
 *
 *     @example
 *     // You can send messages with a sender, or just the message
 *     this._logger.debug('sendMessage()', 'Doing something');
 *     this._logger.info('Just some useful info.');
 *     this._logger.warn('Something did not exist but that should be fine.');
 *     this._logger.error('There HAS to be this thing existing.');
 *     this._logger.critical('Services are down!! Contact Support to let them know at whatever@whatever.com');
 *
 *     @example
 *     // You can send an object or anything at all as a final argument
 *     // If applicable, you can expand it in the console
 *     this._logger.debug('ngOnInit', 'starting with site', defaultSite);
 *     this._logger.debug('websocket message', message);
 */
export class LoggingService {
  private readonly _allowedLevel = environments[env.env].logging
    .level as LogLevel;
  private readonly _allowedFlows = environments[env.env].logging.flows;
  private readonly _ignoredFlows = environments[env.env].logging.ignoredFlows;
  private readonly _flow: string;
  private readonly _name: string;
  private readonly _showHighlights = environments[env.env].logging.highlights;

  constructor(name: string, flow?: string) {
    this._name = name;
    this._flow = flow;
    if (Object.keys(logLevels).indexOf(this._allowedLevel) === -1) {
      this._allowedLevel = 'critical';
    }
  }

  /**
   * Intended to be the most granular type of log. Use to log where you are and information you got from responses.
   * @param sender function name the log is being called from.
   * @param message the meat of the log.
   */
  public debug(sender: string, message: string): void;
  /**
   * Intended to be the most granular type of log. Use to log where you are and information you got from responses.
   * @param sender function name the log is being called from.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public debug(sender: string, message: string, loggedObject: unknown): void;
  /**
   * Intended to be the most granular type of log. Use to log where you are and information you got from responses.
   * @param message whatever you want to say about where you are or what you are doing
   */
  public debug(message: string): void;
  /**
   * Intended to be the most granular type of log. Use to log where you are and information you got from responses.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public debug(message: string, loggedObject: unknown): void;
  public debug(
    sender?: string,
    message?: string,
    optionalObject?: unknown
  ): void {
    if (
      logLevels[this._allowedLevel] <= logLevels.debug &&
      this.isAllowedFlow()
    ) {
      // handle our unique overloaded param ordering
      if (typeof message !== 'string') {
        optionalObject = message;
        message = sender;
        sender = '';
      }
      this.log(
        logLevels.debug,
        LoggingService.formatLog(this._name, sender, message),
        optionalObject
      );
    }
  }

  /**
   * Regular information you'd normally show.
   * @param sender function name the log is being called from.
   * @param message the meat of the log.
   */
  public info(sender: string, message: string): void;
  /**
   * Regular information you'd normally show.
   * @param sender function name the log is being called from.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public info(sender: string, message: string, loggedObject: unknown): void;
  /**
   * Regular information you'd normally show.
   * @param message whatever you want to say about where you are or what you are doing
   */
  public info(message: string): void;
  /**
   * Regular information you'd normally show.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public info(message: string, loggedObject: unknown): void;
  public info(
    sender?: string,
    message?: string,
    optionalObject?: unknown
  ): void {
    if (
      logLevels[this._allowedLevel] <= logLevels.info &&
      this.isAllowedFlow()
    ) {
      // handle our unique overloaded param ordering
      if (typeof message !== 'string') {
        optionalObject = message;
        message = sender;
        sender = '';
      }
      this.log(
        logLevels.info,
        LoggingService.formatLog(this._name, sender, message),
        optionalObject
      );
    }
  }

  /**
   * Let the user know something has happened that shouldn't break anything, but wasn't expected.
   * @param sender function name the log is being called from.
   * @param message the meat of the log.
   */
  public warn(sender: string, message: string): void;
  /**
   * Let the user know something has happened that shouldn't break anything, but wasn't expected.
   * @param sender function name the log is being called from.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public warn(sender: string, message: string, loggedObject: unknown): void;
  /**
   * Let the user know something has happened that shouldn't break anything, but wasn't expected.
   * @param message whatever you want to say about where you are or what you are doing
   */
  public warn(message: string): void;
  /**
   * Let the user know something has happened that shouldn't break anything, but wasn't expected.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public warn(message: string, loggedObject: unknown): void;
  public warn(
    sender?: string,
    message?: string,
    optionalObject?: unknown
  ): void {
    if (
      logLevels[this._allowedLevel] <= logLevels.warn &&
      this.isAllowedFlow()
    ) {
      // handle our unique overloaded param ordering
      if (typeof message !== 'string') {
        optionalObject = message;
        message = sender;
        sender = '';
      }
      this.log(
        logLevels.warn,
        LoggingService.formatLog(this._name, sender, message),
        optionalObject
      );
    }
  }

  /**
   * Let the user know something has happened which HAS broken something.
   * @param sender function name the log is being called from.
   * @param message the meat of the log.
   */
  public error(sender: string, message: string): void;
  /**
   * Let the user know something has happened which HAS broken something.
   * @param sender function name the log is being called from.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public error(sender: string, message: string, loggedObject: unknown): void;
  /**
   * Let the user know something has happened which HAS broken something.
   * @param message whatever you want to say about where you are or what you are doing
   */
  public error(message: string): void;
  /**
   * Let the user know something has happened which HAS broken something.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public error(message: string, loggedObject: unknown): void;
  public error(
    sender?: string,
    message?: string,
    optionalObject?: unknown
  ): void {
    if (
      logLevels[this._allowedLevel] <= logLevels.error &&
      this.isAllowedFlow()
    ) {
      // handle our unique overloaded param ordering
      if (typeof message !== 'string') {
        optionalObject = message;
        message = sender;
        sender = '';
      }
      this.log(
        logLevels.error,
        LoggingService.formatLog(this._name, sender, message),
        optionalObject
      );
    }
  }

  /**
   * Probably the only level we will leave in Prod. Reserve for messages ALL end users can see.
   * @param sender function name the log is being called from.
   * @param message the meat of the log.
   */
  public critical(sender: string, message: string): void;
  /**
   * Probably the only level we will leave in Prod. Reserve for messages ALL end users can see.
   * @param sender function name the log is being called from.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public critical(sender: string, message: string, loggedObject: unknown): void;
  /**
   * Probably the only level we will leave in Prod. Reserve for messages ALL end users can see.
   * @param message whatever you want to say about where you are or what you are doing
   */
  public critical(message: string): void;
  /**
   * Probably the only level we will leave in Prod. Reserve for messages ALL end users can see.
   * @param message the meat of the log. Context describing the object would be nice
   * @param loggedObject literally anything. It will be displayed after the message, and be expandable if applicable.
   */
  public critical(message: string, loggedObject: unknown): void;
  public critical(
    sender?: string,
    message?: string,
    optionalObject?: unknown
  ): void {
    if (
      logLevels[this._allowedLevel] <= logLevels.critical &&
      this.isAllowedFlow()
    ) {
      // handle our unique overloaded param ordering
      if (typeof message !== 'string') {
        optionalObject = message;
        message = sender;
        sender = '';
      }
      this.log(
        logLevels.critical,
        LoggingService.formatLog(this._name, sender, message),
        optionalObject
      );
    }
  }

  private log(level: number, message: string, optionalObject?: unknown): void {
    let styling = '';

    switch (level) {
      case logLevels.debug: {
        message = `DEBUG${separator}${message}`;
        styling = this._showHighlights
          ? 'background: purple;color: white'
          : 'color: purple';
        break;
      }
      case logLevels.info: {
        message = `INFO${separator}${message}`;
        styling = this._showHighlights
          ? 'background: blue;color: white'
          : 'color: blue';
        break;
      }
      case logLevels.warn: {
        message = `WARN${separator}${message}`;
        styling = this._showHighlights
          ? 'background: yellow;color: red'
          : 'color: orange';
        break;
      }
      case logLevels.error: {
        message = `ERROR${separator}${message}`;
        styling = this._showHighlights
          ? 'background: red;color: white'
          : 'color: red';
        break;
      }
      case logLevels.critical: {
        message = `CRITICAL${separator}${message}`;
        styling = this._showHighlights
          ? 'background: lightgrey;color: black'
          : 'color: darkred';
        break;
      }
      default:
        break;
    }

    // Ensure a final separator if we have that optional object
    if (optionalObject === undefined) {
      optionalObject = '';
    } else if (
      message.lastIndexOf(separator) !==
      message.length - separator.length
    ) {
      message += separator;
    }

    // eslint-disable-next-line no-console
    console.info(`%c** ${message}`, styling, optionalObject);
  }

  private isAllowedFlow(): boolean {
    // True if a flow isn't named for this logging service
    // True if allowedFlows is not defined
    // True if allowedFlows is length of zero
    // True if flow is in allowedFlows
    // False if flow is in ignoredFlows
    return (
      (!this._flow ||
        this._allowedFlows?.length <= 0 ||
        this._allowedFlows.includes(this._flow)) &&
      !this._ignoredFlows.includes(this._flow)
    );
  }

  private static formatLog(
    name: string,
    sender: string,
    message: string
  ): string {
    return `${name}${sender ? '.' + sender : ''}${separator}${message}`;
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-de-personal-info',
  templateUrl: './de-personal-info.component.html',
  styleUrls: ['./de-personal-info.component.scss'],
})
export class DePersonalInfoComponent {
  constructor(private dialogRef: MatDialogRef<DePersonalInfoComponent>) {}

  closeDialog() {
    this.dialogRef.close(true);
  }
}

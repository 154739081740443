import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Directive()
export class BaseModalComponent extends BaseComponent {
  @Input() modalOpen = false;

  @Output() closeModal = new EventEmitter<any>();

  close(val: any) {
    this.modalOpen = false;
    this.closeModal.emit(val);
  }
}

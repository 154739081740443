import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserTableView } from '../../../models';
import { BaseModalComponent } from 'src/app/shared/components/modals/base-modal.component';

export interface TableViewsCloseEvent {
  tableView: UserTableView;
  action: 'load' | 'delete';
}

/**
 * Modal providing a list of saved Table Views for the user, with options
 * to select one, and delete from our records or load into the current view.
 */
@Component({
  selector: 'app-table-views-modal',
  templateUrl: './table-views-modal.component.html',
  styleUrls: ['./table-views-modal.component.scss'],
})
export class TableViewsModalComponent extends BaseModalComponent {
  @Input() tableViews: UserTableView[];

  protected tableViewForm = new FormGroup({
    tableView: new FormControl<UserTableView>(null, Validators.required),
    searchText: new FormControl(''),
  });

  protected deleteTableView() {
    const tableView = { ...this.tableViewForm.value.tableView };
    this.tableViewForm.reset();
    this.close({
      tableView,
      action: 'delete',
    } as TableViewsCloseEvent);
  }

  protected loadTableView() {
    this.close({
      tableView: this.tableViewForm.value.tableView,
      action: 'load',
    } as TableViewsCloseEvent);
  }
}

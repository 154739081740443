import { Component, Input } from '@angular/core';
import { BatteryData } from 'src/app/shared/models/battery/batteryData';
import { ChargingStatusType } from 'src/app/shared/models/battery/chargingStatus';
import { Site } from 'src/app/shared/models/site';
import { BATTERY_SITE_MAPPING } from 'src/app/modules/sites/pages/site-specific/battery-site-mapping';
import { SitesService } from 'src/app/shared/services/api/sites.service';

@Component({
  selector: 'app-battery-header',
  templateUrl: './battery-header.component.html',
  styleUrls: ['./battery-header.component.scss'],
})
export class BatteryHeaderComponent {
  @Input() set batteryData(data: BatteryData) {
    if (data) {
      this._batteryData = data;
      this.updateBatteryStatus(this._batteryData);
      this.isLoading = false;
    }
  }
  @Input() set site(data: Site) {
    if (data) {
      this._site = data;
      this.siteName = this.batteryMapData[this._site.unique_id];
    }
  }
  public siteName: string;
  public isLoading = true;
  protected _batteryData: BatteryData;
  protected _site: Site;
  batteryMapData = BATTERY_SITE_MAPPING;
  batteryProgress: number;
  batteryChargingStatus: ChargingStatusType;
  chargingStatusType = ChargingStatusType;
  currentUsage: number;
  daysRemaining: string;

  constructor(protected sitesService: SitesService) {}

  private updateBatteryStatus(data: BatteryData) {
    const latestEnergyData = data;
    this.batteryProgress = latestEnergyData.customerFacingSOC;
    this.batteryChargingStatus =
      latestEnergyData.timeRemaining > 0
        ? ChargingStatusType.Discharging
        : ChargingStatusType.Charging;
    this.currentUsage = Math.round(latestEnergyData.energyStorageSystem?.power.real / 1000);
    this.daysRemaining =
      this.batteryChargingStatus === ChargingStatusType.Discharging
        ? this.splitTimeToDays(latestEnergyData.timeRemaining)
        : null;
  }
  private splitTimeToDays(timeRemaining: number) {
    const hours = Math.round(timeRemaining);
    const days = Math.floor(hours / 24);
    const remainder = hours % 24;
    const h = Math.floor(remainder);
    return `${days} days ${h} hours usage remaining`;
  }
}

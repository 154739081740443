import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export type ModalTheme = 'primary' | 'secondary';

@Component({
  selector: 'app-de-modal',
  templateUrl: './de-modal.component.html',
  styleUrls: ['./de-modal.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('modalFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state(
        'in',
        style({
          opacity: 1,
          transform: 'scale(1)',
        })
      ),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0.5)',
        }),
        animate('300ms cubic-bezier(.49,1.25,.72,.91)'),
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(300, style({ opacity: 0 }))),
    ]),
  ],
})
export class DeModalComponent {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();

  @Input() modalTitle = '';
  @Input() showHeader = true;
  @Input() showMask = true;
  @Input() open = false;
  @Input() showClose = true;
  @Input() center = true;
  @Input() width = 'auto';
  @Input() height = 'auto';
  @Input() padding = '1em';
  @Input() top = 'auto';
  @Input() bottom = 'auto';
  @Input() left = 'auto';
  @Input() right = 'auto';
  @Input() theme: ModalTheme = 'primary';
  @Input() showCloseOnly = false;

  @ViewChild('centerCloseButton') set centerClose(centerClose: ElementRef) {
    if (centerClose) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      centerClose.nativeElement.focus();
    }
  }

  @ViewChild('uncenteredCloseButton') set uncenteredClose(
    uncenteredClose: ElementRef
  ) {
    if (uncenteredClose) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      uncenteredClose.nativeElement.focus();
    }
  }

  @ViewChild('onlyCloseButton') set onlyClose(onlyClose: ElementRef) {
    if (onlyClose) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      onlyClose.nativeElement.focus();
    }
  }

  closeModal() {
    this.close.emit();
  }
}

export class UserInfo {
  constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public fullName: string
  ) {}

  static fromApiModel(model: UserInfoApiModel): UserInfo {
    return {
      email: model?.email,
      firstName: model?.first_name,
      lastName: model?.last_name,
      fullName: model?.first_name + ' ' + model?.last_name,
    };
  }
}

export interface UserInfoApiModel {
  email: string;
  first_name: string;
  last_name: string;
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertType } from 'src/app/shared/models/enums/alertType';
import { MsgData, MessageComponent } from '../components/message/message.component';

/**
 * Used to open MatSnackBars with custom components. Error messages
 * will only dismiss on user action, whereas all others may also
 * dismiss after a few seconds.
 */
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  private readonly _DEFAULT_DURATION = 5_000;

  constructor(private snackBar: MatSnackBar) {}

  /**
   * Show a snackbar message with green background
   * @param message
   * @param permanent
   */
  public success(message: string, permanent = false): void {
    if (permanent) {
      this.showMessage(new MsgData(AlertType.Success, message), 0);
    } else {
      this.showMessage(new MsgData(AlertType.Success, message));
    }
  }

  /**
   * Show a snackbar message with red background - will ONLY dismiss on user action
   * @param message
   */
  public error(message: string): void {
    this.showMessage(new MsgData(AlertType.Error, message), 0);
  }

  /**
   * Show a snackbar message with a gray background
   * @param message
   */
  public info(message: string): void {
    this.showMessage(new MsgData(AlertType.Info, message));
  }

  /**
   * Show a snackbar message with a yellow background
   * @param message
   */
  public warning(message: string): void {
    this.showMessage(new MsgData(AlertType.Warning, message));
  }

  /**
   * Generic method to open a snackbar
   * @param messageData
   * @param duration
   * @private
   */
  private showMessage(messageData: MsgData, duration = this._DEFAULT_DURATION) {
    this.snackBar.openFromComponent(MessageComponent, {
      data: messageData,
      duration: duration,
      verticalPosition: 'top',
      // panelClass must be set at global level, with --mdc-snackbar-container-color w/ !important set underneath it for bg color
      panelClass: `snack-bar--${messageData.type}`,
    });
  }
}

import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from 'src/app/shared/services/api/feedback.service';
import { FeedbackModel } from '../../models/feedback';
import { SnackBarService } from '../../services/snack-bar.service';
import { finalize } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/core/services/authorization.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent {
  public showForm = false;
  public formValid = false;
  public feedbackForm: FormGroup;
  public saving = false;
  protected loadAtFirst = true;

  constructor(
    public authService: AuthorizationService,
    private fb: FormBuilder,
    private snackBar: SnackBarService,
    private feedbackService: FeedbackService
  ) {
    this.feedbackForm = this.fb.group({
      // Could consider doing more/custom validation if we want more strict rules on the form
      summary: new FormControl<string>('', [Validators.required, Validators.minLength(1)]),
      ideas: new FormControl<string>('', [Validators.required, Validators.minLength(1)]),
    });
    // cheap animation trick
    setTimeout(() => (this.loadAtFirst = false), 200);
  }

  public toggleForm() {
    this.showForm = !this.showForm;
  }

  public submitForm() {
    // name and email are gathered from the jwt on the backend
    this.saving = true;
    const formData = this.feedbackForm.getRawValue();
    const feedback: FeedbackModel = {
      idea: formData.ideas,
      summary: formData.summary,
    };
    if (this.formValid) {
      this.feedbackService
        .postFeedback(feedback)
        .pipe(finalize(() => (this.saving = false)))
        .subscribe(
          () => {
            this.snackBar.success('Feedback submitted successfully.');
            this.toggleForm();
          },
          () => {
            this.snackBar.error('Unable to submit feedback at this time.');
          }
        );
    }
  }

  public checkValid(element: HTMLInputElement | HTMLTextAreaElement) {
    if (element.value.length) {
      element.style.setProperty('background', 'url(assets/icons/green-check.svg) no-repeat right');
      element.style.setProperty('border-color', 'black');
    } else {
      element.style.setProperty('background', 'url(assets/icons/error.svg) no-repeat right');
    }
    this.formValid = true;
    for (const field in this.feedbackForm.controls) {
      const inputField = this.feedbackForm.get(field);
      if (!inputField.valid) {
        this.formValid = false;
        break;
      }
    }
  }
}

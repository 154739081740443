<footer class="de-footer">
  <div class="de-footer__bottom-container de-footer__bottom-container--no-links">
    <div class="de-footer__copyright-container flex gap-80">
      <div class="de-footer__copyright-text">© Duke Energy Corporation. All Rights Reserved.</div>
      <div class=" flex gap-16 ">
        <div class="de-footer__copyright-text hover:underline hover:cursor-pointer"><a
            href="https://www.duke-energy.com/legal/privacy" target="_blank"
            class="no-underline !text-white">Privacy</a></div>
        <div class="de-footer__copyright-text hover:underline hover:cursor-pointer"><a
            href="https://www.duke-energy.com/legal/ccpa" target="_blank" class="no-underline !text-white">California
            Consumer Privacy Statement</a></div>
        <div class="de-footer__copyright-text hover:underline hover:cursor-pointer" (click)="openDialog()"><a
            class="no-underline !text-white">Do Not Sell or Share My Personal Information (CA)</a></div>
        <div class="de-footer__copyright-text hover:underline hover:cursor-pointer"><a
            href="https://www.duke-energy.com/legal/terms-of-use" target="_blank" class="no-underline !text-white">Terms
            of use</a></div>
        <div class="de-footer__copyright-text hover:underline hover:cursor-pointer"><a
            href="https://www.duke-energy.com/customer-service/accessibility" target="_blank"
            class="no-underline !text-white">Accessibility</a></div>
      </div>
    </div>
  </div>
</footer>

import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { SortDirection, Sort, MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isPagedResult, PagedResult } from 'src/app/shared/models/pagedResult';
import { getNestedObjectProperty } from '../../utils/table-functions';
import { CellDataAlignment, TableCellDef, CellDataType } from './tableCellDef';
import { SimpleFilterModel } from './simpleFilterModel';
import * as date_fns from 'date-fns';

// used to define the callback function in the parent component
export type Delegate<T> = (...args: any[]) => T;

@Component({
  selector: 'app-data-cell',
  template: '',
})
export class DataCellComponent {
  @Input() name: string;
  @Input() label: string;
  @Input() type: CellDataType = CellDataType.Text;
  @Input() alignment: CellDataAlignment = CellDataAlignment.Left;
  @Input() dateFormat = 'l';
  @Input() cellTemplate?: TemplateRef<any>;
  @Input() dataAccessor?: (dataObject: any, propertyName: string) => any;
}

@Component({
  selector: 'app-display-table',
  templateUrl: './display-table.component.html',
  styleUrls: ['./display-table.component.scss'],
})
export class DisplayTableComponent implements AfterContentInit, OnDestroy {
  // paging
  @Input() pagingEnabled: boolean;
  @Input() serverSidePaging = false;
  @Input() defaultNumItems = 10;

  // sorting
  @Input() sortingEnabled = true;
  @Input() serverSideSorting = false;
  @Input() defaultSort: string;
  @Input() defaultSortDirection: SortDirection = 'asc';

  @Input() includeTextFilter: boolean;
  @Input() noRecordsText: string;
  @Input() includeHeaderRow = true;

  @Input() getData: Delegate<Observable<any[] | PagedResult<any>>>;
  @Input() getDataArgs: any;

  @Input() isClickable: Delegate<boolean>;
  @Input() rowClass: string | string[];
  @Input() columnClass: string | string[];

  @Input() loading = false;

  @Output() rowClick = new EventEmitter<any>();
  @Output() filtersInitialized = new EventEmitter<SimpleFilterModel>();
  @Output() sortChange = new EventEmitter<Sort>();
  @Output() pageChange = new EventEmitter<PageEvent>();

  columns: TableCellDef[];
  gridColumnType = CellDataType;
  gridColumnAlignment = CellDataAlignment;
  dataSource: MatTableDataSource<any>;
  paginator: MatPaginator;
  sort: MatSort;
  filterValue: SimpleFilterModel;
  totalNumberOfRows: number;

  @ViewChild(MatPaginator)
  set _paginator(paginator: MatPaginator) {
    this.paginator = paginator;
    if (this.paginator && this.pagingEnabled && !this.serverSidePaging) {
      // don't assign the paginator to the dataSource if using server paging
      // this causes the total number of records to be ignored
      this.dataSource.paginator = this.paginator;
    }
  }

  @ViewChild(MatSort)
  set _sort(sort: MatSort) {
    this.sort = sort;
    if (this.sort && this.sortingEnabled && !this.serverSideSorting) {
      this.dataSource.sort = this.sort;
    }
  }

  @ContentChildren(DataCellComponent)
  set _columnComponents(columns: QueryList<DataCellComponent>) {
    this.columns = columns.map(
      (x) =>
        new TableCellDef(
          x.name,
          x.label,
          x.dataAccessor,
          x.type,
          x.alignment,
          x.dateFormat,
          x.cellTemplate
        )
    );
  }

  get displayedColumns(): string[] {
    return this.columns ? this.columns.map((x) => x.name) : [];
  }

  private _unsubscribe = new Subject<void>();
  private _searchTextChanged: Subject<string> = new Subject<string>();

  constructor() {}

  ngAfterContentInit() {
    this.filterValue = new SimpleFilterModel();

    this.filtersInitialized.emit(this.filterValue);

    this.dataSource = new MatTableDataSource<any>();
    this.dataSource.sortingDataAccessor = getNestedObjectProperty;

    this.dataSource.filterPredicate = this.filterGrid;

    if (!this.getData) {
      return;
    }

    this.getData(this.getDataArgs)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((resp) => {
        if (!resp) {
          return;
        }
        if (isPagedResult(resp)) {
          const pagedResult = resp;
          this.dataSource.data = pagedResult.results;
          this.totalNumberOfRows = pagedResult.totalNumberOfRows;
        } else {
          const data = resp;
          this.dataSource.data = data;
          this.totalNumberOfRows = data.length;
        }

        this.applyFilters();
      });

    this._searchTextChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((text) => {
        this.filterValue.search = text;
        this.applyFilters();
        this.filtersInitialized.emit(this.filterValue);
      });
  }

  rowClicked(entity: any) {
    if (!this.clickable(entity)) {
      return;
    }
    this.rowClick.emit(entity);
  }

  clickable(entity: any) {
    return this.isClickable
      ? this.isClickable(entity)
      : !!this.rowClick.observers.length;
  }

  onTextChanged(text: string) {
    this._searchTextChanged.next(text);
  }

  toggleActiveFilter() {
    this.filterValue.activeOnly = !this.filterValue.activeOnly;
    this.applyFilters();
    this.filtersInitialized.emit(this.filterValue);
  }

  applyFilters() {
    this.dataSource.filter = JSON.stringify(this.filterValue);
  }

  refresh() {
    this.filtersInitialized.emit(this.filterValue);
  }

  onPage(event: PageEvent) {
    this.pageChange.emit(event);
  }

  onCancelSearch() {
    this.filterValue.search = '';
    this.applyFilters();
  }

  onSort(event: Sort) {
    this.sortChange.emit(event);
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  getColumnData(element: any, column: TableCellDef) {
    const propValue = column.dataAccessor
      ? column.dataAccessor(element, column.name)
      : getNestedObjectProperty(element, column.name);

    if (column.type === CellDataType.Date && column.dateFormat) {
      if (propValue) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return date_fns.format(propValue, column.dateFormat);
      }
      return '';
    }

    return propValue;
  }

  private filterGrid = (data: any, filter: string): boolean => {
    if (!this.includeTextFilter) {
      return true;
    }

    const filterValue: SimpleFilterModel = JSON.parse(filter);
    let isMatch = true;
    if (this.includeTextFilter) {
      isMatch =
        isMatch && this.filterDisplayedGridColumns(data, filterValue.search);
    }
    return isMatch;
  };

  private filterDisplayedGridColumns(dataObject: any, searchFilter: string) {
    const transformedFilter = searchFilter.trim().toLowerCase();
    const gridDataAsString =
      this.columns
        .map((c) => (this.getColumnData(dataObject, c) || '').toString())
        .join(' ')
        .trim()
        .toLowerCase();
    return gridDataAsString.includes(transformedFilter);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Convert from minutes to hours:minutes format. Empty string if minutes value is falsy.
 */
@Pipe({
  name: 'minutesToHoursMinutes',
})
export class MinutesToHoursMinutesPipe implements PipeTransform {
  transform(minutes: number, format: 'long' | 'short' = 'short'): string {
    if (!minutes) {
      return '';
    }
    const hours = Math.floor(minutes / 60);
    const leftoverMinutes = Math.floor(minutes % 60);

    let returnVal = '';

    if (format === 'long') {
      if (hours > 0) {
        returnVal = `${hours} hour${hours === 1 ? '' : 's'} `;
      }
      returnVal += `${leftoverMinutes} minute${
        leftoverMinutes === 1 ? '' : 's'
      }`;
    } else {
      returnVal = `${hours}:${leftoverMinutes}`;
    }

    return returnVal;
  }
}

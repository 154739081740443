import { InterconnectionAmendment } from "../interconnection";

export class Amendment {
    desc: string;
    ordinal: string;
    contractId: number;
    date: Date;
    uniqueId: number;

    constructor(data: InterconnectionAmendment) {
        this.desc = data.amendment_desc;
        this.ordinal = data.amendment_ordinal;
        this.contractId = data.contract_id;
        this.date = data.dt_utc;
        this.uniqueId = data.unique_id;
    }
}
<div class="payment-history__container">
  <div class="text-xl">{{ this.tableTitle }}</div>

  <ng-container *ngIf="tableData?.data?.length; else noData">
    <table
      class="border-t-gray border-t-1 w-full text-gray-dark w-100"
      mat-table
      matSort
      [dataSource]="tableData"
    >
      <ng-container *ngFor="let column of columnDefs; let i = index" [matColumnDef]="column">
        <th
          class="text-teal-darker border-r-gray text-center"
          [class.border-r-1]="i === 0"
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
        >{{ columnHeaders[i] }}</th>
        <td
          class="align-middle border-r-gray border-b-1 border-b-gray text-center"
          [class.border-r-1]="i === 0"
          mat-cell
          *matCellDef="let element"
        > {{ element[column] }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
      <tr mat-row *matRowDef="let row; columns: columnDefs;"></tr>
    </table>
    <div>
      <mat-paginator [pageSizeOptions]="paginatorSizeOptions"></mat-paginator>
    </div>
  </ng-container>
  <ng-template #noData>
    <app-no-data title="No {{ tableTitle }} Data" summary="{{ tableTitle }} data will appear here"> </app-no-data>
  </ng-template>
</div>

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environments } from 'src/config/env.config';
import { env } from 'src/config/env';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { JurisdictionShortCode } from 'src/app/shared/models/jurisdiction';

// TODO: Remove Injectable annotation and update tests to not inject
/**
 * A very generic class that should only provide base functionality
 * for classes that interact with our backend API via http client.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  protected apiUrl: string = environments[env.env].apiUrl as string;

  protected readonly http = inject(HttpClient);
  protected readonly snackBar = inject(SnackBarService);

  constructor() {}

  /**
   * More gracefully create or update an HttpParams object, with queryParam paramKey and value.
   *
   * @example
   *     let httpParams = ApiService.setHttpParam('key', 'value');
   *     httpParams = ApiService.setHttpParam('key2', 'value2', httpParams);
   *
   * @param paramKey
   * @param paramValue
   * @param params
   * @protected
   */
  public static setHttpParam(
    paramKey: string,
    paramValue: string | number | boolean,
    params = new HttpParams()
  ): HttpParams {
    return params.append(paramKey, paramValue);
  }

  /**
   * Create or update an HttpParams object, assigning multiple values to the same queryParam paramKey
   *
   * @example
   *     let httpParams = ApiService.setHttpParamList('key', ['value1', 'value2']);
   *     httpParams = ApiService.setHttpParamList('key2', ['value3', 'value4'], httpParams);
   *
   * @param paramKey
   * @param paramList
   * @param params
   * @protected
   */
  public static setHttpParamList(
    paramKey: string,
    paramList: string[] | number[] | boolean[],
    params = new HttpParams()
  ): HttpParams {
    paramList.forEach((j: string | number | boolean) => {
      params = params.append(paramKey, j);
    });
    return params;
  }

  /**
   * Create or update an HttpParams object, assigning the 'jurisdiction' queryParam paramKey with multiple values
   * @param jurisdictions
   * @param params
   */
  public static setJurisdictionParamList(
    jurisdictions: JurisdictionShortCode[],
    params = new HttpParams()
  ): HttpParams {
    return this.setHttpParamList('jurisdiction', jurisdictions, params);
  }

  /**
   * Create or update an HttpParams object, assigning the 'jurisdiction' queryParam paramKey with multiple values
   * @param startDate
   * @param endDate
   * @param params
   */
  public static setStartEndDateParams(
    startDate: Date | string,
    endDate: Date | string,
    params: HttpParams = new HttpParams()
  ): HttpParams {
    const start = startDate instanceof Date ? startDate.toISOString() : startDate;
    const end = endDate instanceof Date ? endDate.toISOString() : endDate;

    params = this.setHttpParam('startDate', start, params);
    if (endDate) {
      params = this.setHttpParam('endDate', end, params);
    }
    return params;
  }

  /**
   * Create or update an HttpParams object, assigning the 'site' queryParam paramKey with multiple values
   * @param siteIds possibly empty list of site unique ids
   * @param params optional parameters to set within the parameters
   */
  public static setSiteParamList(siteIds: number[], params = new HttpParams()): HttpParams {
    return this.setHttpParamList('site', siteIds, params);
  }

  /**
   * Throws up a snackbar error message appended with " at this time.",
   * and rethrows the error
   * @param msg
   * @param err
   * @protected
   */
  protected handleError(msg: string, err: HttpErrorResponse | unknown): Observable<never> {
    this.snackBar.error(`${msg} at this time.`);
    return throwError(err);
  }
}

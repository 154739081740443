import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

// TODO: Will likely be replaced with other de-* components
@Component({
  selector: 'app-toggle',
  template: `
    <div class="duke-toggle">
      <span>{{ toggleTitle }}</span>
      <div class="container" (click)="handleToggle()" [class.enabled]="toggle">
        <div class="knob"></div>
      </div>
    </div>
  `,
  styleUrls: ['./toggle.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class ToggleComponent {
  @Input() toggle = false;
  @Input() toggleTitle: string;
  @Output() toggleChanged: EventEmitter<boolean>;

  constructor() {
    this.toggleChanged = new EventEmitter<boolean>();
  }

  handleToggle(): void {
    this.toggle = !this.toggle;
    this.toggleChanged.next(this.toggle);
  }
}

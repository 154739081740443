<div class="flex-wrap items-center justify-between">
  <div class="flex items-center">
    <ng-content select="[custom-filter]"></ng-content>
    <mat-form-field *ngIf="includeTextFilter" class="w-full w-">
      <img
        matPrefix
        src="assets/icons/search-base.svg"
        class="invert-[44%] sepia-[10%] saturate-[17%] hue-rotate-[52deg] brightness-[92%] contrast-[97%] w-20 h-20 mr-8 mb-[5px]"
        alt="search"
      />
      <mat-label>Search</mat-label>
      <input
        class="inline-block max-w-prose"
        matInput
        [(ngModel)]="filterValue.search"
        (ngModelChange)="onTextChanged($event)"
        autocomplete="off"
        type="search"
      />

      <button
        aria-label="Clear"
        type="button"
        *ngIf="filterValue.search && filterValue.search !== ''"
        class="search-bar-button float-right clear"
        (click)="onCancelSearch()"
      >
        <img class="search-bar-button-image" src="assets/icons/map/actions/close-small-gray-dark.svg" alt="X" />
      </button>
    </mat-form-field>
  </div>
</div>

<div class="relative" *ngIf="dataSource.filteredData.length || loading; else noRecords">
  <table
    mat-table
    [dataSource]="dataSource"
    class="!w-full"
    matSort
    (matSortChange)="onSort($event)"
    [matSortActive]="defaultSort"
    [matSortDirection]="defaultSortDirection"
    [ngClass]="!includeHeaderRow ? 'hide-header' : ''"
    aria-label="Custom Display Table"
  >
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
      <ng-container *ngIf="sortingEnabled">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [class.text-center]="column.alignment === gridColumnAlignment.Center"
          [class.text-right]="column.alignment === gridColumnAlignment.Right"
          [ngClass]="columnClass"
        >
          {{ column.label || column.name }}
        </th>
      </ng-container>
      <ng-container *ngIf="!sortingEnabled">
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.text-center]="column.alignment === gridColumnAlignment.Center"
          [class.text-right]="column.alignment === gridColumnAlignment.Right"
          [ngClass]="columnClass"
        >
          {{ column.label || column.name }}
        </th>
      </ng-container>
      <ng-container *ngIf="column.cellTemplate">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.data-label]="column.label || column.name"
          [class.text-center]="column.alignment === gridColumnAlignment.Center"
          [class.text-right]="column.alignment === gridColumnAlignment.Right"
          [ngClass]="columnClass"
        >
          <ng-template
            [ngTemplateOutletContext]="{
              element: element,
              column: column
            }"
            [ngTemplateOutlet]="column.cellTemplate"
          >
          </ng-template>
        </td>
      </ng-container>

      <ng-container *ngIf="!column.cellTemplate">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.data-label]="column.label || column.name"
          [class.text-center]="column.alignment === gridColumnAlignment.Center"
          [class.text-right]="column.alignment === gridColumnAlignment.Right"
          [ngClass]="columnClass"
        >
          <ng-container *ngIf="column.type === gridColumnType.Text || column.type === gridColumnType.Date">
            {{ getColumnData(element, column) }}
          </ng-container>
          <mat-checkbox
            *ngIf="column.type === gridColumnType.Checkbox"
            [checked]="getColumnData(element, column)"
            disabled="true"
          >
          </mat-checkbox>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="tracking-[0.35px] leading-[26px]"
      *matRowDef="let element; columns: displayedColumns"
      [class.clickable]="clickable(element)"
      [ngClass]="rowClass"
      (click)="rowClicked(element)"
    ></tr>
  </table>

  <mat-paginator
    *ngIf="pagingEnabled"
    [length]="totalNumberOfRows"
    [pageSize]="defaultNumItems"
    [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="onPage($event)"
    showFirstLastButtons
  >
  </mat-paginator>
  <app-loading *ngIf="loading"></app-loading>
</div>
<ng-template #noRecords>
  <mat-card appearance="outlined">
    <ng-container>{{ noRecordsText }}</ng-container>
  </mat-card>
</ng-template>
<ng-content></ng-content>

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

// TODO: Will likely be replaced with other de-* components
@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IconButtonComponent {
  @Input() name: string;
  @Input() iconName: string;
  @Input() buttonType: 'primary' | 'secondary' | 'text';
  @Input() suffixIconName: string;
  @Output() buttonClicked: EventEmitter<any>;

  constructor() {
    this.buttonClicked = new EventEmitter<any>();
  }

  onClick(): void {
    this.buttonClicked.next({});
  }
}

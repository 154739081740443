import { Injectable } from '@angular/core';
import { env } from 'src/config/env';
import { EnvironmentConfig, environments, FeatureFlag } from 'src/config/env.config';

/**
 * Get environment configs based on src/config/env.config.ts
 */
@Injectable()
export class AppConfig {
  private _config: EnvironmentConfig;
  private _currentEnvironment = env.env;
  private _environmentConfigs = environments;

  /*
   * Get the config value for the matching key
   */
  public getConfig(key: keyof EnvironmentConfig) {
    return this._config[key];
  }

  /**
   * Shortcut to get a feature flag from the mapping in config.
   * Note that feature flags are interpolated as strings when
   * retrieved from Vault.
   * @param flag
   */
  public getFeatureFlag(flag: FeatureFlag): boolean {
    const envFlag = this.getConfig('featureFlags')[flag] as boolean | string;
    return envFlag === 'true' || envFlag === true;
  }

  public load(): void {
    this._config = this._environmentConfigs[this._currentEnvironment] as EnvironmentConfig;
  }
}

import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-de-chip',
  templateUrl: './de-chip.component.html',
  styleUrls: ['./de-chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeChipComponent {
  @Output() removeChip: EventEmitter<any>;

  constructor() {
    this.removeChip = new EventEmitter<any>();
  }

  onClose(): void {
    this.removeChip.next({});
  }
}

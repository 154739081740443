import { Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { PlannedOutageService } from 'src/app/shared/services/planned-outage.service';
import { InputColumn } from '../../table-with-filters/models';
import { TableWithFiltersComponent } from '../../table-with-filters/table-with-filters.component';
import { OutagesBase, OutagesBaseModel } from 'src/app/shared/models/plannedOutage';
import { SitesService } from 'src/app/shared/services/api/sites.service';
import { Site } from 'src/app/shared/models/site';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { Router } from '@angular/router';
import { UrlService } from 'src/app/services/url.service';
import { FileService } from 'src/app/shared/services/utils/file.service';
import { ApprovalStatus } from 'src/app/shared/models/enums/approvalStatus';

@Component({
  selector: 'app-outages-pending',
  templateUrl: './outages-pending.component.html',
  styleUrls: ['./outages-pending.component.scss'],
})
export class OutagesPendingComponent extends BaseComponent {
  @ViewChild(TableWithFiltersComponent)
  private _table: TableWithFiltersComponent<OutagesBase>;

  @Input() set site(site: Site) {
    if (site) {
      this.siteModel = site;
      // TODO Assume this should be integrated into GTM.
      this.siteNameForGa = GtmService.format(this.siteModel.name);
    }
  }

  columns: InputColumn[] = [
    {
      name: 'Outage Type',
      key: 'outageType',
      type: 'string',
      display: true,
      displayFilter: true,
    },
    {
      name: 'Approval Status',
      key: 'approvalStatus',
      type: 'string',
      sticky: true,
      display: true,
      displayFilter: true,
    },

    {
      name: 'Installed Capacity (MW)',
      key: 'installedCapacity',
      type: 'string',
      display: true,
      displayFilter: true,
    },
    {
      name: 'Temp Expected Capacity',
      key: 'tempExpectedCapacity',
      type: 'string',
      display: true,
      displayFilter: true,
    },
    {
      name: 'Event Start',
      key: 'eventStartDate',
      type: 'date',
      display: true,
      displayFilter: true,
    },
    {
      name: 'Event End',
      key: 'eventEndDate',
      type: 'date',
      display: true,
      displayFilter: true,
    },
    {
      name: 'Comments',
      key: 'comments',
      type: 'string',
      display: true,
      displayFilter: true,
    },
  ];
  currentDate = new Date().toISOString();
  siteModel: Site;
  siteNameForGa: string;
  protected tableData: OutagesBase[] = [];
  constructor(
    public fileService: FileService,
    public plannedOutageService: PlannedOutageService,
    public urlService: UrlService,
    private router: Router,
    public sitesService: SitesService
  ) {
    super();
    setTimeout(() => this.loadPendingOutageData());
  }

  loadPendingOutageData(): void {
    this.plannedOutageService.getPendingOutages().subscribe((resp: OutagesBaseModel[]) => {
      this.tableData = [];
      const sitePendingOutages = resp.filter(
        (item) =>
          item.unique_id === this.siteModel.uniqueId &&
          item.approval_status === ApprovalStatus.Pending
      );
      for (const row of sitePendingOutages) {
        const item = new OutagesBase(row);
        this.tableData.push(item);
      }
    });
  }

  createNew() {
    this.sitesService.specificSite$.next(this.siteModel.unique_id);
    this.urlService.setPreviousUrl(this.router.url);
    void this.router.navigateByUrl('/action-center/planned-outages');
  }
}

<div class="py-[10px] pl-[30px]">
    <div class="flex justify-between text-xl text-[#4C4C4C] pr-[10px] ">
        <div class="w-[120px] h-[40px] flex justify-center items-center">
            <img src="../../../../assets/icons/logos/duke-energy.svg" class="modal-image" />
        </div>
        <button mat-icon-button [mat-dialog-close]="false" class="float-right dark:text-dm-accent ">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr class="text-[#e9e9e9]">
<div class="ml-[30px] mr-[3px] pr-[30px]">
    <div class="text-[#696969] modal-title my-[10px]">Do Not Sell or Share My Personal Information (CA)</div>
    <div class="mb-[25px]">
        <div class="text-balance modal-text">Thank you for contacting Duke Energy. Because this right is available only to California residents, we will not be able to complete your request. For additional information about Duke Energy’s privacy practices, please review the Duke Energy Digital Privacy Policy.</div>
        <div ><a href="https://www.duke-energy.com/legal/privacy" target="_blank" class="underline modal-text text-[#00789e]">Duke Energy Privacy Policy.</a></div>
    </div>
</div>
import { DOCUMENT } from '@angular/common';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FileResult } from '../../models/fileResults';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  downloadData(data: any[], filename: string) {
    if (filename.endsWith('.csv')) {
      const csv = this.getCSV(data);
      const blob = new Blob([csv], { type: 'text/csv' });
      this.downloadBlob(blob, filename);
    } else if (filename.endsWith('.json')) {
      const json = JSON.stringify(data);
      const blob = new Blob([json], { type: 'text/json' });
      this.downloadBlob(blob, filename);
    }
  }

  downloadToBrowser(file: Blob, fileName: string) {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  createFileResult(response: HttpResponse<Blob>): FileResult {
    return new FileResult(response.body, this.getFileNameFromHeaders(response.headers));
  }

  private getFileNameFromHeaders(headers: HttpHeaders) {
    const header = headers.get('content-disposition');
    if (!header) {
      return null;
    }

    const result = header.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  private getCSV(data: any[]) {
    // Generates CSV file from data
    // Downloads CSV file
    const replacer = (key, value) => (value === null ? '' : value);
    const header = new Set<string>();

    data.forEach((row: Record<string, unknown>) => {
      Object.keys(row).forEach((k) => {
        header.add(k);
      });
    });
    const headers = Array.from(header.values());
    const csv = data.map((row) =>
      headers.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
    );
    csv.unshift(headers.join(','));
    return csv.join('\r\n');
  }

  private downloadBlob(blob: Blob, filename: string) {
    const a = this.document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}

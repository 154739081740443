import { BaseComponent } from 'src/app/shared/components/base.component';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  Renderer2, OnDestroy,
} from '@angular/core';
import { ThemeService } from '../../services/utils/theme.service';
import { Axis } from '../../utils/time-cartesian-chart.util';
import { DOCUMENT } from '@angular/common';
import {
  Chart,
  ChartOptions,
  ChartDataset,
  LinearScale,
  TimeScale,
  LineController,
  PointElement,
  LineElement, BarController, BarElement, Tooltip
} from 'chart.js';

/**
 * For components that use Chart.js charts. Dark mode supported on tick font color and gridlines.
 * T should be the type of data incoming, from which we extrapolate chart data
 * U should be a string enum type of the dataset labels.
 */
@Directive()
export abstract class ChartBase<T, U> extends BaseComponent implements OnInit, OnDestroy {
  @HostListener('fullscreenchange', ['$event'])
  screenChange(event: Event): void {
    const isFullScreen = !!this.document.fullscreenElement;
    const componentContainer = event.target as HTMLElement;
    let chartContainer;

    // TODO: the next line produces a ts error, but the condition is met.
    //    Check again on future version of typescript (>4.8.4)
    // @ts-ignore
    for (const child of componentContainer.children as HTMLElement[]) {
      if (child.className === 'canvas-container') {
        chartContainer = child;
        break;
      }
    }

    this.renderer.setStyle(
      componentContainer,
      'height',
      isFullScreen ? '100%' : 'unset'
    );

    if (chartContainer) {
      this.renderer.setStyle(
        chartContainer,
        'height',
        isFullScreen ? '85%' : '450px'
      );
    }
  }

  @Output() fullScreenRequest: EventEmitter<ElementRef> =
    new EventEmitter<ElementRef>();

  public abstract chartOptions: ChartOptions;
  public datasets: WeakMap<ChartBase<T, U>, ChartDataset[]>;

  protected abstract readonly gtmLabel: string;
  protected abstract readonly gtmCategory: string;
  protected abstract readonly chartId: string;
  protected abstract readonly chartType: 'line' | 'bar';

  protected _chart: Chart;
  protected _hiddenDatasets: string[] = [];

  protected constructor(
    protected themeService: ThemeService,
    @Inject(DOCUMENT) protected document: Document,
    protected elRef: ElementRef,
    protected renderer: Renderer2
  ) {
    super();
    this.datasets = new WeakMap();
    const initData : ChartDataset[] = [];
    this.datasets.set(this, initData); // don't leave undefined
  }

  // If child also has an ngOnInit, this MUST be called via super.ngOnInit()
  ngOnInit() {
    Chart.register(LinearScale, TimeScale, BarController, BarElement, LineController, LineElement, PointElement, Tooltip);
    this.subscriptions.push(
      this.themeService.themeChanges$.subscribe((isDarkTheme) => {
        this.setChartOptionsTheme(isDarkTheme);
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._chart?.destroy();
  }

  public requestFullScreen(): void {
    this.fullScreenRequest.next(
      this.elRef.nativeElement.firstChild as ElementRef
    );
  }

  public abstract toggleDataset(dataset: ChartDataset): void;

  /**
   * Controls coloring of gridlines and all tick labels. Requires 'grid' and 'ticks' definitions
   * to exist for each scale.
   * @param isDarkTheme
   * @private
   */
  protected setChartOptionsTheme(isDarkTheme: boolean): void {
    const newOptions = Object.assign({}, this.chartOptions);
    isDarkTheme
      ? this.setDarkTheme(newOptions)
      : this.setLightTheme(newOptions);
    this.renderChart();
  }

  /**
   * Keeps selected legend items hidden after new data gets loaded
   * @private
   */
  protected hidePreviouslyHiddenData(): void {
    for (const label of this._hiddenDatasets) {
      const data  =   this.datasets.get(this);
      const tmp = data.find((ds) => ds.label === label);
      if (tmp) {
        tmp.data = [];
      }
    }
    this.renderChart();
  }

  /**
   * Sets up chart data to populate datasets with
   * @param payload
   * @protected
   */
  protected abstract convertPayloadToChartData(payload: T[]): void;

  /**
   * Set this.datasets as an array of calls to getDataset. MUST call this.renderChart() at the end of assignments
   * to see the results reflected in the chart.
   * @protected
   */
  protected abstract defineDatasets(): void;

  /**
   * Supports custom legend item selection / deselection. Should work on a switch
   * from the label, retuning a different Axis[] depending on that label.
   * @param label
   * @protected
   */
  protected abstract getChartDataFromLabel(label: U): Axis[];

  /**
   * Initializes the chart and renders the first time this is called. Thereafter, only makes updates based on updated
   * datasets and chart options
   * @param chartId - id of the canvas we're representing
   * @param chartType - line, bar, or others in the future if support is added
   * @private
   */
  protected renderChart(): void {
    if (this._chart) {
      this._chart.data.datasets = this.datasets.get(this);
      this._chart.options = this.chartOptions;
      this._chart.update();
      return;
    }

    const ctx =
      (document.getElementById(this.chartId) as HTMLCanvasElement)?.getContext('2d');
    if (ctx) {
      const datasets = this.datasets.get(this);
      this._chart = new Chart(ctx, {
        type: this.chartType,
        data: {
          datasets: datasets
        },
        options: this.chartOptions,
      });
      this._chart.render();
    }
  }

  private setLightTheme(newOptions): void {
    for (const scale in newOptions.scales) {
      const thisScale = newOptions.scales[scale];
      thisScale.ticks.color = 'gray';
      thisScale.grid.color = 'lightgray';
    }
    this.chartOptions = newOptions;
  }

  private setDarkTheme(newOptions): void {
    for (const scale in newOptions.scales) {
      const thisScale = newOptions.scales[scale];
      thisScale.ticks.color = 'white';
      thisScale.grid.color = '#444';
    }
    this.chartOptions = newOptions;
  }
}

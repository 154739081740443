import { BaseModel } from './base';
import { SiteFlagEnum } from './enums/siteFlagEnum';

export interface InterconnectionCurtailmentHistoryModel {
  meter_id: string;
  window_start_time: Date;
  window_end_time: Date;
  duration_minutes: number;
  curtailment_amount_mwh: number;
  curtailment_type: string;
}

export interface InterconnectionGenerationModel {
  dt_utc: string; // i.e. "2023-03-16T00:17:00+00:00"
  active_power: number; // i.e. 0
  curtailed_mw_minute: number; // i.e. 0
  forecast_clear_sky_mwh: number; // i.e. 0
  forecast_pvlib_mwh: number; // i.e. 0
  forecast_solcast_mwh: number; // i.e. 0
  mdm_mwh: number; // i.e. 0
  accu_mwh: number; // i.e. 0
  mw_estimate: number;
  calculated_high: number;
}

export interface InterconnectionNotification {
  uuid: string;
  jurisdiction: string;
  unique_id: number;
  customer_legal_name?: string;
  dt_utc: Date;
  notification_type: string;
  disconnect_notice: DisconnectNotice;
  no_generation: NoGenerationViolation;
  over_generation: InterconnectionGenerationViolation;
  recloser_status_change: InterconnectionAlertStatusChange;
}

export interface InterconnectionNotificationDetail {
  notification_type: string;
  details:
    | NoGenerationViolation
    | InterconnectionAlertStatusChange
    | InterconnectionGenerationViolation
    | DisconnectNotice;
}

export interface DisconnectNotice {
  contract_account_number: string;
  delinquency_action_description: string;
  number_of_days: string;
  updated_date: string;
  is_paid: boolean;
}

// TODO: For now, all these properties are optional
export interface InterconnectionAlertStatusChange {
  dt_utc?: string;
  previous_status?: string;
  current_status?: string;
}

export interface InterconnectionGenerationViolation {
  excess_mw: number;
  average_mw: number;
  most_recent_dt_utc: string;
}

export interface NoGenerationViolation {
  start: string;
  end: string;
  number_of_days: number;
}

export enum NotificationPreferenceTypeEnum {
  DISCONNECT_NOTICE = 'disconnect_notice',
  NO_GENERATION = 'no_generation',
  OVER_GENERATION = 'over_generation',
  RECLOSER_STATUS_CHANGE = 'recloser_status_change',
}

export namespace NotificationPreferenceType {
  export function parseFromSiteFlag(val: SiteFlagEnum): NotificationPreferenceTypeEnum {
    switch (val) {
      case SiteFlagEnum.DisconnectNotice:
        return NotificationPreferenceTypeEnum.DISCONNECT_NOTICE;
      case SiteFlagEnum.NoGeneration:
        return NotificationPreferenceTypeEnum.NO_GENERATION;
      case SiteFlagEnum.OverGeneration:
        return NotificationPreferenceTypeEnum.OVER_GENERATION;
      case SiteFlagEnum.RecloserStatusChange:
        return NotificationPreferenceTypeEnum.RECLOSER_STATUS_CHANGE;
      case SiteFlagEnum.RecloserStatusChangeNotificationDisplay:
        return NotificationPreferenceTypeEnum.RECLOSER_STATUS_CHANGE;
      default:
        return null;
    }
  }
}

export interface InterconnectionAmendment {
  amendment_desc: string;
  amendment_ordinal: string;
  contract_id: number;
  dt_utc: Date;
  unique_id: number;
}

export interface SolarSiteConfig {
  ac_rating: number;
  altitude: number;
  axis_azimuth: number;
  axis_tilt: number;
  inverter_name: string;
  latitude: number;
  longitude: number;
  module_name: string;
  modules_per_string: number;
  strings_per_inverter: number;
  surface_azimuth: number;
  surface_tilt: number;
}

export interface InterconnectionModel extends BaseModel {
  unique_id: number;
  unique_id_display?: string;
  user_role?: string;

  // maps to address on Site.ts
  street_address: string;
  city: string;
  facility_state: string;
  state_abbr: string;
  zip_code: string;
  county: string;

  allowable_curtailment_mwh: number;
  allowable_curtailment_percent: number;

  // this field will have a value set if it's a CPRE site, otherwise it will be null.
  cpre_participant_display_flag: string;
  customer_legal_name: string;
  distribution_or_transmission: string;
  energy_source: string;
  facility_installed_capacity_mw: number;
  operational_date: Date;
  operational_status: string;
  ppa_type: string;
  project_designation: string;

  // In format: yyyy-MM-dd (Actually I think we changed it to a proper Date format... operational_date :  "2022-03-01T00:00:00+00:00"
  op_center_classifier?: string;

  // Contract Information
  billing_account_number: string;
  contract_status: string;
  contract_date: Date;
  expiration_date: Date;
  contract_term: string;
  contract_type?: string;
  permission_to_operate_at_full_output?: Date;
  commercial_operational_date: Date;
  negotiated_type?: string;

  site_notifications?: InterconnectionNotification[];
  notification_details?: InterconnectionNotificationDetail[];

  amendments?: InterconnectionAmendment[];
  battery_type: string;
  // Won't necessarily exist if substation_exists === "True"
  substation_name?: string;
  ytd_curtailment_count?: number;
  ytd_curtailment_minutes?: number;
  ytd_curtailment_remaining?: number;
  ytd_curtailment_used?: number;
  ytd_curtailment_used_economic?: number;
  ytd_curtailment_used_reliability?: number;
  ytd_curtailment_used_uncertain?: number;
  ytd_generation_mwh?: number;
  ytd_solar_estimated_mwh?: number;
  ytd_solar_actual_mwh?: number;

  battery_capacity_mwh?: number;

  notification_flags?: string[];

  most_recent_curtailment_amount?: number;
  most_recent_curtailment_date?: string;
  most_recent_curtailment_duration?: number;
  most_recent_curtailment_reason?: string;
}
export interface SiteSpecificGenerationSummary {
  unique_id: number;
  today: 0;
  month: 0;
  year: 0;
}

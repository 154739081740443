import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { SiteSpecificComponent } from 'src/app/modules/sites/pages/site-specific/site-specific.component';
import { SiteSearchComponent } from './pages/site-search/site-search.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: SiteSearchComponent,
      },
      {
        matcher: (url) =>
          url[url.length - 1].path.match(/\d+/)
            ? {
                consumed: url,
                posParams: {
                  unique_id: new UrlSegment(url[url.length - 1].path.slice(0), {}),
                },
              }
            : null,
        component: SiteSpecificComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SitesRoutingModule {}

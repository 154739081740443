export class AccountCreationModel {
  zip_code: string;
  billing_account_number: string;
  customer_legal_name: string;
  access_level_requested: AccessLevelRequest;
}

export enum AccessLevelRequest {
  Owner = 'Owner',
  Operator = 'Operator',
  Admin = 'Admin',
}

  <form [formGroup]="form" [ngClass]="themeService.themeBackgroundClass">
    <mat-form-field appearance="fill">
      <mat-label>Yearly Summary</mat-label>
      <mat-select
        [formControl]="yearControl" class="yearly_summary"
        (selectionChange)="setDate($event)"
        [(value)]="label"
      >
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
      <app-de-icon
        matSuffix
        alt="Next"
        icon="calendar_simple"
      ></app-de-icon>
    </mat-form-field>

  </form>

/*
 * All Application roles are located in the Azure Portal.
 *
 * To view roles (dev): <br>https://portal.azure.com.mcas.ms/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/AppRoles/appId/6ae015ff-964a-49bb-8024-78320297c847/isMSAApp~/false
 * {@link } <br>
 *
 * To assign roles (dev): <br>
 * {@link https://portal.azure.com/#view/Microsoft_AAD_IAM/ManagedAppMenuBlade/~/Users/objectId/18b38c3c-6a70-4ac8-aebf-f358230b6d61/appId/6ae015ff-964a-49bb-8024-78320297c847/preferredSingleSignOnMode~/null/servicePrincipalType/Application/fromNav/}
 */
export enum AllowedAppRoleEnum {
  DCC = 'DCC',
  DEVELOPER = 'DEVELOPER',
  ECC = 'ECC',
  GENERALGROUPS = 'GENERALGROUPS',
  DemandResponder = 'DemandResponder',
  DemandResponderAdmin = 'DemandResponderAdmin',
  DemandResponderTest = 'DemandResponderTest',
  EXTERNAL_USER_ADMIN_READ_ONLY = 'EXTERNAL_USER_ADMIN_READ_ONLY',
  EXTERNAL_USER_ADMIN_READ_WRITE = 'EXTERNAL_USER_ADMIN_READ_WRITE',
  ECC_CURTAILMENT_FORM = 'ECC_CURTAILMENT_FORM',
  FSO_ADDITIONAL_INFO = 'FSO_ADDITIONAL_INFO',
  SOLAR_SITE_CONFIG_WRITE = 'SOLAR_SITE_CONFIG_WRITE',
  PLANNED_OUTAGES_FORM_READ_ONLY = 'PLANNED_OUTAGES_FORM_READ_ONLY',
  PLANNED_OUTAGES_FORM_READ_WRITE = 'PLANNED_OUTAGES_FORM_READ_WRITE',
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prefix'
})
export class PrefixPipe implements PipeTransform {
  transform(value: string | number, prefix: string | number): string | number {
    if (!value && value !== 0) {
      return '';
    }
    if (!prefix && prefix !== 0) {
      return value;
    }
    return `${prefix}${value}`;
  }
}

import { Component, Input } from '@angular/core';
import { Site } from 'src/app/shared/models/site';
import { InterconnectionCurtailmentHistoryModel } from 'src/app/shared/models/interconnection';
import { CurtailmentType } from 'src/app/shared/models/enums/curtailmentType';

@Component({
  selector: 'app-curtailments-summary',
  templateUrl: './curtailments-summary.component.html',
  styleUrls: ['./curtailments-summary.component.scss'],
})
export class CurtailmentsSummaryComponent {
  protected siteModel: Site;
  protected siteCurtailmentHistory: InterconnectionCurtailmentHistoryModel[];

  @Input() set site(siteModel: Site) {
    if (siteModel) {
      this.siteModel = siteModel;
    }
  }
  protected readonly CurtailmentType = CurtailmentType;
}

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AllowedAppRole, AuthorizationService } from '../../core/services/authorization.service';

@Directive({
  selector: '[appRequiredRoles]',
})
export class RequiredRolesDirective implements OnInit {
  @Input() appRequiredRoles: AllowedAppRole[];

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private auth: AuthorizationService
  ) {}

  ngOnInit(): void {
    // If user doesn't have access don't render template at all, so it can't be viewed in Dev Tools
    // otherwise add template to embedded view.
    this.viewContainer.clear();
    // if (this.auth.doesUserHaveAccess(this.appRequiredRoles)) {
    //   this.viewContainer.createEmbeddedView(this.templateRef);
    // } else {
    //   this.viewContainer.clear();
    // }
  }
}

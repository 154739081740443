import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InputColumn } from '../models';
import { MatSelectChange } from '@angular/material/select';
import { DateChangedEvent } from 'src/app/shared/components/month-selector/month-selector.component';
import {GtmService} from "src/app/shared/services/utils/gtm.service";

/**
 * Filter selection options for the table
 */
@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
})
export class TableFiltersComponent {
  @Input() set allColumns(columns: InputColumn[]) {
    this.columnOptions = columns;
    if (this.activeColumn) {
      this.activeColumn = this.columnOptions.find(
        (c) => c.key === this.activeColumn.key
      );
    }
  }
  @Input() tableExportTitle: string;
  @Input() selectedYear: string | number;
  @Output() columnFilterUpdate = new EventEmitter<void>();

  protected columnOptions: InputColumn[] = [];
  protected activeColumn: InputColumn;
  protected monthSelection: DateChangedEvent = null;

  protected readonly MAX_RANGE_VALUE = 10_000;

  protected changeColumnFilter(event: MatSelectChange) {
    this.activeColumn = this.columnOptions.find((c) => c.key === event.value);
  }

  protected applyStringFilter(searchValue: string) {
    this.activeColumn.filter = {
      searchValue,
    };
    this.applyFilterToQuery();
  }

  protected applyRangeFilter(min?: number, max?: number) {
    this.activeColumn.filter = {
      min: min ? min : 0,
      max: max ? max : this.MAX_RANGE_VALUE,
    };
    this.applyFilterToQuery();
  }

  protected applyDateFilter(dateChangedEvent: DateChangedEvent) {
    this.monthSelection = dateChangedEvent;
    const selectedDate = new Date(
      +dateChangedEvent.year,
      dateChangedEvent.monthIndex
    );
    this.activeColumn.filter = {
      date: selectedDate,
    };
    this.activeColumn.showSubSelection = false;
    this.applyFilterToQuery();
  }

  protected applyMultiOptionFilter(event: string[]): void {
    if (event.length === 0) {
      delete this.activeColumn.filter;
    } else {
      this.activeColumn.filter = {
        multiSearchValue: event,
      };
    }

    this.applyFilterToQuery();
  }

  private applyFilterToQuery() {
    // GTM DOC - 5.6.2.1, 5.6.3.2, 5.6.4.3
    GtmService.clickEvent({
        category: 'reporting',
        label: this.tableExportTitle,
        // table-column-filters-|-site-name
        action: `table-column-filters-|-${GtmService.format(this.activeColumn.name)}`,
      });
    this.columnFilterUpdate.emit();
  }
}

import { Component } from '@angular/core';

/**
 * Parent MUST have "position: relative" set for this whiteout-loader to work correctly
 */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {}

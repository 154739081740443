<div [ngClass]="{ 'dark-mode dark': themeService.isDarkThemeObservable | async }">
  <app-header
  class="app-header"
  siteTitle="DER Dashboard"
  [allHeaderControls]="allHeaderControls"
  [menuControls]="menuControls"
  [currentRouteHeaderButton]="currentRouteHeaderButton"
  [hamburgerMenuMobile]="true"
  (hamburgerMenuClick)="openDrawerMenu()"
  (headerButtonClick)="handleHeaderButtonPress($event)"
>
</app-header>


  <div class="main-container block bg-gray-lighter py-16 px-24">
    <router-outlet></router-outlet>
  </div>

  <app-loading *ngIf="sitesService.isAllSitesLoading$ | async"></app-loading>

  <app-feedback></app-feedback>
  <app-de-footer *ngIf="showFooter$ | async"></app-de-footer>
</div>

import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { env } from './env';

export interface EnvironmentConfig {
  production: boolean;
  envName: string;
  apiUrl: string;
  b2cAuthority: string;
  b2cClientId: string;
  b2cFlow: string;
  b2cScopes: string[];
  redirectUri: string;
  /*
   When interpolated during deployment step, feature flags
   MUST be interpolated from e.g. '@GTM@', replacing @GTM@
   with true or false directly (not removing the quotes).
   */
  featureFlags: {
    [p in FeatureFlag]: boolean | string;
  };
  userGuidePDFSource: string;
  dedicatedSupportTeam: string;
  customerInfoForm: string;
  logging: {
    flows: string[];
    ignoredFlows: string[];
    level: 'debug' | 'info' | 'warning' | 'error' | 'critical';
    highlights: boolean;
    msalLogLevel: LogLevel;
  };
}
export interface Environments {
  local: EnvironmentConfig;
  dev: EnvironmentConfig;
  qa: EnvironmentConfig;
  prod: EnvironmentConfig;
}
export type FeatureFlag =
  | 'googleTagManager'
  | 'showHelpCenter'
  | 'showCurtailmentInfo'
  | 'showPaymentInfo';

export const environments: Environments = {
  // This is for an example only. Please duplicate this for your own settings in
  //  a separate env.config.local.ts file per the local config section of the readme.
  local: {
    production: false,
    envName: 'local',
    // apiUrl: 'http://localhost:5001',
    apiUrl: 'https://der-dashboard-cust-api-dev.duke-energy.app',
    b2cAuthority: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/',
    b2cClientId: 'a7b7d0f3-1e34-468d-a871-a6b847b1addc',
    b2cFlow: 'B2C_1_DukeId_SignUp_SignIn_MFA',
    b2cScopes: [
      `https://DukeEnergyTestB2C.onmicrosoft.com/a7b7d0f3-1e34-468d-a871-a6b847b1addc/default`,
    ],
    redirectUri: '/',
    featureFlags: {
      googleTagManager: false,
      showHelpCenter: true,
      showCurtailmentInfo: true,
      showPaymentInfo: false,
    },
    userGuidePDFSource:
      'https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf',
    dedicatedSupportTeam: 'To Be Determined',
    customerInfoForm: 'To be determined',
    logging: {
      flows: [],
      ignoredFlows: [],
      level: 'debug',
      highlights: false,
      msalLogLevel: LogLevel.Error,
    },
  },
  dev: {
    production: false,
    envName: 'dev',
    apiUrl: 'https://der-dashboard-cust-api-dev.duke-energy.app',
    b2cAuthority: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/',
    b2cClientId: 'a7b7d0f3-1e34-468d-a871-a6b847b1addc',
    b2cFlow: 'B2C_1_DukeId_SignUp_SignIn_MFA',
    b2cScopes: [
      `https://DukeEnergyTestB2C.onmicrosoft.com/a7b7d0f3-1e34-468d-a871-a6b847b1addc/default`,
    ],
    redirectUri: '/',
    featureFlags: {
      googleTagManager: '@GTM@',
      showHelpCenter: '@HELP-CENTER@',
      showCurtailmentInfo: '@SHOW-CURTAILMENT-INFO@',
      showPaymentInfo: '@SHOW-PAYMENT-INFO@',
    },
    userGuidePDFSource: '@USER-GUIDE-PDF@',
    dedicatedSupportTeam: '@SUPPORT-TEAM@',
    customerInfoForm: '@CUSTOMER-INFO-FORM@',
    logging: {
      flows: [],
      ignoredFlows: [],
      level: 'debug',
      highlights: false,
      msalLogLevel: LogLevel.Verbose,
    },
  },
  qa: {
    production: false,
    envName: 'qa',
    apiUrl: 'https://der-dashboard-cust-api-qa.duke-energy.app',
    b2cAuthority: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/',
    b2cClientId: 'a7b7d0f3-1e34-468d-a871-a6b847b1addc',
    b2cFlow: 'B2C_1_DukeId_SignUp_SignIn_MFA',
    b2cScopes: [
      `https://DukeEnergyTestB2C.onmicrosoft.com/a7b7d0f3-1e34-468d-a871-a6b847b1addc/default`,
    ],
    redirectUri: '/',
    featureFlags: {
      googleTagManager: '@GTM@',
      showHelpCenter: '@HELP-CENTER@',
      showCurtailmentInfo: '@SHOW-CURTAILMENT-INFO@',
      showPaymentInfo: '@SHOW-PAYMENT-INFO@',
    },
    userGuidePDFSource: '@USER-GUIDE-PDF@',
    dedicatedSupportTeam: '@SUPPORT-TEAM@',
    customerInfoForm: '@CUSTOMER-INFO-FORM@',
    logging: {
      flows: [],
      ignoredFlows: [],
      level: 'info',
      highlights: false,
      msalLogLevel: LogLevel.Error,
    },
  },
  prod: {
    production: true,
    envName: 'prod',
    apiUrl: 'https://der-dashboard-cust-api.duke-energy.app',
    b2cAuthority: 'https://DukeEnergyB2C.b2clogin.com/DukeEnergyB2C.onmicrosoft.com/',
    b2cClientId: '43eaf860-0c8d-467e-b3e2-0a839f45e419',
    b2cFlow: 'B2C_1_DukeId_SignUp_SignIn',
    b2cScopes: [
      `https://DukeEnergyB2C.onmicrosoft.com/43eaf860-0c8d-467e-b3e2-0a839f45e419/default`,
    ],
    redirectUri: '/',
    featureFlags: {
      googleTagManager: '@GTM@',
      showHelpCenter: '@HELP-CENTER@',
      showCurtailmentInfo: '@SHOW-CURTAILMENT-INFO@',
      showPaymentInfo: '@SHOW-PAYMENT-INFO@',
    },
    userGuidePDFSource: '@USER-GUIDE-PDF@',
    dedicatedSupportTeam: '@SUPPORT-TEAM@',
    customerInfoForm: '@CUSTOMER-INFO-FORM@',
    logging: {
      flows: [],
      ignoredFlows: [],
      level: 'critical',
      highlights: false,
      msalLogLevel: LogLevel.Error,
    },
  },
};

export const msalPublicClientAppConfig = {
  auth: {
    clientId: environments[env.env].b2cClientId,
    authority: `${environments[env.env].b2cAuthority}${environments[env.env].b2cFlow}`,
    knownAuthorities: [`${environments[env.env].b2cAuthority}${environments[env.env].b2cFlow}`],
    redirectUri: environments[env.env].redirectUri,
    postLogoutRedirectUri: '/',
    loginFailedRoute: '/',
    navigateToLoginRequestUrl: false,
    // TODO: Not sure if we need this, or if it could be set to false instead
    allowRedirectInIframe: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie:
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1,
  },
};

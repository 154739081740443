export async function toggleFullPage(elRef: any, document: Document) {
  if (document.fullscreenElement) {
    const exitFullscreen = () => {
      return document[
        ['exitFullscreen', 'mozExitFullscreen', 'msExitFullscreen', 'webkitExitFullscreen'].find(
          (prop) => typeof document[prop] === 'function'
        )
      ]?.();
    };
    exitFullscreen();
  } else {
    const enterFullscreen = (elem, options) => {
      return elem[
        [
          'requestFullscreen',
          'mozRequestFullScreen',
          'msRequestFullscreen',
          'webkitRequestFullscreen',
        ].find((prop) => typeof elem[prop] === 'function')
      ]?.(options);
    };
    if (isSafari()) {
      enterFullscreen(elRef, null);
    } else {
      await enterFullscreen(elRef, null);
    }
  }
}

export function isSafari() {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1
  );
}

export type ChartColors =
  | 'blue'
  | 'cyan'
  | 'fuchsia'
  | 'gray'
  | 'green'
  | 'green_lighter'
  | 'orange'
  | 'purple'
  | 'purplish_blue'
  | 'red'
  | 'teal'
  | 'teal_lighter'
  | 'yellow'
  | 'seafoam_green'
  | 'pink'
  | 'apple_green'
  | 'navy_blue'
  | 'light_grey';

const colors = {
  blue: 'rgb(0, 120, 158)',
  cyan: 'rgb(68, 215, 182)',
  fuchsia: 'rgb(255, 0, 255)',
  gray: 'rgb(105, 105, 105)',
  green: 'rgb(0, 133, 63)',
  green_lighter: 'rgb(0, 180, 110)',
  orange: 'rgb(247, 142, 30)',
  purple: 'rgb(153, 50, 204)',
  purplish_blue: 'rgb(98, 54, 255)',
  red: 'rgb(158, 27, 50)',
  teal: 'rgb(38, 188, 215)',
  teal_lighter: 'rgb(84, 234, 255)',
  yellow: 'rgb(255, 210, 0)',
  seafoam_green: 'rgb(143,207,231)',
  pink: 'rgb(253,111,217)',
  apple_green: 'rgba(84, 185, 72, 1)',
  navy_blue: 'rgba(0, 89, 132, 1)',
  light_grey: 'rgba(105,105,105, 1)',
};

export const getChartColor = (label: ChartColors): string => {
  return colors[label];
};

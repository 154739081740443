<div [class.dark]="themeService.isDarkTheme">
  <div class="p-16 dark:bg-dm-bg">
    <h2 class="text-title text-teal-darker dark:text-white !mb-[10px]"> Amendments
      <button mat-icon-button [mat-dialog-close]="false" class="float-right dark:text-dm-accent">
        <mat-icon>close</mat-icon>
      </button>
    </h2>

    <ng-container *ngIf="dataSource?.length > 0">
      <div class="contract-amendments">
        <table class="w-full text-gray-dark" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="ordinal">
            <th
              class="text-teal-darker text-center border-r-gray dark:border-r-dm-border border-r-1 border-solid whitespace-nowrap left-auto"
              mat-header-cell
              *matHeaderCellDef
            >
              <div class="w-1/3">Amendment</div>
            </th>

            <td
              class="align-middle text-center border-r-gray dark:border-r-dm-border border-r-1 border-solid whitespace-nowrap left-auto"
              mat-cell
              *matCellDef="let element"
            >
              <div>{{ element.ordinal || '--' }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th class="align-middle text-center border-r-gray dark:border-r-dm-border border-r-1 left-auto" mat-header-cell *matHeaderCellDef>
            <div>Execution Date</div>
            </th>

            <td class="align-middle text-center border-r-gray dark:border-r-dm-border border-r-1" mat-cell *matCellDef="let element">
              <div>
                {{ element.date | date : "shortDate" }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="desc">
            <th
              class="text-teal-darker text-left whitespace-normal left-auto"
              mat-header-cell
              *matHeaderCellDef
            >
              <div>Description</div>
            </th>

            <td
              class="align-middle text-left whitespace-normal left-auto"
              mat-cell
              *matCellDef="let element"
            >
              <div>{{ element.desc || '--' }}</div>
            </td>
          </ng-container>

          <tr class="-mt-2 w-full" mat-header-row *matHeaderRowDef="displayedColumns"></tr>

          <tr class="w-full" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div class="amendments-legend dark:text-dm-body-text mt-[5px]"> <p class="amendments-asterisk">*</p> -- indicates data missing from DET source system </div>
      </div>
    </ng-container>
  </div>
</div>

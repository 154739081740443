import { Component } from '@angular/core';
import { DePersonalInfoComponent } from '../de-personal-info/de-personal-info.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-de-footer',
  templateUrl: './de-footer.component.html',
  styleUrls: ['./de-footer.component.scss'],
})
export class DeFooterComponent {
  constructor(private dialog: MatDialog) {}
  openDialog() {
    this.dialog.open(DePersonalInfoComponent, { width: '575px' });
  }
}

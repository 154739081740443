import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  template: `
    <p>
      You are signed out. Please navigate to a page to initiate sign-in. Thank
      you!
    </p>
  `,
})
export class LandingComponent {}

<div class="violations">
  <div class="flex flex-col">
    <div class="text-xl">Generation Violations</div>
    <div *ngIf="siteModel?.contract?.capacity" class="flex flex-row items-baseline">
      <span class="mr-6">Capacity: </span>
      <span>{{siteModel.contract.capacity}} MW<sub>AC</sub></span>
    </div>
  </div>
  <div>
    <app-month-selector [defaultDateIso]="currentDate"
                        (monthSelectionChanged)="newMonthSelection($event)">
    </app-month-selector>
  </div>

  <ng-container *ngIf="dataSource.data?.length > 0; else noData">
    <div class="generation-violations">
      <table mat-table matSort [dataSource]="dataSource" #sortingTable="matSort">
        <ng-container matColumnDef="date" sticky="true">
          <th class="whitespace-nowrap"
              mat-header-cell mat-sort-header *matHeaderCellDef
          >
            Violation Date
          </th>
          <td class="whitespace-nowrap"
              mat-cell *matCellDef="let element"
          >
            {{ element.date | date: 'short' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="averageMW">
          <th class="whitespace-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>
            Hourly Metered MW
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.averageMW | number: '1.0-3' }} MW<sub>AC</sub>
          </td>
        </ng-container>

        <ng-container matColumnDef="excessMW">
          <th class="whitespace-nowrap" mat-header-cell mat-sort-header *matHeaderCellDef>
            Amount Generated in Excess of PPA Capacity
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.excessMW | number: '1.0-3' }} MW<sub>AC</sub>
          </td>
        </ng-container>

        <tr class=" -mt-2" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <div class="generation-violations-paginator">
        <mat-paginator [pageSizeOptions]="paginatorSizeOptions"></mat-paginator>
        <span class="generation-violations-paginator-spacer"></span>
        <div class="generation-violations-paginator-buttons">
          <app-icon-button
            name="Export Table"
            iconName="table/export"
            buttonType="text"
            (buttonClicked)="downloadCsv()"
          ></app-icon-button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noData>
    <app-no-data [title]="'No Generation Violations'"
                 [summary]="'Generation Violation Data will appear here'">
    </app-no-data>
  </ng-template>
</div>

<div class="header">
  <div class="header-title">
    Solar Generation
    <mat-icon
      class="text-blue dark:text-dm-blue align-middle mr-10 mb-4"
      svgIcon="information-solid"
      [matTooltip]="streamingData"
    >ligature</mat-icon
    >
    <span class="timestamp dark:text-dm-body-text"
    >Last Updated: <span class="text-blue dark:text-dm-accent">{{ lastGenerationTime }}</span></span
    >
  </div>
  <div class="border-[1px] py-10 flex justify-center px-10 mt-10" *ngIf="summary && showSummary">
    <div>
      Live:
      <span class="font-bold text-teal-dark pr-20"
      >{{
          summary?.live < 1
            ? (summary?.live | number : "1.2-2")
            : (summary?.live | number : "1.0-0")
        }}
        MW</span
      >
    </div>
    <div [ngClass]="'contents'">
      <div *ngIf="summary?.month">
        Month to Date:
        <span class="font-bold text-teal-dark pr-20"
        >{{ summary?.month | number : "1.0-0" }} MWh</span
        >
      </div>
      <div *ngIf="summary?.year">
        Year to Date:
        <span class="font-bold text-teal-dark pr-20"
        >{{ summary?.year | number : "1.0-0" }} MWh</span
        >
      </div>
    </div>
    <div class="flex flex-row pr-20" *ngIf="summary?.recloser && summary?.recloser !== 'N/A'">
      Recloser Status:
      <div class="flex ml-10">
        <div
          [matTooltip]="summary?.recloser"
          [ngClass]="summary?.recloser.toLowerCase() === 'closed' ? 'bg-red' : 'bg-green'"
          class="w-18 h-18 rounded-full"
        ></div>
      </div>
    </div>
    <div class="flex flex-row pr-20" *ngIf="summary?.comm && summary?.comm !== 'N/A'">
      Comm Status:
      <div class="flex ml-10">
        <div
          [matTooltip]="summary?.comm"
          [ngClass]="summary?.comm.toLowerCase() === 'online' ? 'bg-green' : 'bg-red'"
          class="w-18 h-18 rounded-full"
        ></div>
      </div>
    </div>
  </div>
</div>
<div class="datepicker">
  <app-datepicker [minDate]="minDate" [maxDate]="maxDate" (dateChange)="dateChanged($event)"></app-datepicker>
</div>

<div class="chart-container" [ngClass]="themeService.themeBackgroundClass">
  <!--  Need to enclose within a div to keep the height width from overflowing-->
  <div class="canvas-container">
    <canvas id="energyProductionChart"></canvas>
  </div>

  <div class="legend-container flex-container">
    <div class="left">
      <ng-container *ngFor="let d of datasets.get(this)">
        <div
          class="legend-item"
          *ngIf="!d.label.includes('Forecast')"
          [ngClass]="{ toggled: d.data.length === 0 }"
          (click)="toggleDataset(d)"
        >
          <span class="box" [style.backgroundColor]="d.borderColor"></span>
          <span>{{ d.label }}</span>
        </div>
      </ng-container>
    </div>
    <div class="right">
      <ng-container *ngFor="let d of datasets.get(this)">
        <div
          class="legend-item"
          *ngIf="d.label.includes('Forecast')"
          [ngClass]="{ toggled: d.data.length === 0 }"
          (click)="toggleDataset(d)"
        >
          <span class="box" [style.backgroundColor]="d.borderColor"></span>
          <span>{{ d.label }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <app-loading *ngIf="sitesService.isSiteGenerationLoading$ | async"></app-loading>
</div>

import { Component, Input, OnInit } from '@angular/core';

// Just didn't look right at 100% and other ranges without it
const WIDTH_OFFSET_MULTIPLIER = 0.15;

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.scss'],
})
export class BatteryComponent implements OnInit {
  @Input() set progressPercent(percent: number) {
    this.progressPercentPadding = percent * WIDTH_OFFSET_MULTIPLIER;
    this._progressPercent = percent;
  }
  get progressPercent(): number {
    return this._progressPercent;
  }

  public progressPercentPadding: number;
  public progressColor: string;

  private _progressPercent: number;

  ngOnInit() {
    this.setProgressColor();
  }

  private setProgressColor() {
    if (this.progressPercent < 50) {
      this.progressColor = 'progress-bar-red';
    } else if (this.progressPercent < 80) {
      this.progressColor = 'progress-bar-yellow';
    } else {
      this.progressColor = 'progress-bar-green';
    }
  }
}

<div *ngIf="siteModel" class="flex flex-col gap-10">
  <div class="flex flex-row items-baseline">
    <div class="text-xl mr-16">
      {{ siteModel.name
      }}<span class="ml-8" *ngIf="siteModel.jurisdiction"
        >({{ siteModel.jurisdiction }} - {{ siteModel.address.state }})</span
      >
    </div>
    <div>
      {{ siteModel.address.street }}, {{ siteModel.address.city }} , {{ siteModel.address.state }}
      {{ siteModel.address.zipCode }}
    </div>
  </div>

  <div class="w-3/4 inline">
    <span *ngFor="let item of siteModel.flags">
      <app-toggle-button
        *ngIf="getFlagIconUrl(item)"
        [label]="item"
        [imgClass]="'site-flag--toggled'"
        [imgUrl]="getFlagIconUrl(item)"
        [imgAlt]="item"
        [active]="true"
        [clickable]="false"
      >
      </app-toggle-button
    ></span>
  </div>

  <div class="flex flex-row flex-wrap gap-12">
    <div class="flex flex-row items-baseline">
      <div class="font-bold mr-6">Unique ID:</div>
      <div>{{ siteModel.uniqueIdDisplay }}</div>
    </div>
    <div class="flex flex-row items-baseline">
      <div class="font-bold mr-6">Installed Capacity:</div>
      <div>{{ siteModel.capacity }} MW<sub>AC</sub></div>
    </div>
    <div *ngIf="siteModel.substationName" class="flex flex-row items-baseline">
      <div class="font-bold mr-6">Substation:</div>
      <div>
        {{ siteModel.substationName }}
      </div>
    </div>
    <div class="flex flex-row items-baseline">
      <div class="font-bold mr-6">Contract Status:</div>
      <div>
        {{ siteModel.contract.contractStatus || "Unknown" }}
      </div>
    </div>
    <div class="flex flex-row items-baseline">
      <div class="font-bold mr-6">Operational Date:</div>
      <div>
        {{ siteModel.operationalDate ? (siteModel.operationalDate | date : "shortDate") : "Unknown" }}
      </div>
    </div>
    <div class="flex flex-row items-baseline">
      <div class="font-bold mr-6">PTO at Full Output:</div>
      <div>
        {{
          siteModel.contract.ptoAtFullOutputDate
            ? (siteModel.contract.ptoAtFullOutputDate | date : "shortDate")
            : "Unknown"
        }}
      </div>
    </div>
    <div class="flex flex-row items-baseline">
      <div class="font-bold mr-6">Grid Location:</div>
      <div>{{ siteModel.connectionType }}</div>
    </div>
    <div class="flex flex-row items-baseline">
      <div class="font-bold mr-6">Commercial Operational Date:</div>
      <div>
        {{
          siteModel.commercialOperationalDate ? (siteModel.commercialOperationalDate | date : "shortDate") : "Unknown"
        }}
      </div>
    </div>
  </div>
</div>

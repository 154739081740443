<button
  class="border-1 border-gray-dark p-4 text-sm m-4 rounded-md"
  (click)="updateToggle()"
  [ngClass]="[active ? 'toggled' : 'untoggled', optionalClasses, clickable ? 'cursor-pointer hover:shadow' : 'cursor-default']">
  <img [src]="imgUrl"
    [ngClass]="imgClass"
    class="inline w-18 h-18 mr-8 mb-2"
    [alt]="imgAlt" />
  <div class="inline whitespace-nowrap mr-8">{{label}}</div>
</button>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-de-icon',
  templateUrl: './de-icon.component.html',
  styleUrls: ['./de-icon.component.scss'],
})
export class DeIconComponent {
  @Input() type: string;
  @Input() alt: string;

  @Input() set icon(source: string) {
    const fileType = this.type || DeIconComponent.DEFAULT_TYPE;
    this.imgSource = `${DeIconComponent.ASSETS_PATH}/${source}${fileType}`;
    this.imgName = this.alt || source;
  }

  static readonly ASSETS_PATH = 'assets/icons';
  static readonly DEFAULT_TYPE = '.svg';
  imgSource: string;
  imgName: string;
}

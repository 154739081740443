import { Component, Inject } from '@angular/core';
import { Amendment } from 'src/app/shared/models/sites/amendment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';

@Component({
  selector: 'app-amendments',
  templateUrl: './amendments.component.html',
  styleUrls: ['./amendments.component.scss'],
})
export class AmendmentsComponent {
  public displayedColumns: string[] = ['ordinal', 'date', 'desc'];
  public dataSource = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Amendment[],
    private dialogRef: MatDialogRef<AmendmentsComponent>,
    public themeService: ThemeService
  ) {
    this.dataSource = data;
  }

  closeAmendments() {
    this.dialogRef.close();
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SimpleFilterModel } from 'src/app/shared/components/display-table/simpleFilterModel';
import { Site } from 'src/app/shared/models/site';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';
import { compareByProperties } from 'src/app/shared/utils/object.util';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { SitesService } from 'src/app/shared/services/api/sites.service';
import { BaseComponent } from 'src/app/shared/components/base.component';

@Component({
  selector: 'app-site-search',
  templateUrl: './site-search.component.html',
  styleUrls: ['./site-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  searchText = '';
  private _sites$ = new BehaviorSubject<Site[]>([]);
  private _allSites: Site[];

  private _gtmServiceEventSent = false;

  constructor(
    public themeService: ThemeService,
    public sitesService: SitesService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadSites();
  }

  public getSites = (): Observable<Site[]> => {
    return this._sites$.asObservable();
  };

  public containsSearchText(propToSearch: string): boolean {
    return propToSearch?.toLowerCase().includes(this.searchText);
  }

  public filtersInitialized(filter: SimpleFilterModel) {
    this.searchText = filter.search.toLocaleLowerCase();
    if (this.searchText && !this._gtmServiceEventSent) {
      this._gtmServiceEventSent = true;
      // GTM DOC - 5.4.3.1, 5.8.2.1
      GtmService.clickEvent({
        category: 'site',
        action: 'search',
        label: 'search-site-name-address',
      });
    }
  }

  public navigateToSite(site: Site): void {
    // GTM DOC - 5.4.6.1
    GtmService.clickEvent({
      category: 'sites',
      action: 'site-clickthrough',
      // Ex: site-|-13263
      label: `site-|-${site.unique_id}`,
    });
    void this.router.navigateByUrl(`/site-information/sites/${site.unique_id}`);
  }

  private loadSites() {
    this.subscriptions.push(
      this.sitesService.allInterconnections$.subscribe(() => {
        const externalSites = this.sitesService.allInterconnections$.value;
        this._allSites = externalSites
          .sort((a, b) => compareByProperties(a, b, 'customer_legal_name', 'substation_name'))
          .map((x) => Site.fromInterconnection(x));
        this._sites$.next(this._allSites);
      })
    );
  }
}

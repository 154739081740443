export class Power {
  chargedAmount: number;
  dischargedAmount: number;
  real: number;

  constructor(meterData: any) {
    this.chargedAmount = meterData.ac_charged_wh;
    this.dischargedAmount = meterData.ac_discharged_wh;
    this.real = meterData.real_power;
  }
}

import {
  InterconnectionModel,
  NotificationPreferenceTypeEnum,
} from 'src/app/shared/models/interconnection';
import { JurisdictionShortCode } from 'src/app/shared/models/jurisdiction';
import { Address } from 'src/app/shared/models/address';
import { LoggingService } from '../services/logging.service';

import { SiteNotification } from './sites/siteNotification';
import { SiteContract } from './sites/siteContract';
import { SiteNotificationDetail } from './sites/siteNotificationDetail';
import { ConnectionType } from 'src/app/shared/models/enums/connectionType';
import { EnergyType } from 'src/app/shared/models/enums/energyType';
import { SiteFlag, SiteFlagEnum } from 'src/app/shared/models/enums/siteFlagEnum';
import { SiteClassifier } from './enums/siteClassifier';

const _logger = new LoggingService('Site', 'accountManagementSite');

export interface YTDCurtailment {
  count?: number;
  minutes?: number;
  remaining?: number;
  used?: number;
  usedEconomic?: number;
  usedReliability?: number;
  usedUncertain?: number;
}

export interface MostRecentCurtailment {
  amount?: number;
  date?: string;
  duration?: number;
  reason?: string;
}

export class Site {
  unique_id?: number;
  uniqueId?: number;
  uniqueIdDisplay?: string;
  address?: Address;
  connectionType?: ConnectionType;
  distribution_or_transmission?: ConnectionType;
  energyType?: EnergyType;
  batteryType?: EnergyType;
  flags?: SiteFlagEnum[];
  name: string;
  substationName?: string;
  jurisdiction: JurisdictionShortCode;
  ownedBy?: string;
  opCenterClassifier?: SiteClassifier;

  // TODO: refactor to siteContract
  operationalDate?: Date;
  commercialOperationalDate?: Date;

  // TODO: Validate these
  capacity?: number;
  ownershipStatus?: string;

  contract: SiteContract;
  mostRecentCurtailment?: MostRecentCurtailment;
  ytdCurtailment?: YTDCurtailment;

  notifications: SiteNotification[];
  notificationDetails: SiteNotificationDetail[];

  static fromInterconnection(interconnection: InterconnectionModel): Site {
    const ret: Site = {
      // TODO: Remove direct mapping
      ...interconnection,
      address: {
        street: interconnection.street_address,
        city: interconnection.city,
        state: interconnection.facility_state,
        zipCode: interconnection.zip_code,
        county: interconnection.county,
      },
      energyType:
        interconnection.energy_source === 'Solar'
          ? EnergyType.Solar
          : interconnection.energy_source == 'Microgrid'
          ? EnergyType.Microgrid
          : EnergyType.Battery,
      batteryType:
          interconnection.battery_type === 'Battery'
            ? EnergyType.Battery
            : interconnection.battery_type == 'Microgrid'
            ? EnergyType.Microgrid
            : EnergyType.Solar,
      commercialOperationalDate: interconnection.commercial_operational_date,
      connectionType: interconnection.distribution_or_transmission as
        | ConnectionType.Distribution
        | ConnectionType.Transmission,
      distribution_or_transmission: interconnection.distribution_or_transmission as
        | ConnectionType.Distribution
        | ConnectionType.Transmission,
      capacity: interconnection.facility_installed_capacity_mw,
      contract: new SiteContract(interconnection),
      flags: interconnection.notification_flags
        ? this.parseSiteFlags(interconnection.notification_flags)
        : null,
      mostRecentCurtailment: {
        amount: interconnection?.most_recent_curtailment_amount,
        date: interconnection?.most_recent_curtailment_date,
        duration: interconnection?.most_recent_curtailment_duration,
        reason: interconnection?.most_recent_curtailment_reason,
      },
      name: interconnection.customer_legal_name,
      notificationDetails: interconnection.notification_details?.map(
        (n) => new SiteNotificationDetail(n)
      ),
      notifications: interconnection.site_notifications?.map((n) => new SiteNotification(n)),
      opCenterClassifier: interconnection.op_center_classifier as
      | SiteClassifier.DGOC
      | SiteClassifier.RROC,
      operationalDate: interconnection.operational_date,
      ownedBy: interconnection.project_designation == 'Utility Owned' ? 'Duke Energy' : 'External',
      ownershipStatus: interconnection.project_designation,
      substationName: interconnection.substation_name,
      uniqueId: interconnection.unique_id,
      ytdCurtailment: interconnection.ytd_curtailment_used
        ? {
            count: interconnection.ytd_curtailment_count,
            minutes: interconnection.ytd_curtailment_minutes,
            remaining: interconnection.ytd_curtailment_remaining,
            used: interconnection.ytd_curtailment_used,
            usedEconomic: interconnection.ytd_curtailment_used_economic,
            usedReliability: interconnection.ytd_curtailment_used_reliability,
            usedUncertain: interconnection.ytd_curtailment_used_uncertain,
          }
        : null,
    };
    if (ret.unique_id) {
      ret.uniqueIdDisplay = `${ret.unique_id}`.padStart(6, '0');
    }
    return ret;
  }

  static parseSiteFlags(flags: string[]): SiteFlagEnum[] {
    const mappedFlags: SiteFlagEnum[] = flags.map((flag: string) => {
      const siteFlag = SiteFlag.parseFromNotificationPreferenceType(
        flag as NotificationPreferenceTypeEnum
      );
      if (siteFlag === null) {
        _logger.warn('Unable to map site flag', flag);
      }
      return siteFlag;
    });
    return mappedFlags;
  }
}

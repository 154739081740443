import {
  DisconnectNotice,
  InterconnectionAlertStatusChange,
  InterconnectionGenerationViolation,
  InterconnectionNotificationDetail,
  NoGenerationViolation,
  NotificationPreferenceTypeEnum,
} from 'src/app/shared/models/interconnection';
import { SiteFlag } from 'src/app/shared/models/enums/siteFlagEnum';

export class SiteNotificationDetail {
  notificationType: string;
  details:
    | DisconnectNotice
    | InterconnectionAlertStatusChange
    | InterconnectionGenerationViolation
    | NoGenerationViolation;

  constructor(data: InterconnectionNotificationDetail) {
    this.notificationType = SiteFlag.parseFromNotificationPreferenceType(
      data.notification_type as NotificationPreferenceTypeEnum
    );
    this.details = data.details;
  }
}

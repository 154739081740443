import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemeService } from '../../services/utils/theme.service';
import { addDays } from 'date-fns';
import {MatSelectChange} from "@angular/material/select";

export interface DateChangedEvent {
  month: string;
  monthIndex: number;
  year: string;
}

@Component({
  selector: 'app-yearly-summary-selector',
  templateUrl: './yearly-summary-selector.component.html',
  styleUrls: ['./yearly-summary-selector.component.scss'],
})
export class YearlySummarySelectorComponent implements OnInit {
  @Output() yearSelectionChanged = new EventEmitter<string>();
  @Input() defaultDateIso: string = null;

  public showSelector = false;
  public label: string;
  public years = [];
  public yearControl = new FormControl<string>(null);
  public form = new FormGroup({
    year: this.yearControl,
  });

  private _currentYear: string;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {
    // Initialize options
    const today = new Date();
    const endYear = today.getFullYear();
    const startYear = 2023; // Start from an increasingly distant origin
    for (let i = startYear; i <= endYear; i++) {
      this.years.push(i.toString());
    }

    // Initialize selections
    if (this.defaultDateIso) {
      const defaultDate = new Date(this.defaultDateIso);
      this._currentYear = defaultDate.getFullYear().toString();
    } else {
      this._currentYear = today.getFullYear().toString();
    }
    this.yearControl.setValue(this._currentYear);

    this.setLabel();
  }

  public apply() {
    this.toggleSelector();

    this._currentYear = this.yearControl.value;
    this.setLabel();
  }

  public toggleSelector() {
    this.showSelector = !this.showSelector;
  }

  public setDate(data: MatSelectChange) {
    // TODO: Handle properly by casting to client's timezone from UTC first
    // Date at this point is UTC. Fixing bug where January trying to use MONTHS[12]

    const lastSelectedValue = data.value as string;
    const dt = addDays(new Date(lastSelectedValue), 1);
    this._currentYear = dt.getFullYear().toString();
    this.yearControl.setValue(this._currentYear);
    this.setLabel();
    this.yearSelectionChanged.next(this._currentYear);
  }

  private setLabel() {
    this.label = `${this._currentYear}`;
  }
}

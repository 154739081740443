<div class="section-header flex justify-between">
  <span class="text-title">Battery Storage</span>
  <span *ngIf="batteryData?.length > 0" class="timestamp">
      Last Updated:
      <span class="text-blue">{{ batteryData[batteryData.length - 1].date | date : "M/d/yy h:mm:ss a" }}</span>
    </span>
</div>

<div class="datepicker">
  <app-datepicker [minDate]="minDate" [maxDate]="maxDate" (dateChange)="dateChanged($event)"></app-datepicker>
</div>

<div class="chart-container relative">
  <!--  Need to enclose within a div to keep the height width from overflowing-->
  <div class="canvas-container">
    <canvas id="batteryStorageChart"></canvas>
  </div>

  <div class="legend-container flex-container flex flex-wrap gap-y-8">
    <div class="left">
      <span class="legend-title">Left Axis: </span>
      <ng-container *ngFor="let d of datasets">
        <div
          class="legend-item"
          *ngIf="d.yAxisID === 'y'"
          [ngClass]="{ toggled: d.data.length === 0 }"
          (click)="toggleDataSet(d)"
        >
          <span class="box" [style.backgroundColor]="d.borderColor"></span>
          <span>{{ d.label }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <app-loading *ngIf="sitesService.isSiteBatteryLoading$ | async"></app-loading>
</div>

// Angular
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppConfig } from './core/config/app.config';
import { AppRoutingModule } from './app.routing.module';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import { environments, msalPublicClientAppConfig } from 'src/config/env.config';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { env } from 'src/config/env';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { EdsSiteHeaderComponent } from '@electronds/ng-components-duke';
import { UrlService } from './services/url.service';
import { SitesModule } from './modules/sites/sites.module';
import { AppPreloadStrategy } from './appPreLoadStrategy';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalPublicClientAppConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [
        `${environments[env.env].apiUrl}/`,
        [...environments[env.env].b2cScopes],
      ],
    ]),
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environments[env.env].b2cScopes],
    },
  };
}

export function loadConfigurations(appConfig: AppConfig) {
  return () => {
    appConfig.load();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    MsalModule,
    SharedModule,
    EdsSiteHeaderComponent,
    CoreModule,
    SitesModule,
    // Last position so child routes from feature modules are handled first
  ],
  providers: [
    provideAnimations(),
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurations,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfig],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfig],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfig],
    },
    MsalBroadcastService,
    MsalGuard,
    MsalService,
    AppPreloadStrategy,
    UrlService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent {
  @Input() label: string;
  @Input() imgUrl: string;
  @Input() imgClass: string;
  @Input() active = false;
  @Input() imgAlt: string;
  @Input() optionalClasses = '';
  @Input() clickable = true;
  @Output() toggleChange = new EventEmitter<boolean>();

  updateToggle() {
    if (!this.clickable) {
      return;
    }
    this.active = !this.active;
    this.toggleChange.emit(this.active);
  }
}

import { Injectable } from '@angular/core';
import { FeedbackModel } from 'src/app/shared/models/feedback';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService extends ApiService {
  postFeedback(feedback: FeedbackModel): Observable<FeedbackModel[]> {
    return this.http
      .post<FeedbackModel[]>(`${this.apiUrl}/send-feedback/`, feedback)
      .pipe(catchError((err) => this.handleError('Unable to submit feedback', err)));
  }
}

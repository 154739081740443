import { TemplateRef } from '@angular/core';

export enum CellDataType {
  Text,
  Checkbox,
  Date,
}

export enum CellDataAlignment {
  Left,
  Center,
  Right,
}

export class TableCellDef {
  constructor(
    public name: string,
    public label?: string,
    public dataAccessor?: (dataObject: any, propertyName: string) => any,
    public type: CellDataType = CellDataType.Text,
    public alignment: CellDataAlignment = CellDataAlignment.Left,
    public dateFormat: string = 'l',
    public cellTemplate: TemplateRef<any> = null
  ) {}
}

<app-de-modal
  [open]="modalOpen"
  (close)="close(null)"
  [showMask]="true"
  [showHeader]="false"
  [showClose]="true"
  [showCloseOnly]="true"
>
  <div position="centered" class="table-views-modal">
    <div class="table-views-modal__header">Select Table View</div>
    <form [formGroup]="tableViewForm">
      <div class="table-views-modal__search">
        <mat-form-field>
          <mat-icon svgIcon="table/search" matPrefix></mat-icon>
          <input matInput placeholder="Search" formControlName="searchText" />
        </mat-form-field>
      </div>

      <div *ngIf="tableViews?.length > 0" class="table-views-modal__list">
        <mat-radio-group class="flex flex-col" formControlName="tableView">
          <mat-radio-button *ngFor="let item of tableViews | search: 'name':tableViewForm.controls.searchText.value; let i=index;"
                            [checked]="item.name === tableViewForm.value.tableView?.name"
                            [value]="item">
            {{ item.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div>
        Selected: {{tableViewForm.value.tableView?.name || ''}}
      </div>

      <div class="table-views-modal__buttons">
        <de-button type="secondary" (buttonClick)="close(null)"
          >CANCEL</de-button
        >
        <de-button
          type="secondary"
          (buttonClick)="deleteTableView()"
          [disabled]="!tableViewForm.valid"
          >DELETE TABLE</de-button
        >
        <de-button
          type="primary"
          (buttonClick)="loadTableView()"
          [disabled]="!tableViewForm.valid"
          >LOAD TABLE</de-button
        >
      </div>
    </form>
  </div>
</app-de-modal>

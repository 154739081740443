<div class="h-full min-w-[80vw]">
  <div class="mb-24">
    <span class="text-title"> Submitted Outages </span>
  </div>
  <div class="flex gap-20 items-center">
    <div class="flex cursor-pointer items-center">
      <img class="inline mr-6 mb-2" src="assets/icons/plus.svg" alt="" />
      <p class="text-[#00789E] mb-0" (click)="createNew()">Create New</p>
    </div>
  </div>
  <ng-container *ngIf="tableData?.length > 0 || (plannedOutageService.outagesPendingLoading | async); else noData">
    <app-table-with-filters
      tableExportTitle="pending-outages-table"
      [columns]="columns"
      [tableData]="tableData"
      tableType="planned-outages"
      totalsRowType="none"
      [isSavingFiltersEnabled]="false"
    >
    </app-table-with-filters>
  </ng-container>
  <ng-template #noData>
    <app-no-data [title]="'No Submitted Outages'" [summary]="'Submitted Outages Data will appear here'"> </app-no-data>
  </ng-template>
  <app-loading *ngIf="plannedOutageService.outagesPendingLoading | async"></app-loading>
</div>

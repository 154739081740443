<header class="de-site-header de-site-header--internal">
  <div class="de-site-header-main">
    <button
      *ngIf="hamburgerMenuMobile"
      class="de-site-header-hamburger"
      aria-label="Header Menu"
      (click)="onHamburgerMenuClick()"
    >
      <span></span>
    </button>
    <div class="h-full flex flex-col side-nav bg-white dark:bg-dm-bg" *ngIf="showSideNav">
      <span class="py-4 px-8 text-l text-center">Navigation</span>
      <hr class="side-nav--item" />
      <div class="flex flex-row items-center justify-between">
        <ul>
          <li *ngFor="let headerButton of allHeaderControls" class="py-8">
            <a
              class="flex flex-row items-center text-white px-10"
              [attr.data-test-hook]="'HL-' + headerButton.route || ''"
              (click)="onHeaderButtonClick(headerButton)"
              tabindex="0"
            >
              <span *ngIf="headerButton.label !== 'Profile'">{{ headerButton.label }}</span></a
            >
            <ul *ngIf="headerButton.children?.length" aria-hidden="true">
              <ng-container *ngFor="let headerChild of headerButton.children">
                <li *ngIf="headerChild.route || headerChild.action || (headerChild.shouldDisplay | async)" class="py-2">
                  <a
                    *ngIf="headerChild.route || headerChild.action; else navToggle"
                    class="flex flex-row items-center text-white px-10"
                    [attr.data-test-hook]="'de-header-sublink-' + headerChild.route"
                    (click)="onHeaderChildButtonClick(headerChild)"
                    tabindex="0"
                  >
                    <span>{{ headerChild.label }}</span>
                  </a>
                  <ng-template #navToggle>
                    <app-toggle
                      [toggleTitle]="headerChild.label"
                      [toggle]="headerChild.initialValue"
                      (toggleChanged)="headerChild.onToggle($event)"
                      class="flex flex-row items-center px-10"
                    ></app-toggle>
                  </ng-template>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="de-site-header-brand--left de-site-header-brand">
      <div class="de-site-header-logo">
        <img
          src="assets/icons/logos/duke-energy-white.svg"
          alt="duke-energy-logo"
          class="de-site-header-logo__logo de-site-header-logo__use"
        />
      </div>
      <div (click)="onSiteLogoClick()" *ngIf="siteTitle" class="de-site-header-title">
        <a class="de-site-header-title__link" data-test-hook="de-header-title">
          {{ siteTitle }}
        </a>
      </div>
    </div>
    <ng-content></ng-content>

    <nav class="de-site-header-nav--right de-site-header-nav" aria-label="Main">
      <ul class="de-site-header-nav__group">
        <li
          class="de-site-header-nav__item js-trigger-site-header-submenu header-button"
          *ngFor="let headerButton of menuControls"
        >
          <div
            class="whitespace-normal text-center de-site-header-nav__link main-link text-base dark:text-dm-sec-bg"
            *ngIf="headerButton.label === 'Dark Mode'"
            (click)="changeTheme(headerButton)"
          >
            <img
              class="header-button__icon mr-8"
              [src]="toggle ? headerButton.icon.url : moonLightIcon"
              width="24"
              height="24"
            />
            <span>{{ toggle ? 'Light Mode' : headerButton.label }}</span>
          </div>
          <div
            class="whitespace-normal text-center de-site-header-nav__link main-link text-base"
            *ngIf="headerButton.label === 'Updates'"
            (click)="onHeaderChildButtonClick(headerButton)"
          >
            <img class="header-button__icon mr-8" [src]="headerButton.icon.url" width="24" height="24" />
            <span>{{ headerButton.label }}</span>
          </div>
          <div
            class="whitespace-normal text-center de-site-header-nav__link main-button text-base"
            *ngIf="headerButton.label === 'Sign Out'"
            (click)="onHeaderButtonClick(headerButton)"
          >
            <div class="flex bg-white px-16 py-10 rounded-md cursor-pointer">
              <img
                class="header-button__icon mr-8"
                [src]="toggle ? profileIcon : headerButton.icon.url"
                width="20"
                height="20"
              />
              <span>{{ headerButton.label }}</span>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </div>
  <div class="bg-white dark:bg-dm-bg">
    <nav class="de-site-header-nav--right de-site-header-nav" aria-label="Main" #headerMenu>
      <ul class="de-site-header-nav__group !justify-center gap-24">
        <li
          (click)="onHeaderButtonClick(headerButton)"
          (keyup.enter)="onHeaderButtonClick(headerButton)"
          (keyup.space)="onHeaderButtonClick(headerButton)"
          (keydown)="handleHeaderButtonKeyEvent($event)"
          *ngFor="let headerButton of allHeaderControls; let i = index"
          class="de-site-header-nav__item js-trigger-site-header-submenu header-button py-[16px] px-24 !font-bold cursor-pointer"
          [ngClass]="{
            'de-is-current': headerButton === currentRouteHeaderButton,
            'de-is-active': headerButton === activeHeaderButton
          }"
        >
          <div
            class="whitespace-normal text-center de-site-header-nav__link submain-link !p-0"
            [ngClass]="{
              'de-site-header-nav__link--arrow': headerButton.children?.length
            }"
            [attr.data-test-hook]="'HL-' + headerButton.route || ''"
            [attr.aria-haspopup]="headerButton.children?.length"
            [attr.aria-expanded]="headerButton === activeHeaderButton"
            [attr.aria-label]="headerButton.label"
            tabindex="0"
          >
            <ng-container *ngIf="headerButton.icon">
              <img
                class="header-button__icon mr-8"
                [ngClass]="headerButton === currentRouteHeaderButton ? 'filter--normal' : 'filter--white'"
                [src]="headerButton.icon.url"
                [alt]="headerButton.icon.alt"
                [width]="headerButton.icon.width || 24"
                [height]="headerButton.icon.height || 24"
              />
            </ng-container>
            <span>{{ headerButton.label }}</span>
          </div>
          <ul
            *ngIf="headerButton.children?.length"
            class="de-site-header-nav__child-group !font-normal"
            [ngClass]="{ 'de-is-right': i >= allHeaderControls.length - 2 }"
            aria-label="Submenu"
            aria-hidden="true"
            role="menu"
          >
            <ng-container *ngFor="let headerChild of headerButton.children">
              <li
                (click)="onHeaderChildButtonClick(headerChild)"
                (keyup.enter)="onHeaderChildButtonClick(headerChild)"
                (keyup.space)="onHeaderChildButtonClick(headerChild)"
                (keydown)="handleHeaderChildButtonKeyEvent($event)"
                *ngIf="headerChild.route || headerChild.action || (headerChild.shouldDisplay | async)"
                class="de-site-header-nav__child-item"
                [ngClass]="{ toggle__container: headerChild.onToggle }"
              >
                <div
                  *ngIf="headerChild.route || headerChild.action; else navToggle"
                  class="de-site-header-nav__child-link submain-link"
                  role="menuitem"
                  [ngClass]="{
                    'de-is-current': headerChild === activeHeaderChildButton,
                    'de-site-header-nav_child-active': headerChild.active
                  }"
                  [attr.data-test-hook]="'de-header-sublink-' + headerChild.route"
                  [attr.aria-label]="headerChild.label"
                  tabindex="0"
                >
                  <img
                    *ngIf="headerChild.icon"
                    class="inline mr-10 align-middle"
                    [src]="headerChild.icon.url"
                    [alt]="headerChild.icon.alt"
                    [width]="headerChild.icon.width || 24"
                    [height]="headerChild.icon.height || 24"
                  />
                  <span>{{ headerChild.label }}</span>
                </div>
                <ng-template #navToggle>
                  <app-toggle
                    [toggleTitle]="headerChild.label"
                    [toggle]="headerChild.initialValue"
                    (toggleChanged)="headerChild.onToggle($event)"
                  ></app-toggle>
                </ng-template>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</header>

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Site } from 'src/app/shared/models/site';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccountManagementService } from 'src/app/modules/account-management/services/account-management.service';
import { MatDialog } from '@angular/material/dialog';
import { EnergyType } from 'src/app/shared/models/enums/energyType';

@Component({
  selector: 'app-site-curtailment-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteHeaderComponent implements OnInit {
  @Output() getUpdatedSite = new EventEmitter<boolean>();
  @Input() solarSiteWrite: boolean;
  @Input() set site(interconnection: Site) {
    if (interconnection) {
      this.updateSite(interconnection);
    }
  }
  noFlags: string;
  smallerScreen = false;
  expanded = false;
  protected siteModel?: Site;
  protected readonly surfaceAzmuth: string =
    'Direction solar panels are facing - 0 degrees pointing North and 180 degrees pointing South';
  protected readonly surfaceTilt: string =
    'Solar panel tilt up from the ground - 0 degrees indicates panels horizontally to the ground - 90 degrees indicates panels mounted vertically';
  protected readonly axisAzmuth: string =
    'Direction the axis of rotation is facing - 0 degrees pointing North and 180 degrees pointing South';
  protected readonly axisTilt: string =
    'Any tilt in the solar panel axis of rotation - Most sites do not tilt the axis of rotation and this input is 0 degrees';

  constructor(
    protected accountManagementService: AccountManagementService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (window.innerWidth <= 1024) {
      this.smallerScreen = true;
    }

    fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.smallerScreen = window.innerWidth <= 1024 ? true : false;
      });
  }

  getFlagIconUrl(flag: string): string {
    return this.accountManagementService.flagIconArray.filter((item) => item.flag === flag)[0].url;
  }

  private updateSite(site: Site) {
    this.siteModel = site;
  }

  protected readonly EnergyType = EnergyType;
}

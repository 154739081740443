import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  public transform(items: any[], keys: string, term: string) {
    if (!items) {
      return [];
    }

    if (!term) {
      return items || [];
    }

    return items.filter((item) =>
      keys
        .split(',')
        .some(
          (key) =>
            item[key].toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !==
            -1
        )
    );
  }
}

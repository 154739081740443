<app-de-modal
  [open]="modalOpen"
  (close)="close(null)"
  [showMask]="true"
  [showHeader]="false"
  [showClose]="true"
  [showCloseOnly]="true"
>
  <div position="centered">
    <form [formGroup]="saveTableViewForm">
      <div class="save-table-view-modal">
        <div class="save-table-view-modal__header">Save Table View</div>
        <div class="save-table-view-modal__description">
          This table will save with the selected filters and desired name.
        </div>
        <div class="save-table-view-modal__input">
          <mat-form-field>
            <input
              matInput
              placeholder="Insert Table Name"
              formControlName="name"
            />
          </mat-form-field>
        </div>

        <div class="save-table-view-modal__filters" *ngIf="filteredColumns?.length > 0">
          <div
            *ngFor="let column of filteredColumns"
            class="save-table-view-modal__filters__filter"
          >
            <div class="save-table-view-modal-filters__filter-name">{{ column.name }}</div>
            <div
              class="save-table-view-modal__filters__filter-value"
              *ngIf="column.type === 'string' || column.type === 'html' || column.type === 'mailtoEmail'"
            >
              <span>{{ column.filter.searchValue }}</span>
            </div>
            <div
              class="save-table-view-modal__filters__filter-value"
              *ngIf="column.type === 'number'"
            >
              Min: <span>{{ column.filter.min }}</span> - Max:
              <span>{{ column.filter.max }}</span>
            </div>
          </div>
        </div>

        <div class="save-table-view-modal__buttons">
          <de-button type="secondary" (buttonClick)="close(null)"
            >CANCEL</de-button
          >
          <de-button
            type="primary"
            (buttonClick)="saveTableView()"
            [disabled]="!saveTableViewForm.valid"
            >SAVE TABLE</de-button
          >
        </div>
      </div>
    </form>
  </div>
</app-de-modal>

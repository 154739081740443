<div class="site-search px-16 -my-14 -mx-24 md:px-24 py32 md:py-48 bg-white dark:bg-dm-bg">
  <div class="container-xl text-center">
    <h1 class="text-xl">Search Sites</h1>

    <p class="rich-text pt-6 md:pt-12">
      Search for sites by entering: Site Name or Contract Account Number
    </p>
  </div>

  <div class="relative container-4xl flex flex-col">
    <app-display-table
      [includeTextFilter]="true"
      [pagingEnabled]="true"
      [getData]="getSites"
      [loading]="sitesService.isAllSitesLoading$ | async"
      [sortingEnabled]="false"
      [includeHeaderRow]="false"
      [rowClass]="'no-border'"
      noRecordsText="No matching sites found."
      (filtersInitialized)="filtersInitialized($event)"
    >
      <ng-template #siteTemplate let-element="element" let-column="column">
        <div class="flex w-[700px]">
          <a
            class="text-lg text-teal-darker mr-12 cursor-pointer underline hover:no-underline"
            (click)="navigateToSite(element)"
          >
            {{ element.name ? element.name : element.substationName }}
          </a>
          <mat-chip-listbox *ngIf="this.searchText !== ''" aria-label="Sites Filtered">
            <mat-chip-option *ngIf="containsSearchText(element.name)">Site</mat-chip-option>
            <mat-chip-option *ngIf="containsSearchText(element.substationName)">Sub Name</mat-chip-option>
            <mat-chip-option *ngIf="containsSearchText(element.substationId)">Sub ID</mat-chip-option>
            <mat-chip-option *ngIf="containsSearchText(element.stlfDisplayName)">STLF Name</mat-chip-option>
          </mat-chip-listbox>
        </div>
      </ng-template>
      <ng-template #uniqueIdTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #uniqueIdDisplayTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #subNameTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #subIdTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #feederNumTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #stlfNameTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #containerTemplate let-element="element" let-column="column"> </ng-template>
      <ng-template #energyTypeTemplate let-element="element" let-column="column">
        <ng-container *ngIf="element.energyType === 'Solar' && (element.batteryType !== 'Battery' && element.batteryType !== 'Microgrid'); then solar; else battery"> </ng-container>
        <ng-template #solar>
          <mat-icon class="m-auto relative solar-icon mr-8" svgIcon="solar-electric-base"> </mat-icon>
        </ng-template>
        <ng-template #battery>
          <ng-container *ngIf="element.batteryType === 'Battery'; else microgrid">
            <mat-icon class="m-auto relative battery-icon mr-8" svgIcon="battery-storage-base"> </mat-icon>
          </ng-container>
        </ng-template>
        <ng-template #microgrid>
          <!-- Can't get Dark Mode icon color exactly to #36c6dd, changing brightness gets it close enough -->
          <mat-icon class="m-auto relative microgrid-icon mr-8 dark:brightness-110" svgIcon="microgrid"> </mat-icon>
        </ng-template>
      </ng-template>
      <ng-template #opCenterClassifierTemplate let-element="element" let-column="column"> </ng-template>
      <app-data-cell name="energyType" label="energy" [cellTemplate]="energyTypeTemplate"> </app-data-cell>
      <app-data-cell name="name" label="name" [cellTemplate]="siteTemplate"> </app-data-cell>
      <app-data-cell name="unique_id" label="" [cellTemplate]="uniqueIdTemplate"> </app-data-cell>
      <app-data-cell name="uniqueIdDisplay" label="" [cellTemplate]="uniqueIdDisplayTemplate"> </app-data-cell>
      <app-data-cell name="substationName" label="" [cellTemplate]="subNameTemplate"> </app-data-cell>
      <app-data-cell name="substationId" label="" [cellTemplate]="subIdTemplate"> </app-data-cell>
      <app-data-cell name="feederNumber" label="" [cellTemplate]="feederNumTemplate"> </app-data-cell>
      <app-data-cell name="stlfDisplayName" label="" [cellTemplate]="stlfNameTemplate"> </app-data-cell>
      <app-data-cell name="opCenterClassifier" label="" [cellTemplate]="opCenterClassifierTemplate"> </app-data-cell>
      <app-data-cell name="contract.billingAcctNum" label="" [cellTemplate]="containerTemplate"></app-data-cell>
      <app-data-cell name="address.city" label="" [cellTemplate]="containerTemplate"></app-data-cell>
      <app-data-cell name="address.state" label="" [cellTemplate]="containerTemplate"></app-data-cell>
      <app-data-cell name="address.zipCode" label="" [cellTemplate]="containerTemplate"></app-data-cell>
      <app-data-cell name="address.county" label="" [cellTemplate]="containerTemplate"></app-data-cell>
      <app-data-cell name="address.street" label="" [cellTemplate]="containerTemplate"></app-data-cell>
    </app-display-table>
  </div>
</div>

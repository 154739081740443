import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseModalComponent } from 'src/app/shared/components/modals/base-modal.component';
import { RemoveSiteService } from 'src/app/modules/sites/services/remove-site.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Site } from '../../../../../shared/models/site';
import { AccessLevelRequest } from '../../../../register-account/models/accountCreationModel';
import { Router } from '@angular/router';

/**
 * Expected format of the whats-new.json file
 */
export interface ProdRelease {
  releaseDate: string;
  sections: [
    {
      title: 'Added' | 'Changed' | 'Fixed' | 'Removed';
      statements: string[];
    }
  ];
}

@Component({
  selector: 'app-remove-site-dialog',
  templateUrl: './remove-site-dialog.component.html',
  styleUrls: ['./remove-site-dialog.component.scss'],
})
export class RemoveSiteDialogComponent extends BaseModalComponent {
  @Input() isOpen = false;
  @Input() latestUpdates = [];
  @Output() closeRequest = new EventEmitter<void>();
  @Input() confirmation = false;
  @Input() site: Site;
  @Input() role: string;
  openSiteDialog: boolean;
  protected userLevelRequest = AccessLevelRequest;

  protected removeSiteForm = new FormGroup({
    removeSiteOption: new FormControl<boolean>(null),
  });

  constructor(public removeSiteService: RemoveSiteService, private router: Router) {
    super();
  }

  toggleConfirm() {
    this.confirmation = !this.confirmation;
  }

  toggleDialog() {
    this.openSiteDialog = !this.openSiteDialog;
  }

  public removeSiteDialog() {
    this.removeSiteService
      .removeSite(this.site.unique_id, this.removeSiteForm.controls.removeSiteOption.value)
      .subscribe((data) => {
        if (data.success) {
          this.close(false);
          void this.router.navigateByUrl('/site-information/sites');
        }
      });
  }
}

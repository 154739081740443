import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayJoin',
})
export class ArrayJoinPipe implements PipeTransform {
  transform(value: string[], separator = ', '): string {
    if (!value || value.length === 0) {
      return '';
    }

    return value.join(separator);
  }
}

import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {GtmService} from "./gtm.service";

// TODO: Refactor - should only need 1 observable for the theme, should be unsubscribing, and key
//    should be locked down to LocalSettings.
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public isDarkThemeObservable: Observable<boolean> = of(false);
  public isDarkTheme: boolean;
  public themeChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // Used to support theme choice in fullscreen mode
  public themeBackgroundClass: 'light-background' | 'dark-background' =
    'light-background';

  private _storageKey = 'siteTheme';
  private _darkClassName = 'dark-mode';

  constructor(private overlay: OverlayContainer) {
    this.getStoredTheme();
    this.isDarkThemeObservable.subscribe((t) => {
      this.themeChanges$.next(t);
      this.isDarkTheme = t;
    });
  }

  storeTheme(isDark: boolean) {
    // GTM Doc - 5.1.2
    GtmService.clickEvent({
      category: 'navigation',
      action: isDark ? 'profile-|-darkmode-on': 'profile-|-darkmode-off',
      label: 'navigation-header',
    });

    try {
      localStorage.setItem(this._storageKey, isDark.toString());
      this.isDarkThemeObservable = of(isDark);
      this.setOverlayTheme(isDark);
      this.themeChanges$.next(isDark);
      this.isDarkTheme = isDark;
      // TODO
      // eslint-disable-next-line no-empty
    } catch {}
  }

  getStoredTheme(): Observable<boolean> | null {
    const item = localStorage.getItem(this._storageKey);

    if (item === null) {
      return null;
    }
    const isDark = item === 'true';
    this.isDarkThemeObservable = of(isDark);
    this.setOverlayTheme(isDark);
    return this.isDarkThemeObservable;
  }

  // This is needed to set dark mode on overlay containers, like mat-select-panel
  setOverlayTheme(isDark: boolean) {
    if (isDark) {
      this.overlay.getContainerElement().classList.add(this._darkClassName);
      this.themeBackgroundClass = 'dark-background';
    } else {
      this.overlay.getContainerElement().classList.remove(this._darkClassName);
      this.themeBackgroundClass = 'light-background';
    }
  }

  clearStorage() {
    try {
      localStorage.removeItem(this._storageKey);
      // TODO: Either remove or log
      // eslint-disable-next-line no-empty
    } catch {}
  }
}

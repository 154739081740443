import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'de-button',
  template: `
    <button
      type="{{ htmlType }}"
      class="de-button de-button--{{ type }}"
      [ngClass]="{
        'is-spinner': loading,
        'de-button--small': size === 'small',
        'de-button--medium': size === 'medium',
        'de-button--large': size === 'large',
        'de-button--full': size === 'full'
      }"
      [attr.disabled]="disabled || null"
      (click)="onButtonClick($event)"
    >
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['./de-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeButtonComponent {
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() size: string;
  @Input() htmlType: 'button' | 'submit' | 'reset' = 'button';

  // If using "click", it also registers component clicks, need a custom event
  @Output()
  public buttonClick: EventEmitter<PointerEvent> =
    new EventEmitter<PointerEvent>();

  /** Propagate button clicks out from the component */
  public onButtonClick(event: PointerEvent) {
    this.buttonClick.emit(event);
  }
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  DateChangedEvent,
  MonthSelectorComponent,
} from 'src/app/shared/components/month-selector/month-selector.component';
import { FileService } from 'src/app/shared/services/utils/file.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { MatSort } from '@angular/material/sort';
import { Site } from 'src/app/shared/models/site';
import { GenerationViolation } from 'src/app/shared/models/sites/generationViolation';
import { SiteNotification } from 'src/app/shared/models/sites/siteNotification';

@Component({
  selector: 'app-generation-violations',
  templateUrl: './generation-violations.component.html',
  styleUrls: ['./generation-violations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// implements AfterViewInit
export class GenerationViolationsComponent extends BaseComponent {
  @Input() set site(site: Site) {
    if (site) {
      this.siteModel = site;
      this.siteNameForGa = GtmService.format(this.siteModel.name);
    }
  }

  @Input() set siteNotifications(notifications: SiteNotification[]) {
    if (notifications) {
      this.dataSource.data = notifications
        .filter((n) => n.overGeneration)
        .map((n) => n.overGeneration);
      this.sortDataByDate();
    }
  }

  @Output() monthSelectionEvent = new EventEmitter<DateChangedEvent>();

  @ViewChild(MonthSelectorComponent)
  monthSelector: MonthSelectorComponent;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this._sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this._paginator = mp;
    this.setDataSourceAttributes();
  }
  currentDate = new Date().toISOString();

  dataSource = new MatTableDataSource<GenerationViolation>();
  displayedColumns = ['date', 'averageMW', 'excessMW'];
  monthSelection: DateChangedEvent = null;
  siteNameForGa: string;
  paginatorSizeOptions = [5, 10, 25, 50, 100];
  defaultDate: string;
  siteModel: Site;

  private _paginator: MatPaginator;
  private _sort: MatSort;

  constructor(public fileService: FileService) {
    super();
  }

  public sortDataByDate() {
    // array sorting is in-place but it seems the data array must be assigned explicitly?
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (new Date(b.date) as any) - (new Date(a.date) as any);
    });
    this.filterBySelectedDate();
  }

  public setMonthSelector(newDateIso: string) {
    if (this.monthSelector) {
      this.currentDate = newDateIso;
      this.monthSelector.setDate(newDateIso);
    }
  }

  newMonthSelection(selection: DateChangedEvent) {
    this.monthSelectionEvent.emit(selection);
    this.sortDataByDate();
    // GTM External Doc 5.3.4.1.1
    GtmService.clickEvent({
      category: 'sites',
      action: 'date-range-changed',
      label: `date-|-${this.siteModel.unique_id}`,
    });
  }

  filterBySelectedDate() {
    const parts = this.currentDate.split('-');
    const yr = parts[0];
    const mnth = parts[1];
    const filteredByYearMonth = this.dataSource.data.filter((v) => {
      return v.date.getMonth() + 1 === +mnth && v.date.getFullYear() === +yr;
    });
    this.dataSource.data = filteredByYearMonth;
  }

  downloadCsv() {
    GtmService.clickEvent({
      category: 'site',
      label: this.siteNameForGa,
      action: 'export-table',
    });

    this.fileService.downloadData(this.dataSource.data, 'curtailment_history.csv');
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this._paginator;
    this.dataSource.sort = this._sort;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffix'
})
export class SuffixPipe implements PipeTransform {
  transform(value: string | number, suffix: string | number): string | number {
    if (!value && value !== 0) {
      return '';
    }
    if (!suffix && suffix !== 0) {
      return value;
    }
    return `${value}${suffix}`;
  }
}

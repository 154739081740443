<form class="filter-section">
  <mat-form-field class="column-filter">
    <mat-label>Column Filter</mat-label>
    <mat-select [hideSingleSelectionIndicator]="true"
                (selectionChange)="changeColumnFilter($event)">
      <mat-option>Select column to filter</mat-option>
      <ng-container *ngFor="let c of columnOptions">
        <mat-option *ngIf="c.displayFilter" [value]="c.key">{{ c.name && c.year ? (c.name + ' (' + selectedYear +')' ) : c.name }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- Could use an ngSwitch here but then we lose type safety -->
  <ng-container *ngIf="activeColumn">
    <!-- Search Input -->
    <ng-container
      *ngIf="
          activeColumn.type === 'string' ||
          activeColumn.type === 'html' ||
          activeColumn.type === 'mailtoEmail'
        "
    >
      <mat-form-field class="search-input">
        <mat-label>Search</mat-label>
        <input matInput #searchValue />
      </mat-form-field>
      <button
        class="de-button de-button--secondary apply-filter-button"
        (click)="applyStringFilter(searchValue.value)"
      >
        APPLY
      </button>
    </ng-container>

    <!-- Number Range Filter -->
    <ng-container
      *ngIf="
          activeColumn.type === 'number' ||
          activeColumn.type === 'addend'
        "
    >
      <mat-form-field class="search-input">
        <mat-label>Minimum Value</mat-label>
        <input matInput #min type="number" placeholder="Min" value="0.0" />
      </mat-form-field>
      <mat-form-field class="search-input">
        <mat-label>Maximum Value</mat-label>
        <input
          matInput
          #max
          type="number"
          placeholder="Max"
          value="{{ MAX_RANGE_VALUE }}"
        />
      </mat-form-field>
      <button
        class="de-button de-button--secondary apply-filter-button"
        (click)="applyRangeFilter(min.valueAsNumber, max.valueAsNumber)"
      >
        APPLY
      </button>
    </ng-container>

    <ng-container
      *ngIf="
          activeColumn.type === 'multi' ||
          activeColumn.type === 'bool'
        "
      class="multi-selector"
    >
      <mat-form-field>
        <mat-label>Select one</mat-label>
        <mat-select [multiple]="true"
                    [value]="activeColumn.filter?.multiSearchValue"
                    (valueChange)="applyMultiOptionFilter($event)">
          <mat-option
            *ngFor="let multiOption of activeColumn.filterOptions"
            [value]="multiOption"
          >{{ multiOption }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div class="date-filter" *ngIf="activeColumn.type === 'date'">
      <div class="summary-calendar">
        <app-month-selector
          (monthSelectionChanged)="applyDateFilter($event)"
        ></app-month-selector>
      </div>
    </div>
  </ng-container>
</form>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';

export interface SuccessModel {
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RemoveSiteService extends ApiService {
  public removeSite(uniqueId: number, allUsers: boolean): Observable<SuccessModel> {
    return this.http.put<SuccessModel>(`${this.apiUrl}/external/sites/${uniqueId}/remove-access`, {allUsers:allUsers});
  }
}

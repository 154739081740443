<div class="outages">
  <div class="flex flex-col">
    <div class="text-xl">Processed Outages</div>
    <div class="mt-20 mb-20">
      <div class="flex gap-20 items-center">
        <app-month-selector [defaultDateIso]="currentDate" (monthSelectionChanged)="newMonthSelection($event)">
        </app-month-selector>
        <div class="flex  cursor-pointer items-center">
          <img class="inline mr-6 mb-2" src="assets/icons/plus.svg" alt="" />
          <p class="text-[#00789E] mb-0" (click)="createNew()">Create New</p>
        </div>
      </div>

      <ng-container *appRequiredRoles="allowUserManagePlannedOutages">
        <a [routerLink]="['/action-center/planned-outages']" class="ml-24 strong" (click)="
        GtmService.clickEvent({category: 'operations', action: 'clickthrough', label: 'new-planned-outage'});
        urlService.setPreviousUrl('/action-center/planned-outages');
      ">
          <img class="inline mr-6 mb-4" src="assets/icons/plus.svg" alt="" />
          <span class="underline">Create New</span>
        </a>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="dataSource.data?.length > 0; else noData">
    <div class="site-planned-outages">
      <table mat-table matSort [dataSource]="dataSource" #sortingTable="matSort">
        <ng-container matColumnDef="startDateTime" sticky="true">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Event Start Date
          </th>
          <td class="whitespace-nowrap text-center" mat-cell *matCellDef="let element">
            {{ element.startDateTime | date: 'shortDate' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="approvalStatus">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Approval Status
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.approvalStatus }}
          </td>
        </ng-container>

        <ng-container matColumnDef="outageType">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Outage Type
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.outageType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="derateAmount">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Derate Amount
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.siteCapacity - element.temporaryExpectedCapacity | number: '1.0-0' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="temporaryExpectedCapacity">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Temporary Capacity
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.temporaryExpectedCapacity | number: '1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="temporaryCapacity">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Temporary Capacity %
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ (element.temporaryExpectedCapacity / element.siteCapacity) * 100 | number: '1.0-0' }} %
          </td>
        </ng-container>

        <ng-container matColumnDef="timeStart">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Time Start
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.startDateTime | date: 'HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="restorationTime">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Restoration Time
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.endDateTime | date: 'M/d/yy, HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Duration (hh:mm)
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ asDuration(element) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Created By:
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.createdBy.firstName }} {{ element.createdBy.lastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastUpdatedBy">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            Last Updated By:
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            {{ element.lastEditedBy.firstName }} {{ element.lastEditedBy.lastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="edit" *ngIf="isEdit">
          <th class="whitespace-nowrap" mat-header-cell *matHeaderCellDef>
            &nbsp;
          </th>
          <td class="whitespace-nowrap" mat-cell *matCellDef="let element">
            <a class="text-teal-darker hover:no-underline flex pointer-events-none w-40"
              [routerLink]="['/action-center/planned-outages/', element.uuid]">
              <img class="pointer-events-auto " src="assets/icons/pencil-blue.svg" alt="Edit Planned Outage" height="20"
                width="20" />
            </a>
          </td>
        </ng-container>

        <tr class="-mt-2" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </ng-container>

  <ng-template #noData>
    <app-no-data [title]="'No Processed Outages'" [summary]="'Processed Outages Data will appear here'">
    </app-no-data>
  </ng-template>
</div>

import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * To be used if data to display does not exist, i.e. that for a table or chart.
 * @input title, summary
 */
@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NoDataComponent {
  // Title to display under the gray informational icon.
  @Input() title = 'No Data Available';
  // Summary to display under the title.
  @Input() summary = '';
}

<!-- Uncentered Modal -->
<div *ngIf="open && !center"
  class="modal-mask fixed z-[4] flex justify-center items-center inset-0"
  [ngClass]="{'transparent': !showMask}"
  (click)="closeModal()"></div>
<div *ngIf="open && !center"
  class="modal no-center bg-white dark:bg-dm-bg"
  [ngClass]="{'drop-shadow': !showMask}"
  [@modalFadeAnimation]="'in'"
  [style.width]="width"
  [style.height]="height"
  [style.top]="top"
  [style.bottom]="bottom" [style.left]="left" [style.right]="right" (click)="$event.stopPropagation()">
  <div *ngIf="showHeader"
    class="modal-header flex flex-row justify-between items-center w-full max-w-full h-56 px-16
           py-14 rounded-[2px_2px_0em_0em] {{this.theme}}">
    <h1 class="title">{{modalTitle}}</h1>
    <button *ngIf="showClose" #uncenteredCloseButton (click)="closeModal()"><div class="x">+</div></button>
  </div>
  <div *ngIf="showCloseOnly"
       class="only-close flex flex-row items-center py-14 px-16 w-full max-w-full h-56 rounded-[2px_2px_0_0]">
    <button #onlyCloseButton class="bg-transparent border-[none] hover:cursor-pointer" (click)="closeModal()">
      <div class="rotate-45 text-3xl justify-self-end">+</div>
    </button>
  </div>
  <div class="modal-body" [style.padding]="padding">
    <ng-content tabindex="0" select="div[position=uncentered]"></ng-content>
  </div>
</div>

<!-- Centered Modal -->
<div *ngIf="open && center"
  class="modal-mask fixed z-[4] flex justify-center items-center inset-0"
  [ngClass]="{'bg-black bg-opacity-32 dark:bg-white dark:bg-opacity-32': showMask, 'bg-transparent': !showMask}"
  (click)="closeModal()">
  <div *ngIf="center"
    class="modal center bg-white dark:bg-dm-bg"
    [ngClass]="{'drop-shadow': !showMask}"
    [@modalFadeAnimation]="'in'"
    [style.width]="width"
    [style.height]="height"
    (click)="$event.stopPropagation()">
    <div *ngIf="showHeader"
         class="modal-header flex flex-row justify-between items-center w-full max-w-full h-56 px-16
         py-14 rounded-[2px_2px_0em_0em] {{this.theme}}">
      <h1 class="title">{{modalTitle}}</h1>
      <button *ngIf="showClose" #centerCloseButton (click)="closeModal()"><div class="x">+</div></button>
    </div>
    <div *ngIf="showCloseOnly"
         class="only-close flex flex-row items-center py-14 px-16 w-full max-w-full h-56 rounded-[2px_2px_0_0]">
      <button #onlyCloseButton class="bg-transparent border-[none] hover:cursor-pointer" (click)="closeModal()">
        <div class="rotate-45 text-3xl justify-self-end">+</div>
      </button>
    </div>
    <div class="modal-body" [style.padding]="padding">
      <ng-content tabindex="0" select="div[position=centered]"></ng-content>
    </div>
  </div>
</div>

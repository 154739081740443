/**
 * Compares the value of a given property on two objects and returns -1 if the value of the property
 * on the first object is less than the value on the second object.
 *
 * Used for sorting. If the property exists on both objects, they will be compared on that property.
 * If one object does not have the property, null is returned (won't be compared / will go at the end of a sorted list).
 *
 * @param {object} objA - The first object to compare.
 * @param {object} objB - The second object to compare.
 * @param {string} property - The name of the property to compare. This property must exist on both objects.
 * @returns {number} Returns -1 if the value of the property on objA is less than the value on objB. Otherwise, returns 0.
 */
export function compareByProperty<T, U>(
  objA: T | U,
  objB: T | U,
  property: string | number
): number | null {
  if (
    !objA ||
    !objA[property] ||
    !objB ||
    !objB[property] ||
    objA[property] === objB[property]
  ) {
    return 0;
  }
  try {
    if (objA[property] < objB[property]) {
      return -1;
    } else {
      return 1;
    }
  } catch (err) {
    // not comparable
    return null;
  }
}

/**
 * Compares the value of given properties on two objects and returns -1 if the value of the property
 * on the first object is less than the value on the second object.
 *
 * Used for sorting. If properties are e.g. 'first', 'second', if the prop 'first' exists on both objects,
 * they will be compared on that prop. Otherwise, 'first' on one will be compared to 'second' on the other. Otherwise,
 * 'second' will be compared on both. If one object has neither property, null is returned (won't be compared /
 * will go at the end of a sorted list).
 *
 * @param {object} objA - The first object to compare.
 * @param {object} objB - The second object to compare.
 * @param {string} property1 - The name of the primary property to compare.
 * @param {string} property2 - The name of the secondary/fallback property to compare (if, for example, both don't have the primary property)
 * @returns {number} Returns -1 if the value of the property on objA is less than the value on objB. Otherwise, returns 0.
 */
export function compareByProperties<T, U>(
  objA: T | U,
  objB: T | U,
  property1: string | number,
  property2: string | number
): number | null {
  if (objA && objB) {
    try {
      if (objA[property1] && objB[property1]) {
        return objA[property1] < objB[property1] ? -1 : 1;
      } else if (objA[property1] && objB[property2]) {
        return objA[property1] < objB[property2] ? -1 : 1;
      } else if (objA[property2] && objB[property1]) {
        return objA[property2] < objB[property1] ? -1 : 1;
      } else if (objA[property2] && objB[property2]) {
        return objA[property2] < objB[property2] ? -1 : 1;
      } else {
        return 0;
      }
    } catch {
      // not comparable
      return null;
    }
  } else {
    return 0;
  }
}

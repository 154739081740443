import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LandingComponent } from 'src/app/core/components/landing/landing.component';
import { AppPreloadStrategy } from './appPreLoadStrategy';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'site-information/sites',
        loadChildren: () => import('src/app/modules/sites/sites.module').then((m) => m.SitesModule),
        data: { preload: false },
      },
      {
        path: 'site-information/program-manager',
        loadChildren: () =>
          import('src/app/modules/account-management/account-management.module').then(
            (m) => m.AccountManagementModule
          ),
        data: { preload: false },
      },
      {
        path: 'home',
        loadChildren: () => import('src/app/modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'action-center/register-account',
        loadChildren: () =>
          import('src/app/modules/register-account/register-account.module').then(
            (m) => m.RegisterAccountModule
          ),
        data: { preload: false },
      },
      {
        path: 'help-center/user',
        loadChildren: () => import('src/app/modules/users/users.module').then((m) => m.UsersModule),
        data: { preload: false },
      },
      {
        path: 'action-center/user',
        loadChildren: () => import('src/app/modules/users/users.module').then((m) => m.UsersModule),
        data: { preload: false },
      },
      {
        path: 'action-center/planned-outages',
        loadChildren: () =>
          import('src/app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { preload: false },
      },
      {
        path: 'inquiries',
        loadChildren: () =>
          import('src/app/modules/action-center/inquiries.module').then((m) => m.InquiriesModule),
        data: { preload: false },
      },
    ],
  },
  {
    path: 'landing',
    pathMatch: 'full',
    component: LandingComponent,
  },
  {
    path: 'unauthorized',
    component: LandingComponent, // This will lead to a different page that is yet to be built
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppPreloadStrategy,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

    <div class="progress-container">
      <div class="progress-bar-container">
        <img src="assets/icons/battery_storage.svg"
             alt="battery state of charge"
             width="200px"
             height="140px">
        <div class="progress-bar"
             [ngClass]="progressColor"
             [style.width.%]="progressPercent - progressPercentPadding">
        </div>
        <!-- Inside/outside here means relative to the progress bar itself -->
        <span *ngIf="progressPercent >= 50"
              class="inside"
              [style.width.%]="progressPercent - progressPercentPadding">
          {{progressPercent}}%
        </span>
        <span *ngIf="progressPercent < 50"
              class="outside"
              [style.width.%]="100 - progressPercent - progressPercentPadding">
          {{progressPercent}}%
        </span>
      </div>
        <div class="text-center mt-8">
          State of Charge
        </div>
    </div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { addDays, startOfDay } from 'date-fns';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { GenerationModel, GenerationSummaryModel } from 'src/app/shared/models/generation';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { SiteSpecificGenerationSummary } from 'src/app/shared/models/interconnection';

/**
 * Ops for Generation/Production data
 */
@Injectable({
  providedIn: 'root',
})
export class GenerationDataService extends ApiService {
  public isDataLoading = false;
  public generationData$ = new BehaviorSubject<GenerationModel[]>([]);
  public generationSummary$ = new BehaviorSubject<GenerationSummaryModel>(null);
  protected _logger = new LoggingService('GenerationData', 'CachedData');

  private get _startOfToday(): Date {
    return startOfDay(new Date());
  }

  private get _startOfTomorrow(): Date {
    return addDays(this._startOfToday, 1);
  }

  /**
   * GET energy production summary for this given unique identifier
   *
   * @param uniqueId  of the unique site
   */
  public getSiteSpecificGenerationSummary(uniqueId: number) {
    this._logger.info(
      `getSiteSpecificGenerationSummary(${uniqueId}, ${this._startOfToday.toISOString()}, ${this._startOfTomorrow.toISOString()})`
    );
    this.isDataLoading = true;
    const curYear = this._startOfToday.getFullYear();
    return this.http
      .get<SiteSpecificGenerationSummary>(
        `${this.apiUrl}/sites/solar/${uniqueId}/annual/${curYear}/`
      )
      .pipe(
        tap((data) => {
          this.generationSummary$.next({ today: data.today, month: data.month, year: data.year });
        }),
        catchError((err) => this.handleError('Unable to load generation data', err)),
        finalize(() => (this.isDataLoading = false))
      )
      .subscribe();
  }
}

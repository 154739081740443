import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { env } from 'src/config/env';
import { ThemeService } from './shared/services/utils/theme.service';
import { EdsActionButton } from '@electronds/ng-components-duke';
import { InterconnectionModel } from './shared/models/interconnection';
import { GtmService } from './shared/services/utils/gtm.service';
import { HeaderButton } from './core/components/header/models/header-controls';
import { AuthorizationService } from 'src/app/core/services/authorization.service';
import { SitesService } from 'src/app/shared/services/api/sites.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public headerDrawerIsOpen = false;
  public showFooter$: Observable<boolean>;

  public noFooterRoutes = ['sites/map'];
  protected userHasSites: boolean;
  protected userSites: InterconnectionModel[];
  protected specificID: string;
  protected headerActionButtons: EdsActionButton[] = this.getHeaderActionButtons(
    this.themeService.isDarkTheme
  );
  public allHeaderControls: HeaderButton[];
  public menuControls: HeaderButton[];
  public currentRouteHeaderButton: HeaderButton = null;

  private _unsubscribe = new Subject();
  private _iconsBaseUrl = 'assets/icons/';
  private _iconsSharedUrl = 'app/shared/assets/icons/';

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    public themeService: ThemeService,
    private titleService: Title,
    private authService: AuthorizationService,
    public sitesService: SitesService
  ) {
    const suffix = env.env == 'prod' ? '' : ` External - ${env.env.toUpperCase()}`;
    this.titleService.setTitle(`DER Dashboard${suffix}`);
    this.registerIcons();
    this.menuControls = [
      {
        label: 'Dark Mode',
        initialValue: this.themeService.isDarkTheme,
        onToggle: (event: boolean) => this.themeService.storeTheme(event),
        shouldDisplay: of(true),
        icon: {
          url: `${this._iconsSharedUrl}sun.svg`,
          alt: 'new',
        },
      },
      {
        label: 'Sign Out',
        route: 'logout',
        icon: {
          url: `${this._iconsBaseUrl}profile.svg`,
          alt: 'new',
        },
      },
    ];
    const defaultHeaderControls = [
      {
        route: 'home',
        label: 'Home',
      },
      {
        label: 'Site Information',
        children: [
          {
            label: 'Site Search',
            route: 'site-information/sites',
          },
          {
            label: 'Site Alerts',
            route: 'site-information/program-manager',
          },
          {
            label: 'Messages',
            route: 'site-information/program-manager/messages',
          },
        ],
      },
    ];

    this.allHeaderControls = defaultHeaderControls;

    this.showFooter$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      map(
        (e: NavigationStart) =>
          !this.noFooterRoutes.includes(e.url.split('?')[0].replace('/', '').toLowerCase())
      )
    );

    this.sitesService.getAllUserSites().subscribe(() => {
      this.checkUserRegistration();

      if (this.userHasSites) {
        this.allHeaderControls.push({
          label: 'Action Center',
          children: [
            {
              label: 'Register a New Site',
              route: 'action-center/register-account',
            },
            {
              label: 'Report an Outage',
              route: 'action-center/planned-outages',
            },
            {
              label: 'Receive Notifications',
              route: 'action-center/user/notifications',
            },
            {
              label: 'Submit an Inquiry',
              route: 'inquiries',
            },
          ],
        });
      } else {
        this.allHeaderControls.push({
          label: 'Action Center',
          children: [
            {
              label: 'Register a New Site',
              route: 'action-center/register-account',
            },
          ],
        });
      }

      // Add Help Center at the end of Header Controls
      this.allHeaderControls.push({
        label: 'Help Center',
        route: 'help-center/user/user-help-page',
      });
    });
    this.authService.setUserInfo();
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this._unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlNav = event.urlAfterRedirects.split('?')[0].replace('/', '').toLowerCase();
        // Handle primary navigation selection
        // GTM External Doc - 5.1.2
        if (urlNav === 'register-account') {
          GtmService.clickEvent({
            category: 'navigation',
            label: 'navigation-header',
            action: `action-center-|-register-site`,
          });
        } else if (urlNav === 'notifications') {
          // handle "profile" activity
          GtmService.clickEvent({
            category: 'navigation',
            label: 'navigation-header',
            action: `action-center-|-notifications`,
          });
        } else if (urlNav === 'user/user-help-page') {
          // handle "profile" activity
          GtmService.clickEvent({
            category: 'navigation',
            label: 'navigation-header',
            action: `help-center-|-user-help-page`,
          });
        } else {
          // GTM External Doc - 5.1.1
          GtmService.clickEvent({
            category: 'navigation',
            label: 'navigation-header',
            action: 'site-information-|-program-manager',
          });
        }
      }
    });

    // This section determines which nav group is selected by navigated-to route
    const navigationEndURLEvent$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.urlAfterRedirects.split('?')[0].replace('/', '').toLowerCase())
    );

    navigationEndURLEvent$.subscribe((e: string) => {
      if (e) {
        this.allHeaderControls.forEach((parent) => {
          if (parent.children) {
            parent.children.forEach((element) => {
              if (element.route === e || e.startsWith(element.route)) {
                element.active = true;
                this.currentRouteHeaderButton = parent;
              } else {
                element.active = false;
              }
            });
            return;
          } else {
            if (parent.route === e || e.startsWith(parent.route)) {
              parent.active = true;
              this.currentRouteHeaderButton = parent;
            } else {
              parent.active = false;
            }
          }
        });
      } else {
        this.currentRouteHeaderButton = this.allHeaderControls[this.allHeaderControls.length - 1];
      }
    });
  }

  ngOnDestroy() {
    this._unsubscribe.next(null);
  }

  checkUserRegistration() {
    this.userSites = this.sitesService.allInterconnections$.getValue();
    if (this.userSites && this.userSites.length > 0) {
      this.userHasSites = true;
      this.specificID = this.userSites[0].unique_id.toString();
    } else {
      this.userHasSites = false;
      void this.router.navigateByUrl('action-center/register-account');
    }
  }

  public openDrawerMenu(): void {
    this.headerDrawerIsOpen = true;
  }

  handleHeaderButtonPress(headerButton: HeaderButton) {
    if (!headerButton.route) {
      return;
    }

    GtmService.clickEvent({
      category: 'navigation',
      action: headerButton.route,
      label: 'navigation-header',
    });

    if (headerButton.route === 'logout') {
      this.authService.logout();
    } else {
      this.currentRouteHeaderButton = headerButton;
      if (headerButton.params) {
        const key = 'home';
        const queryparams = {};
        queryparams[key] = headerButton.params;
        void this.router.navigate([headerButton.route], { queryParams: queryparams });
      } else {
        void this.router.navigate([headerButton.route]);
      }
    }
  }

  private registerIcons(): void {
    // There might be some way that we can automate setting all
    // of the icons. This was a much easier way coloring and adding
    // svg icons that differed from our typical icons being set by the
    // app-de-icon component.
    let icons = [
      'information-solid',
      'informational',
      'solar-electric-base',
      'battery-storage-base',
      'microgrid',
    ];
    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this._iconsSharedUrl}${icon}.svg`)
      );
    });

    icons = ['table/search'];
    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this._iconsBaseUrl}${icon}.svg`)
      );
    });
  }

  /**
   * With the eds-site-header, it's a little easier to change the
   * icons / labels in this way
   * @private
   */
  private changeTheme(): void {
    const isDarkMode = !this.themeService.isDarkTheme;
    this.themeService.storeTheme(isDarkMode);
    this.headerActionButtons = this.getHeaderActionButtons(isDarkMode);
  }

  private getHeaderActionButtons(isDarkMode: boolean): EdsActionButton[] {
    return [
      {
        label: isDarkMode ? 'Light Mode' : 'Dark Mode',
        icon: {
          path: isDarkMode ? 'app/shared/assets/icons/sun.svg' : 'app/shared/assets/icons/moon.svg',
          sizeClass: 'icon-28',
          attributes: {
            fill: 'currentColor',
          },
        },
        action: () => this.changeTheme(),
      },
      {
        label: 'Sign Out',
        icon: {
          path: 'app/shared/assets/icons/user.svg',
          sizeClass: 'icon-28',
          attributes: {
            fill: 'currentColor',
          },
        },
        action: () => this.authService.logout(),
      },
    ];
  }
}

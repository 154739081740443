import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Month, MONTHS } from 'src/app/shared/models/enums/month';
import { ThemeService } from '../../services/utils/theme.service';
import {addDays, endOfDay, lastDayOfMonth, startOfMonth} from 'date-fns';

export interface DateChangedEvent {
  month: string;
  monthIndex: number;
  year: string;
}

@Component({
  selector: 'app-month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss'],
})
export class MonthSelectorComponent implements OnInit {
  @Output() monthSelectionChanged = new EventEmitter<DateChangedEvent>();
  @Input() defaultDateIso: string = null;
  @Input() hideMonthField = false;

  public showSelector = false;
  public label: string;

  public months = MONTHS;
  public years = [];

  public monthControl = new FormControl<Month>(null);
  public yearControl = new FormControl<string>(null);
  public form = new FormGroup({
    month: this.monthControl,
    year: this.yearControl,
  });

  private _currentYear: string;
  private _currentMonth: Month;

  constructor(public themeService: ThemeService) {}

  ngOnInit(): void {
    // Initialize options
    const today = new Date();
    const endYear = today.getFullYear();
    const startYear = endYear - 2;
    for (let i = startYear; i <= endYear; i++) {
      this.years.push(i.toString());
    }

    // Initialize selections
    if (this.defaultDateIso) {
      const defaultDate = new Date(this.defaultDateIso);
      this._currentYear = defaultDate.getFullYear().toString();
      this._currentMonth = this.months[defaultDate.getMonth()];
    } else {
      this._currentYear = today.getFullYear().toString();
      this._currentMonth = this.months[today.getMonth()];
    }
    this.monthControl.setValue(this._currentMonth);
    this.yearControl.setValue(this._currentYear);

    this.setLabel();
  }

  public apply() {
    this.toggleSelector();

    this._currentMonth = this.monthControl.value;
    this._currentYear = this.yearControl.value;
    this.setLabel();

    this.monthSelectionChanged.next({
      month: this._currentMonth,
      year: this._currentYear,
      monthIndex: this.months.indexOf(this._currentMonth),
    });
  }

  public toggleSelector() {
    this.showSelector = !this.showSelector;
  }

  public setDate(newDate: string) {
    // TODO: Handle properly by casting to client's timezone from UTC first
    // Date at this point is UTC. Fixing bug where January trying to use MONTHS[12]
    const dt = addDays(new Date(newDate), 1);
    this._currentYear = dt.getFullYear().toString();
    this._currentMonth = this.months[dt.getMonth()];
    this.monthControl.setValue(this._currentMonth);
    this.yearControl.setValue(this._currentYear);
    this.setLabel();
  }

  private setLabel() {
    this.label = this.hideMonthField ? `${this._currentYear}` : `${this._currentMonth} ${this._currentYear}`;
  }

  public getDateRangeOfMonth(year: string, monthIndex: number) {
      const yearNumber = parseInt(year);
      const startDate = startOfMonth(new Date(yearNumber, monthIndex, 1));
      const lastDayMonth = endOfDay(lastDayOfMonth(new Date(yearNumber, monthIndex)));
      return {
        'startDate': startDate.toISOString(),
        'endDate': lastDayMonth.toISOString()
      };
  }
}

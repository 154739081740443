import { NotificationPreferenceTypeEnum } from 'src/app/shared/models/interconnection';

export enum SiteFlagEnum {
  All = 'All',
  DisconnectNotice = 'Disconnect Notice',
  NoGeneration = 'No Generation',
  OverGeneration = 'Over Generation',
  // 2 enums for recloser status change
  // First is for display on Program Manager
  RecloserStatusChange = 'Recloser Open',
  // Second is for display and functionality on User Notification Preferences
  RecloserStatusChangeNotificationDisplay = 'Recloser Status Change',
}

export namespace SiteFlag {
  export function parseFromNotificationPreferenceType(
    val: NotificationPreferenceTypeEnum
  ): SiteFlagEnum {
    switch (val) {
      case NotificationPreferenceTypeEnum.DISCONNECT_NOTICE:
        return SiteFlagEnum.DisconnectNotice;
      case NotificationPreferenceTypeEnum.NO_GENERATION:
        return SiteFlagEnum.NoGeneration;
      case NotificationPreferenceTypeEnum.OVER_GENERATION:
        return SiteFlagEnum.OverGeneration;
      case NotificationPreferenceTypeEnum.RECLOSER_STATUS_CHANGE:
        return SiteFlagEnum.RecloserStatusChangeNotificationDisplay;
      default:
        return null;
    }
  }
}

export enum SiteFlagDescriptionEnum {
  DisconnectNotice = 'When the notice has been issued based on the delinquency information.',
  NoGeneration = 'Alert created when the site is not generating for at least 24 hours.',
  OverGeneration = 'Average hourly generation is compared to the PPA installed site capacity. Alert remains active for 24 hours. Older alerts can be viewed in Messages tab.',
  RecloserStatusChange = 'Recloser status change from Closed to Open. Alert remains active for 24 hours.  Older alerts can be viewed in Messages tab.',
}

import { Injectable } from '@angular/core';
import {
  InputColumn,
  UserTableView,
} from 'src/app/shared/components/table-with-filters/models';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ApiService } from "src/app/shared/services/api/api.service";
import { LoggingService } from "../../services/logging.service";

export type TableType = 'curtailment' | 'curtailment-logs' | 'reporting' | 'externalAccess' | 'planned-outages';

export interface SuccessResponse {
  success: boolean;
  description?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TableWithFiltersService extends ApiService {
  private _logger = new LoggingService('FiltersService', 'filters');
  private _map: Map<string, InputColumn[]> = new Map<string, InputColumn[]>();

  setFiltersForNamespace(name: string, columns: InputColumn[]) {
    this._map.set(name, columns);
  }

  getFiltersForNamespace(name: string): InputColumn[] {
    if (this._map.has(name)) {
      return this._map.get(name);
    }
    return [];
  }

  hasFiltersForNamespace(name: string): boolean {
    return this._map.has(name);
  }

  clearAllFilters() {
    this._map.clear();
  }

  public loadTableViewsFromDatabase(
    tableType: TableType
  ): Observable<UserTableView[]> {
    return this.http
      .get<UserTableView[]>(`${this.apiUrl}/user/tables/${tableType}`)
      .pipe(
        tap((tables) => this._logger.debug('Loaded tables', tables)),
        catchError(() => of([]))
      );
  }

  public saveTableViewToDatabase(
    name: string,
    tableType: TableType,
    columns: InputColumn[],
    overwrite = false
  ): Observable<SuccessResponse> {
    this._logger.debug(`Attempting to save ${tableType} table ${name}.`);
    this._logger.debug('Columns', columns);
    this._logger.debug('Overwriting', overwrite);

    const url = `${this.apiUrl}/user/tables/${tableType}`;
    const data = { name, columns };
    let request = this.http.post<SuccessResponse>(url, data);
    if (overwrite) {
      request = this.http.put<SuccessResponse>(url, data);
    }

    return request.pipe(
      tap(() =>
        this.snackBar.success(`Successfully saved '${data.name}' table view.`)
      ),
      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.Conflict) {
          return of({
            success: false,
            description: 'exists',
          }) as Observable<SuccessResponse>;
        }

        this.snackBar.error(`Error saving '${data.name}' table view.`);
        return of({ success: false }) as Observable<SuccessResponse>;
      })
    );
  }

  public deleteTableViewFromDatabase(
    name: string,
    tableType: TableType
  ): Observable<SuccessResponse> {
    return this.http
      .delete<SuccessResponse>(`${this.apiUrl}/user/tables/${tableType}`, {
        body: { name },
      })
      .pipe(
        tap(() =>
          this.snackBar.success(`Successfully deleted '${name}' table view.`)
        ),
        catchError(() => {
          this.snackBar.error(`Error deleting '${name}' table view.`);
          return of({ success: false });
        })
      );
  }
}

import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { PlannedOutageModel } from "src/app/shared/models/plannedOutage";

@Injectable({
  providedIn: 'root',
})
export class PlannedOutagesService extends ApiService {
  public isPlannedOutagesLoading$ = new BehaviorSubject(false);

  getSitePlannedOutages(uniqueId: number | string, startDate: Date, endDate: Date): Observable<PlannedOutageModel[]> {
    this.isPlannedOutagesLoading$.next(true);
    const dateRange = `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    return this.http.get<PlannedOutageModel[]>(`${this.apiUrl}/planned-outages/site/${uniqueId}?${dateRange}`).pipe(
      catchError((err) => {
        return this.handleError(`Unable to load site ${uniqueId} planned outages.`, err);
      }),
      finalize(() => this.isPlannedOutagesLoading$.next(false))
    );
  }
}

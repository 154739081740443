import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { InputColumn } from '../models';

/**
 * Provides a way for users to select which columns to display in their TableView
 * and in which order.
 */
@Component({
  selector: 'app-table-column-options',
  templateUrl: './table-column-options.component.html',
  styleUrls: ['./table-column-options.component.scss'],
})
export class TableColumnOptionsComponent {
  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.showTableData = false;
  }
  @Input() tableExportTitle: string;
  @Input() set columns(columns: InputColumn[]) {
    // If I don't do this, then the columns are passed by reference,
    // and the columns are updated on the table as changes are made,
    // instead of when the button apply is clicked.
    this.newColumns = columns.map((c) => {
      return { ...c };
    });
  }

  @Output() columnsChanged = new EventEmitter<InputColumn[]>();

  public newColumns: InputColumn[];
  public showTableData = false;
  public shouldEnableDarkMode$: Observable<boolean>;

  constructor(public themeService: ThemeService) {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.newColumns, event.previousIndex, event.currentIndex);
  }

  apply() {
    this.columnsChanged.emit(this.newColumns);
    this.toggleTableData();
  }

  toggleTableData() {
    this.showTableData = !this.showTableData;
  }

  optionChecked(columnName: string) {
    GtmService.clickEvent({
      category: 'dashboard',
      action: 'table-data-|-' + columnName.replace(/\s+/g, '-').toLowerCase(),
      label: this.tableExportTitle,
    });
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  Renderer2,
} from '@angular/core';
import { HeaderButton } from './models/header-controls';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';

/**
 * @Deprecated - Should use the new eds-site-header
 * Extracted from the OLD shared Duke component library and tweaked to fit our needs.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  // Represents current page navigation
  @Input() currentRouteHeaderButton: HeaderButton;
  @Input() allHeaderControls: HeaderButton[] = [];
  @Input() menuControls: HeaderButton[] = [];
  @Input() siteTitle: string;
  @Input() hamburgerMenuMobile = false;

  @Output() headerButtonClick = new EventEmitter<HeaderButton>();
  @Output() siteLogoClick = new EventEmitter<void>();

  @ViewChild('headerMenu', { static: true }) headerMenu: ElementRef;

  activeHeaderButton: HeaderButton = null;
  protected activeHeaderChildButton: HeaderButton;
  protected showSideNav = false;
  protected moonLightIcon = 'assets/icons/halfmoon.svg';
  protected profileIcon = 'assets/icons/profile.svg';
  protected toggle = false;

  constructor(public themeService: ThemeService, private renderer: Renderer2) {
    this.toggle = this.themeService.isDarkTheme;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!this.headerMenu.nativeElement.contains(e.target)) {
        this.activeHeaderButton = null;
      }
    });
  }

  onHeaderButtonClick(headerButton: HeaderButton) {
    if (headerButton.children) {
      this.toggleHeaderButtonExpanded(headerButton);
    } else {
      this.activeHeaderButton = null;
    }
    this.headerButtonClick.emit(headerButton);
    this.showSideNav = false;
  }

  onHeaderChildButtonClick(headerChildButton: HeaderButton) {
    this.activeHeaderChildButton = null;
    this.headerButtonClick.emit(headerChildButton);
  }

  onHamburgerMenuClick() {
    this.showSideNav = !this.showSideNav;
  }

  onSiteLogoClick() {
    this.siteLogoClick.emit();
  }

  public toggleHeaderButtonExpanded(headerButton: HeaderButton) {
    this.activeHeaderButton = this.activeHeaderButton === headerButton ? null : headerButton;
  }

  handleHeaderButtonKeyEvent(e) {
    if (this.activeHeaderButton) {
      if (e.key === 'Escape') {
        this.activeHeaderButton = null;
      }
      if (e.key === 'Tab' && e.shiftKey) {
        e.preventDefault();
        const subLinks: NodeListOf<HTMLElement> = document.querySelectorAll(
          '.de-is-active  .de-site-header-nav__child-link'
        );
        subLinks[subLinks.length - 1].focus();
      }
    }
  }
  handleHeaderChildButtonKeyEvent(e) {
    const subLinks: NodeListOf<HTMLElement> = document.querySelectorAll(
      '.de-is-active  .de-site-header-nav__child-link'
    );
    const parentLink: HTMLElement = document.querySelector(
      '.de-is-active  .de-site-header-nav__link'
    );
    const length = subLinks.length;
    const activeEle = document.activeElement;
    const activeEleIndex: number = Array.prototype.indexOf.call(subLinks, activeEle);
    if (e.key === 'ArrowDown' && activeEleIndex < length - 1) {
      e.preventDefault();
      subLinks[activeEleIndex + 1].focus();
    }

    if (e.key === 'ArrowUp' && activeEleIndex > 0) {
      e.preventDefault();
      subLinks[activeEleIndex - 1].focus();
    }
    if (e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      if (activeEleIndex < length - 1) {
        subLinks[activeEleIndex + 1].focus();
      } else {
        parentLink.focus();
      }
    }
    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      if (activeEleIndex > 0) {
        subLinks[activeEleIndex - 1].focus();
      } else {
        parentLink.focus();
      }
    }
    if (e.key === 'Escape') {
      parentLink.focus();
      parentLink.click();
    }
  }
  changeTheme(menu: HeaderButton) {
    this.toggle = !this.toggle;
    menu.onToggle(this.toggle);
  }
}

import { EnergyStorageSystem } from './energyStorageSystem';
import { Power } from './power';

export class BatteryData {
  jurisdiction: string;
  customerFacingSOC: number; // SOC is state of charge
  timeRemaining: number;

  energyStorageSystem?: EnergyStorageSystem; // site_ess
  systemMeter?: Power; // sys_meter
  siteMeter?: Power;

  date: string;

  constructor(energyData: any) {
    this.jurisdiction = energyData.jurisdiction;
    this.customerFacingSOC = energyData.customer_facing_soc;
    this.timeRemaining = energyData.time_remaining;
    this.date = energyData.dt_utc;
    this.systemMeter = energyData.sys_meter ? new Power(energyData.sys_meter) : null;
    this.siteMeter = energyData.site_meter ? new Power(energyData.site_meter) : null;
    this.energyStorageSystem = energyData.site_ess
      ? {
          power: new Power(energyData.site_ess),
          running: energyData.site_ess.running,
          actualSOC: energyData.site_ess.soc,
        }
      : null;
  }
}

<form [formGroup]="dateForm" class="datepicker pt-10">
  <mat-form-field [appearance]="appearance">
    <mat-label>{{ label }}</mat-label>
    <input matInput formControlName="date" [min]="minDate" [max]="maxDate" [value]="selectedDate"
      [matDatepicker]="$any(datepicker)" (change)="saveDateValue($event.target)" (dateChange)="dateChanged($event)"
      (focusout)="formatDate()" />
    <app-de-icon matSuffix alt="Next" icon="chevron-left-xxsmall" (click)="previousDay()"
      [ngClass]="{ disabled: dateForm.value.date < minDate }"></app-de-icon>
    <app-de-icon matSuffix alt="Next" icon="chevron-right-xxsmall" (click)="nextDay()"
      [ngClass]="{ disabled: dateForm.value.date > maxDate }"></app-de-icon>
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
    <mat-error>Date must be valid</mat-error>
  </mat-form-field>
  <app-icon-button *ngIf="displayToday" name="Today" (buttonClicked)="today()" buttonType="text"
    class="mb-5 ml-12"></app-icon-button>
</form>

<div (click)="$event.stopPropagation()">
  <app-icon-button
    name="Table Columns"
    iconName="bar-chart"
    buttonType="text"
    (click)="toggleTableData()"
  ></app-icon-button>
</div>

<div
  class="columns-list bg-white dark:bg-dm-bg"
  *ngIf="showTableData"
  (click)="$event.stopPropagation()"
>
  <div class="columns-list__header">Select, Deselect, Order Columns</div>
  <div
    cdkDropList
    class="columns-list__container"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="columns-list__container--box dark:hover:!bg-dm-h-f-bg"
      *ngFor="let column of newColumns"
      cdkDrag
    >
      <app-de-checkbox
        [label]="column.name"
        [(value)]="column.display"
        (valueChange)="optionChecked(column.name)"
      ></app-de-checkbox>

      <app-de-icon icon="drag_drop" alt="drag"></app-de-icon>
    </div>
  </div>

  <div class="columns-list__buttons">
    <de-button type="secondary" size="small" (buttonClick)="toggleTableData()"
      >CANCEL</de-button
    >
    <de-button type="primary" size="small" (buttonClick)="apply()"
      >APPLY</de-button
    >
  </div>
</div>

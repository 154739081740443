import { JurisdictionShortCode } from 'src/app/shared/models/jurisdiction';
import { GenerationViolation } from './generationViolation';
import {
  InterconnectionNotification,
  NotificationPreferenceTypeEnum,
  NoGenerationViolation,
  DisconnectNotice,
} from 'src/app/shared/models/interconnection';
import { AlertStatusChange } from './alertStatusChange';
import { SiteFlag, SiteFlagEnum } from 'src/app/shared/models/enums/siteFlagEnum';
import { LoggingService } from 'src/app/shared/services/logging.service';

export class SiteNotification {
  uuid: string;
  uniqueId: number;
  jurisdiction: JurisdictionShortCode;
  date: Date;
  flag: SiteFlagEnum;
  noGeneration?: NoGenerationViolation;
  overGeneration?: GenerationViolation;
  recloserStatusChange?: AlertStatusChange;
  disconnectNotice?: DisconnectNotice;
  siteName: string;

  private _logger = new LoggingService('Class_SiteNotification', 'ProgramManager');

  constructor(data: InterconnectionNotification) {
    this.date = new Date(data.dt_utc);

    this.uuid = data.uuid;
    this.uniqueId = data.unique_id;
    this.siteName = data.customer_legal_name;
    this.jurisdiction = data.jurisdiction as JurisdictionShortCode;
    this.flag = SiteFlag.parseFromNotificationPreferenceType(
      data.notification_type as NotificationPreferenceTypeEnum
    );

    switch (data.notification_type as NotificationPreferenceTypeEnum) {
      case 'no_generation':
        this.noGeneration = {
          start: data.no_generation?.start,
          end: data.no_generation?.end,
          number_of_days: data.no_generation?.number_of_days,
        };
        break;
      case 'over_generation':
        this.overGeneration = {
          // Use UTC date as this notification is generated for the full day.
          date: this.date,
          excessMW: data.over_generation?.excess_mw,
          averageMW: data.over_generation?.average_mw,
          capacity: data.over_generation?.average_mw - data.over_generation?.excess_mw,
        };
        break;
      case 'recloser_status_change':
        this.recloserStatusChange = {
          date: this.date,
          previousStatus: data.recloser_status_change?.previous_status,
          currentStatus: data.recloser_status_change?.current_status,
        };
        break;
      case 'disconnect_notice':
        this.disconnectNotice = {
          contract_account_number: data.disconnect_notice?.contract_account_number,
          delinquency_action_description: data.disconnect_notice?.delinquency_action_description,
          number_of_days: data.disconnect_notice?.number_of_days,
          updated_date: data.disconnect_notice?.updated_date,
          is_paid: data.disconnect_notice?.is_paid,
        };
        break;
      default:
        this._logger.debug('Unhandled notification type in response', data.notification_type);
        break;
    }
  }
}

import { environments } from 'src/config/env.config';
import { env } from 'src/config/env';
import {LoggingService} from "../logging.service";

/**
 * Standardized way for our application to send normal events to GTM
 *
 * @property {string} category - In most cases is the page. E.g. dashboard, site, or site-map
 * @property {string} label - In most cases is the component. E.g. curtailment-summary or map-panel
 * @property {string} action - The actual action that was just intended by the user. E.g. turning legend items on or off
 */
export interface DerGtmEvent {
  /**
   * In most cases is the page. E.g. dashboard, site, or site-map
   */
  category: string;
  /**
   * In most cases is the component. E.g. curtailment-summary or map-panel
   */
  label: string;
  /**
   * The actual action that was just intended by the user. E.g. turning legend items on or off
   */
  action: string;
}

interface GtmEvent {
  'event-category': string;
  'event-action': string;
  'event-label': string;
  'event': 'event-click';
}

interface VpvEvent {
  event: 'send-VPV';
  'vpv-name': string;
}

const dataLayer = window['dataLayer'] as (GtmEvent | VpvEvent)[];
const isGtmEnabled: boolean =
  environments[env.env].featureFlags.googleTagManager === 'true' ||
  environments[env.env].featureFlags.googleTagManager === true;
const categoryPrefix = 'dist-energy-res_';
const vpvNamePrefix = '/vpv/dist-energy-res/page';
const logger = new LoggingService('GtmService', 'gtm');

/**
 * Google Tag Manager Service
 */
export class GtmService {
  /**
   * A more standardized approach to pushing click events to gtm
   * @param event Only 3 parts needed, with the standard naming convention for its properties.
   * Will be pushed to gtm with our given category prefix for ALL events, and the actual event of 'event-click'
   */
  public static clickEvent(event: DerGtmEvent) {
    this.pushGtmEvent({
      'event-category': `${categoryPrefix}${event.category}`,
      'event-action': event.action,
      'event-label': event.label,
      event: 'event-click',
    });
  }

  /**
   * pagePath can be accessed by the router's navigated path (everything after the host, for now excluding
   * queryParams). Considering tapping into the router's events within this service; could happen behind the scenes.
   * @param pagePath Every part of the current page's URL, after navigation, that appears after '<protocol>://<host>'.
   */
  public static pageLoadEvent(pagePath: string, pagePathEnd?: string, action='load') {
    this.pushGtmEvent({
      event: 'send-VPV',
      'vpv-name': `${vpvNamePrefix}${pagePath}/${action}${pagePathEnd ? pagePathEnd: pagePath}`,
    });
  }

  /**
   * Removes hyphens, commas, periods, and parentheses. Then replaces all spaces with hyphens.
   * Finally, converts to lowercase. Does not modify original.
   *
   * @example
   * 'Solar Generation (Actual)' -> 'solar-generation-actual'
   *
   * @param str to format
   */
  public static format(str: string): string {
    if (str) {
      return str
      .replace(/-+|\(+|\)+|,+|\.+|%+/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
    }
    return str;
  }

  private static pushGtmEvent(event: GtmEvent | VpvEvent): void {
    if (isGtmEnabled) {
      logger.debug('Pushing new gtm event', event);
      dataLayer.push(event);
    }
  }
}

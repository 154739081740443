import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  Chart,
  ChartDataset,
  LinearScale,
  CategoryScale,
  LineController,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
} from 'chart.js';
import {
  Axis,
  ChartDatasetModel,
  getDataset,
} from 'src/app/shared/utils/time-cartesian-chart.util';
import { DOCUMENT } from '@angular/common';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { FullChartOptions } from 'src/app/shared/models/charts/default-chart-options';
import { BatteryData } from 'src/app/shared/models/battery/batteryData';
import { SitesService } from 'src/app/shared/services/api/sites.service';

export interface BatteryStorageChart {
  customerFacingSOC: Axis[];
  actualSOC: Axis[];
}

// TODO: Needs a refactor
@Component({
  selector: 'app-battery-storage-chart',
  templateUrl: 'battery-storage-chart.component.html',
})
export class BatteryStorageChartComponent extends BaseComponent implements OnInit {
  @Input() set batteryData(data: BatteryData[]) {
    if (data) {
      this._payload = this.convertPayload(data);

      this.datasets = [
        getDataset({
          color: 'green',
          data: this._payload.customerFacingSOC,
          label: 'Customer State of Charge',
        }),
        getDataset({
          color: 'blue',
          data: this._payload.actualSOC,
          label: 'Actual State of Charge',
        }),
      ];

      this.hidePreviouslyHiddenData();
      this.renderChart();
    }
  }

  @Output() dateSelection = new EventEmitter<Date>();

  private _chart: Chart;

  protected _selectedDate: Date = new Date();

  public datasets: ChartDataset[] = [];
  public labels: Map<number, string>;

  public readonly minDate = new Date('03/15/2023');
  public readonly maxDate = new Date();

  public chartOptions: FullChartOptions = {
    animation: {
      duration: 0,
    },
    interaction: {
      intersect: false,
      axis: 'x',
    },
    responsive: true,
    maintainAspectRatio: false,
    spanGaps: true,

    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgb(255, 255, 255)',

    scales: {
      y: {
        position: 'left',
        ticks: {
          callback: (val: number) => `${val}%`,
          count: 5,
        },
        min: 0,
        max: 100,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  private _hiddenDatasets: string[] = [];
  private readonly _logger = new LoggingService('BatteryStorageChart', 'charts');
  private _payload: BatteryStorageChart;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public themeService: ThemeService,
    public sitesService: SitesService,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    Chart.register(
      LinearScale,
      CategoryScale,
      LineController,
      PointElement,
      LineElement,
      TimeScale,
      Tooltip
    );

    this.setXScale();

    this.subscriptions.push(
      this.themeService.themeChanges$.subscribe((value) => {
        this.setChartOptionsTheme(value);
      })
    );
  }

  convertPayload(batteryData: BatteryData[]): BatteryStorageChart {
    this._logger.debug(`convertPayload(...)`);
    const chartData: BatteryStorageChart = {
      customerFacingSOC: [],
      actualSOC: [],
    };

    batteryData.forEach((item) => {
      const itemMsSinceEpoch = new Date(item.date).getTime();
      chartData.customerFacingSOC.push({
        x: itemMsSinceEpoch,
        y: Math.round(item.customerFacingSOC),
      });
      chartData.actualSOC.push({
        x: itemMsSinceEpoch,
        y: Math.round(item.energyStorageSystem.actualSOC),
      });
    });

    return chartData;
  }

  setXScale(): void {
    this.chartOptions.scales['x'] = {
      type: 'time' as const,
      time: {
        unit: 'hour' as const,
      },
      ticks: {},
    };
  }

  toggleDataSet(dataset: ChartDatasetModel): void {
    GtmService.clickEvent({
      category: 'sites',
      label: 'battery-storage',
      action: GtmService.format(dataset.label) + '-' + (dataset.data.length > 0 ? 'on' : 'off'),
    });

    if (dataset.data.length === 0) {
      dataset.data = this.getPayloadDataFromLabel(dataset.label);
      this._hiddenDatasets.splice(this._hiddenDatasets.indexOf(dataset.label), 1);
    } else {
      dataset.data = [];
      this._hiddenDatasets.push(dataset.label);
    }

    this.renderChart();
  }

  getPayloadDataFromLabel(label: string) {
    switch (label) {
      case 'Customer State of Charge':
        return this._payload.customerFacingSOC;
      case 'Actual State of Charge':
        return this._payload.actualSOC;
      default:
        return [];
    }
  }

  setChartOptionsTheme(isDarkTheme = false) {
    const newOptions = Object.assign({}, this.chartOptions);
    for (const scale in newOptions.scales) {
      if (isDarkTheme) {
        newOptions.scales[scale].grid = {
          color: 'rgba(255, 255, 255, 0.2)',
        };

        newOptions.scales[scale].ticks.color = 'white';
      } else {
        delete newOptions.scales[scale].grid;
        delete newOptions.scales[scale].ticks.color;
      }
    }

    this.chartOptions = newOptions;
    this.renderChart();
  }

  /**
   * Keeps selected legend items hidden after new data gets loaded
   * @private
   */
  private hidePreviouslyHiddenData(): void {
    for (const label of this._hiddenDatasets) {
      this.datasets.find((ds) => ds.label === label).data = [];
    }
    this.renderChart();
  }

  /**
   * Occurs on focusOut of the input field or when a date is selected via UI
   * @param date
   */
  public dateChanged(date: Date): void {
    this._selectedDate = date ?? new Date();
    this.dateSelection.emit(date);
  }

  /**
   * Initializes the chart and renders the first time this is called. Thereafter, only makes updates based on updated
   * datasets and chart options
   * @private
   */
  private renderChart(): void {
    if (this._chart) {
      this._chart.data.datasets = this.datasets;
      this._chart.options = this.chartOptions;
      this._chart.update();
      return;
    }

    if (document.getElementById('batteryStorageChart')) {
      const ctx = (document.getElementById('batteryStorageChart') as HTMLCanvasElement)?.getContext(
        '2d'
      );
      this._chart = new Chart(ctx, {
        type: 'line',
        data: {
          datasets: this.datasets,
        },
        options: this.chartOptions,
      });
      this._chart.render();
    }
  }
}

<app-de-modal
  [showHeader]="false"
  [showMask]="true"
  [open]="modalOpen"
  [showClose]="true"
  [showCloseOnly]="true"
  (close)="close(false)"
>
  <div position="centered" class="confirmation flex flex-col items-center">
    <mat-icon class="confirmation__icon aspect-square mb-16" svgIcon="informational"></mat-icon>
    <div class="confirmation__text mb-16 text-center max-w-sm">
      {{ description }}
    </div>
    <div class="confirmation__buttons">
      <de-button *ngIf="cancelText" type="secondary" size="small" (buttonClick)="close(false)">{{
        cancelText
      }}</de-button>
      <de-button type="primary" size="small" (buttonClick)="close(true)">{{
        confirmText
      }}</de-button>
    </div>
  </div>
</app-de-modal>
